import http from "./AxiosInstance";

class ClientService {
  
// Famille

getClient() {
  return http.get("/client/all");
}



getTypeClient(idType) {
  return http.get(`/client/${idType}`);
}

createClient(data){
  console.log(data)
  return http.post("/client/create",data)
}

updateClient(data,id) {
  console.log(data)
  return http.put(`/client/update/${id}`, data);
}

deleteClient(id) {
  return http.delete(`/client/delete/${id}`);
}

  
}

export default new ClientService ();