
const stockReducer = (stocks = [], action) => {
  switch (action.type) {
    case "GET_STOCKS":
      return action.stocks.data;
    case "CLEAR_PRODUITS":
      return [];
    default:
      return stocks;
  }
};

export default stockReducer;