import axios from "axios";
import { url } from "../../api";
import { toast } from "react-toastify";

export const signUp = (user) => {
  return (dispatch) => {
    axios
      .post(`${url}/signup`, user)
      .then((token) => {
        console.log("token === ",token)
        localStorage.setItem("token", token.data);
        dispatch({
          type: "SIGN_UP",
          token: token.data,
        });
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
};

export const signIn = (speudo, passwd) => {
  return (dispatch) => {
    console.log("API URL: ", url);
    axios
      .post(`${url}/auth/login`, { speudo, passwd })
      .then((token) => {
        localStorage.setItem("token", token.data.token);
        localStorage.setItem("user", JSON.stringify(token.data));
        console.log("user === ",token.data)
        dispatch({
          type: "SIGN_IN",
          token:token.data.token,
          auth:token.data
        });
      })
      .catch((error) => {
        //console.log(error.response);
        toast.error(error.response?.data?.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
};

export const signOut = () => {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_TODOS",
    });
    
    dispatch({
      type: "SIGN_OUT",
    });

  };
};

export const loadUser = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    if (token) {
      dispatch({
        type: "USER_LOADED",
        token,
      });
    } else return null;
  };
};