import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './redux/reducers/rootReducer'
import { BrowserRouter} from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import MaterialTable from 'material-table';


const store = createStore(rootReducer, compose(applyMiddleware(thunk)))

const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#017679', // Remplacez cette valeur par votre couleur personnalisée
      },
      secondary: {
        main: '#f26522',
      },
      info: {
        main: '#FEEAA1',
      },
      warn: {
        main: '#FO4358',
      }
    },
  });
  

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
       <Provider store = { store }>
       <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider> 
      </ThemeProvider>
   </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
