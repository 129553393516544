

import { Button, Container, Grid, MenuItem,TextField,Select } from '@material-ui/core'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from 'yup'
import { toast } from "react-toastify";
import ClientService from '../services/ClientService';
import TypeClientService from '../services/TypeClientService';
import DateTimePicker from './FormsUI/DataTimePicker';
import { Autocomplete } from '@material-ui/lab';


const ClientForm = () => {


  const auth= useSelector((state) => state.auth);
  const produits = useSelector((state) => state.produits);
  const dispatch = useDispatch();

  console.log("produits ===== ",produits)

  const useStyles = makeStyles({
    root: {
      maxWidth: 345,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent:'center',
    }
  });

  const btnStyle = { marginTop: 10, width:'100%' }
  
    const [sousFamilles,setSousFamille] = useState([])
    const [random,setRandon] = useState()
    const [valueRemise, setValueRemise] = useState('0');

    const remises = ['100', '90','80','70','60','50'];
    const [stateRemise, setRemise] = React.useState(remises[0]);



  useEffect(() => {
    const min = 1000;
      const max = 900000;
      const rand = min + Math.random() * (max - min);
      setRandon(rand)

    const fetchData = async () => {
      await TypeClientService.getTypeClient()
         .then((response) => {
           setSousFamille(response.data)
         })
         .catch(e => {
           console.log(e);
        });
       }

   fetchData();
  }, []);
    
  const initialValues = {
    idType: '', 
    reference: '', 
    nom: '', 
    adresse: '', 
    telephone: '', 
    quota: '',
    remise: '',
    tauxIpm: ''
}
const validationSchema = Yup.object().shape({
  idType: Yup.number().required("Required"),
  reference: Yup.string(),
  nom: Yup.string().required("Required"),
  adresse: Yup.string().required("Required"),
  telephone: Yup.number().required("Required"),
  quota: Yup.number(),
  remise: Yup.number(),
  tauxIpm: Yup.number()
  })

const onSubmit = (values, props) => {
    var client = {
      "idType":values.idType,
      "reference":values.reference,
      "nom":values.nom,
      "speudo":auth.nom,
      "adresse":values.adresse.trim(),
      "telephone":values.telephone,
      "quota":values.quota,
      "remise":values.remise,
      "tauxIpm":values.tauxIpm
    }

    let percent = parseInt(parseInt(values.remise) + parseInt(values.tauxIpm))

      if (percent > 100){
        alert(percent)
        toast.error("Le pourcentage ne doit pas dépasser 100%...", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return;
      }

    ClientService.createClient(client)
       .then((response) => {
         toast.success("Client ajoute avec success...", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
       })
       .catch((error) => {
         console.log(error);
         toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
    //props.resetForm()
}




    return (
        <div>
             <Grid item xs={12}>
        <Container maxWidth="md">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {(props) => (
                        <Form noValidate>
                     <Grid container spacing={2}>
                     <Grid item xs={6} >
                            <Field as={Select} name="idType" label='Type Client' placeholder='famille' fullWidth variant="outlined"
                                error={props.errors.idType && props.touched.idType}
                                helperText={<ErrorMessage name='idType' />} required >
                                     {
                                        sousFamilles.map((f)=>(
                                            <MenuItem value={f.idType}>{f.designation}</MenuItem>
                                        )
                                        )
                                    }
                                        </Field>
                                </Grid> 
                    <Grid item xs={6} >
                            <Field as={TextField} name='reference' label='reference' fullWidth variant="outlined"
                                error={props.errors.reference && props.touched.reference} 
                                helperText={<ErrorMessage name='reference' />} required />
                    </Grid>    
                    <Grid item xs={12} >
                            <Field as={TextField} name='nom' label='nom' fullWidth variant="outlined"
                                error={props.errors.nom && props.touched.nom}
                                helperText={<ErrorMessage name='nom' />} required />
                    </Grid> 
                   
                    <Grid item xs={6} >
                            <Field as={TextField} name='adresse' label='adresse' fullWidth variant="outlined"
                                error={props.errors.adresse && props.touched.adresse}
                                helperText={<ErrorMessage name='adresse' />} required />
                    </Grid> 
                    <Grid item xs={6} >
                            <Field as={TextField} name='quota' label='Quota' fullWidth variant="outlined"
                                error={props.errors.quota && props.touched.quota}
                                helperText={<ErrorMessage name='quota' />} required />
                    </Grid> 
                    <Grid item xs={12} >
                            <Field as={TextField} name='telephone' label='telephone' fullWidth variant="outlined"
                                error={props.errors.telephone && props.touched.telephone}
                                helperText={<ErrorMessage name='telephone' />} required />
                    </Grid>   
                   {props.values.idType === 1  && <Grid item xs={12} >
                            <Field as={TextField} name='remise' label='Taux de remise' fullWidth variant="outlined"
                                error={props.errors.remise && props.touched.remise}
                                helperText={<ErrorMessage name='remise' />} required />
                    </Grid> }
                   { props.values.idType === 2  && <Grid item xs={12} >
                            <Field as={TextField} name='remise' label='Taux de participation client' fullWidth variant="outlined"
                                error={props.errors.remise && props.touched.remise}
                                helperText={<ErrorMessage name='remise' />} required />
                    </Grid>   }
                    {
                      props.values.idType === 2  &&
                       <Grid item xs={12} >
                       <Field as={TextField} name='tauxIpm' label='Taux de participation IPM' fullWidth variant="outlined"
                           error={props.errors.tauxIpm && props.touched.tauxIpm}
                           helperText={<ErrorMessage name='tauxIpm' />} required />
                        </Grid>   
                    }
                   {/*  <Grid item xs={12} >
                    <Autocomplete
                                id="remise"
                                name="remise"
                                value={valueRemise}
                                options={remises}
                                onChange={(e, v, r) => {
                                  
                                      setValueRemise(v)
                                      props.setFieldValue("remise",v)
                                    
                                  }}
                                 renderInput={params => (
                                    <Field as={TextField} {...params} name="remise" label="Remise" variant="outlined" fullWidth
                                    error={props.errors.remise && props.touched.remise}
                                    helperText={<ErrorMessage name='remise' />}  required
                                    />
                                )}
                        />
                      </Grid>    */}
              
                               <Grid item xs={12}>
                            <Button type='submit' style={btnStyle} variant='contained'
                                color='primary'>Valider</Button>
                                </Grid>
                     </Grid>
                        </Form>
                    )}
                </Formik>
                </Container>
                </Grid>
        </div>
    )
}

export default ClientForm
