
import jsPDF from "jspdf";
import "jspdf-autotable";
import { format } from "date-fns";

import  logoImg  from "../images/undraw_medicine_b1ol.png";


const generatePDF = reportProd => {
  // initialize jsPDF
  const doc = new jsPDF();

  //const x = doc.internal.pageSize.getWidth() - 70; 
  //const y = 10; 

    // Définir les dimensions de l'image (largeur et hauteur)
    //const width = 30;
   // const height = 30;

    //doc.addImage(logoImg, 'PNG', x, y, width, height);


//'l', 'in', [3, 5]
  // define the columns we want and their titles
//'l', 'in', [3, 5]
  // define the columns we want and their titles
  const tableColumn = ["produit","quantite","prix unitaire","total"];
  // define an empty array of rows
  const tableRows = [];

  // for each ticket pass all its data into an array
  let total = 0
  let numero =''
  let nom = ''
  let telephone=''
  let remise = 0
  reportProd.forEach(prod => {
    const ticketData = [
      prod.nomProd,
      prod.qtVdu,
      prod.prixProdVendu,
      prod.total,
      total = total + prod.total,
      numero =prod.numFactSrt,
      nom = prod.nom,
      telephone=prod.telephone,
      remise = prod.remise


      // called date-fns to format the date on the ticket
      //format(new Date(prod.updated_at), "yyyy-MM-dd")
    ];
    // push each tickcet's info into a row
    tableRows.push(ticketData);
  });



  // startY is basically margin-top
 /*  doc.autoTable(tableColumn, tableRows, { tableLineColor: [0, 0, 0],
    tableLineWidth: 0.05,
    styles: {
        font: 'Meta',
        lineColor: [0,0,0],
        lineWidth: 0.1
    },
    headerStyles: {
        fillColor: [0, 0, 0],
        fontSize: 11
    },
    bodyStyles: {
        fillColor: [216, 216, 216],
        textColor: 50
    },
    alternateRowStyles: {
        fillColor: [250, 250, 250]
    },
    startY: 90,});
  // we use a date string to generate our filename.
  var d = new Date()
  var datestring = ("0" + d.getDate()).slice(-2)  + "/" + ("0"+(d.getMonth()+1)).slice(-2) + "/" + d.getFullYear() + " " + ("0"+d.getHours()).slice(-2)+ ":" + ("0"+d.getMinutes()).slice(-2)
  // ticket title. and margin-top + margin-left
  doc.setFontSize(20) 
  //doc.setTextColor("green")
  doc.text("PHARMACIE NDIOL FOUTA", 14, 15);

  doc.setFontSize(16) 
  doc.text("DR. AL Ousseynou KEITA", 14, 22);


  doc.setFontSize(10) 
  doc.text("Sor Diagne Route Nationale N 2/ Saint-Louis:", 14, 28);

  doc.setFontSize(10) 
  doc.text("X Médina Bois Saint-Louis - Tél: +221 33 961 08 80", 14, 33);

  doc.text("NINEA:007968079 - RC: SN-STL-2020-A-1225", 14, 38);
 

  doc.setFontSize(10) 
  doc.setTextColor("black")

  doc.text("N°: "+numero, 150, 21,{
    styles: { fontSize: 10},
  });
  doc.text(datestring, 150, 27);

  doc.text("Nom: "+nom, 70, 50,{
    styles: { fontSize: 10},
  });

  doc.setFontSize(20) 
  doc.text("FACTURE", 14, 47);

  doc.setDrawColor(0, 0, 0);
  doc.line(14, 40, 200, 40);


doc.setFontSize(10) 

doc.setLineWidth(0.2);
doc.line(14, 55, 200, 55)

    



doc.setFontSize(25)
doc.setLineWidth(1);
doc.setFillColor(0,0,0);
//doc.rect(14, 170, 180, 12,'FD');
doc.setTextColor("Black")
doc.text("Total "+total,80, 280).setTableHeaderRow();
  // we define the name of our PDF file. */


  const date = Date().split(" ");
  // we use a date string to generate our filename.
  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
  var d = new Date()
  var datestring = ("0" + d.getDate()).slice(-2)  + "/" + ("0"+(d.getMonth()+1)).slice(-2) + "/" + d.getFullYear() + " " + ("0"+d.getHours()).slice(-2)+ ":" + ("0"+d.getMinutes()).slice(-2)
  



  var header = function(data){

    const text = "PHARMACIE NDIOL FOUTA";
    const fontSize = 10;
   


    doc.setFontSize(fontSize);
    doc.setFont('helvetica', 'bold');
    //doc.setTextColor("green")
    doc.text(text, 14, 15);


    //doc.setFontSize(14) 
    //doc.setTextColor("green")
    //doc.text("PHARMACIE NDIOL FOUTA", 14, 15);
  
    doc.setFontSize(10) 
    doc.setFont('helvetica', 'bold');
    doc.text("DR. AL Ousseynou KEITA", 14, 20);

    //doc.addImage(logoImg, "PNG", 150, 10, 40, 40);

  
  
    doc.setFontSize(10) 
    doc.setFont('helvetica', 'normal'); 
    doc.text("Sor Diagne Route Nationale N 2", 14, 25);
  
    doc.setFontSize(10) 
    doc.text("X Médina Bois Saint-Louis - Tél: +221 33 961 08 80", 14, 30);
  
    doc.text("NINEA:007968079 - RC: SN-STL-2020-A-1225", 14, 35);
  
  
   
/*   
    doc.setFontSize(14) 
    doc.text("BON CLIENT", 70, 47); */
  
  
   /*  doc.setDrawColor(0, 0, 0);
    doc.line(14, 40, 200, 40); */


const pageWidth = doc.internal.pageSize.getWidth(); // Largeur de la page
const marginRight = 10; // Marge droite fixe
const blockWidth = 55; // Largeur du bloc

const labelWidth = 20; // Largeur de l'étiquette (par exemple, "Nom du client")
const contentWidth = blockWidth - labelWidth; // Largeur du contenu (valeur dynamique)

const labelX = pageWidth - marginRight - blockWidth; // Position X de l'étiquette
const contentX = labelX + labelWidth - 15; // Position X du contenu


doc.setFontSize(10);



  doc.text("Nom du client :", labelX, 53, { align: "right" });
  doc.text("" + nom, contentX, 53, { align: "left", maxWidth: contentWidth });
/*   
  doc.text("Téléphone :", labelX, 61, { align: "right" });
  doc.text("" + telephone, contentX, 61, { align: "left", maxWidth: contentWidth }); */

  doc.text("Remise :", labelX, 61, { align: "right" });
  doc.text( remise  + "%", contentX, 61, { align: "left" });








doc.setFontSize(10) 
doc.setTextColor("black")

doc.text("FACTURE N°:", 14, 80, { align: "left" });
doc.text(numero.toString(), 50, 80, { align: "right", maxWidth: contentWidth });

doc.text("Réalisée le ", labelX - 15, 80, { align: "right" });
doc.text(datestring, contentX - 15, 80, { align: "left" });

/* doc.text("FACTURE N°: "+numero, 14, 21,{
  styles: { fontSize: 10},
});
doc.text(datestring, 150, 27); */

  
/*   
  if (reportProd[0].idType === 1){
    doc.text("TOTAL SANS REMISE",14, 80,{styles:{fontWeight: 'bold'}});
    doc.text(""+total+" Fcfa",80, 80,{styles:{fontWeight: 'bold'}});
    doc.text("TOTAL AVEC REMISE",14, 88,{styles:{fontWeight: 'bold'}});
    doc.text(""+remise+" Fcfa",80, 88,{styles:{fontWeight: 'bold'}});
  }
  else {
    doc.text("TOTAL CLIENT: ",14, 80,{styles:{fontWeight: 'bold'}});
    doc.text(""+client+" Fcfa",80, 80,{styles:{fontWeight: 'bold'}});
    doc.text("TOTAL IPM: ",14, 88,{styles:{fontWeight: 'bold'}});
    doc.text(""+ipm+" Fcfa",80, 88,{styles:{fontWeight: 'bold'}});
  }
   */
  
  
  
  
/*   doc.setLineWidth(0.2);
  doc.line(14, 67, 200, 67) */
  doc.setFontSize(10);
  doc.setLineWidth(1);
  doc.setFillColor(0,0,0);
  
  
  }

  const options = {
    margin: { top: 95 },
    beforePageContent: header,
  };
  
  doc.autoTable(tableColumn, tableRows, options);
  
  const tableBottom = doc.autoTableEndPosY(); // Obtenir la position verticale de fin du tableau
  
  const montantY = tableBottom + 10; // Position verticale pour le montant
  
  doc.setFontSize(10);

  /* doc.text(160, montantY, "Net à payer:",{ align: "right" }); 

  doc.text(165, montantY,Math.round(total - (remise * total) / 100) + "F CFA", { align: "left" }); */

  
  const netAPayerX = 110;
const netAPayerY = montantY;

const montantNetX = netAPayerX + 40;
const montantNetY = montantY;

const tableauWidth = 80;
const tableauHeight = 10;

// Dessiner le cadre du tableau
doc.rect(netAPayerX, netAPayerY, tableauWidth, tableauHeight);

// Dessiner la barre verticale
const barreX = netAPayerX + tableauWidth / 2;
const barreY1 = netAPayerY + 2;
const barreY2 = netAPayerY + tableauHeight - 2;
doc.line(barreX, barreY1, barreX, barreY2);

// Ajouter le contenu dans le tableau
doc.setFontSize(12)
doc.text("Net à payer", netAPayerX + 5, netAPayerY + 5, { align: "left" });
doc.setFontSize(10)
doc.setFont('helvetica', 'bold');
doc.text(Math.round(total - (remise * total) / 100) + " F CFA", montantNetX + 5, montantNetY + 5, { align: "left" });

  

  doc.text(60,montantY + 30, "La présente facture est arrêtée à la somme de "+Math.round(total - (remise * total) / 100)+ "F CFA", { align: "left" });

  
  doc.save(`facture_${datestring}_${nom}.pdf`);
};

export default generatePDF;