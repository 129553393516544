import React, { useState, useEffect } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import ProduitVenduService from '../../services/ProduitVenduService';

// Enregistrez les composants nécessaires à ChartJS
ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, ArcElement, Title, Tooltip, Legend);

const SalesChart = ({ period, chartType }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (period === '15-jours') {
          response = await ProduitVenduService.top10Daily();
        } else if (period === 'mois') {
          response = await ProduitVenduService.top10Monthly();
        } else if (period === 'annee') {
          response = await ProduitVenduService.top10Yearly();
        }

        setData(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [period]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  // Transformez les données pour ChartJS
  const chartData = {
    labels: data.map(item => item.nomProd),
    datasets: [{
      label: "Quantité Vendue",
      data: data.map(item => item.totalQuantity),
      backgroundColor: chartType === 'Pie' ? [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 163, 235, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        // Ajouter d'autres couleurs si nécessaire
      ] : "rgba(255, 99, 132, 0.2)",
      borderColor: "rgba(54, 163, 235, 1)",
      borderWidth: 1
    }]
  };

  // Choisissez le type de graphique en fonction de chartType
  let ChartComponent;
  switch (chartType) {
    case 'Bar':
      ChartComponent = Bar;
      break;
    case 'Line':
      ChartComponent = Line;
      break;
    case 'Pie':
      ChartComponent = Pie;
      break;
    default:
      ChartComponent = Bar;
  }

  return (
    <div>
      <h2>Produits les mieux vendus ({period})</h2>
      <ChartComponent
        data={chartData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed !== null) {
                    label += context.parsed.y || context.parsed;
                  }
                  return label;
                }
              }
            }
          },
          scales: chartType !== 'Pie' ? {
            y: {
              beginAtZero: true
            }
          } : undefined,
        }}
      />
    </div>
  );
};

export default SalesChart;
