import axios from "axios";
import { toast } from "react-toastify";
import { setHeaders, url } from "../../api";

export const getUsers = () => {
  return (dispatch) => {
    axios
      .get(`${url}/users/all`, setHeaders())
      .then((users) => {
        dispatch({
          type: "GET_USERS",
          users,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const addUser = (newUser) => {
  return (dispatch, getState) => {
    axios
      .post(`${url}/users/register`, { ...newUser }, setHeaders())
      .then((user) => {
        dispatch({
          type: "ADD_USER",
          user,
        });
      })
      .catch((error) => {
        console.log("error ",error.response);
        toast.error(error.response?.data?.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
};

export const updateUser = (updatedUser, id) => {
  return (dispatch) => {
    axios
      .put(`${url}/users/${id}`, updatedUser, setHeaders())
      .then((user) => {
        dispatch({
          type: "UPDATE_USER",
          user,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
};

export const deleteUser = (id) => {
  return (dispatch) => {
    axios
      .delete(`${url}/users/${id}`, setHeaders())
      .then(() => {
        dispatch({
          type: "DELETE_USER",
          id,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
};

export const checkUser = (id) => {
  return (dispatch) => {
    axios
      .patch(`${url}/users/${id}`, {}, setHeaders())
      .then((user) => {
        dispatch({
          type: "CHECK_USER",
          user,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
};