import React, { useState } from 'react'
import * as Yup from 'yup'

import { Grid, Paper, Button, Typography, Container,TextField } from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Autocomplete } from "@material-ui/lab";
import { toast } from "react-toastify";
import DefectService from '../../services/DefectService';
import { useEffect } from 'react';
import CliniqueService from '../../services/CliniqueService';
import NumCommandeService from '../../services/NumCommandeService';
import BonService  from '../../services/BonService';
import RetourCommandeService from '../../services/RetourCommandeService';

const AddRetourCommande = () => {

    const [nums, setNumbers] = useState([])
    const [commandes, setCommandes] = useState([])

    const listEtats = [
        {name:"EXPIRER"},
        {name:"AVARIE"},
        {name:"RETRAIT"}
    ]

    const initialValues = {
        idNumero: '',
        quan_retour: '',
        etat: '',
        motif:'',
        produit:'',
        fournisseur:'',
        date_commande:'',
        codeIdProd:''
    }
    const validationSchema = Yup.object().shape({
        idNumero: Yup.string().required("Obligatoire"),
        quan_retour: Yup.number().required("Obligatoire"),
        etat: Yup.string().required("Obligatoire"),
        motif: Yup.string(),
        produit: Yup.string(),
        fournisseur: Yup.string(),
        date_commande: Yup.date(),
        codeIdProd: Yup.date(),
    })


  const paperStyle = { padding: '0 15px 40px 15px', width: 600, height: 500 }
  const btnStyle = { marginTop: 10, width: '100%' }

  useEffect(() => {
    const fetchData = async () => {
      
        await NumCommandeService.getNumbers()
        .then((response) => {
            setNumbers(response.data)
        })
        .catch(e => {
          console.log(e);
       });
      }
   
      fetchData();
}, [])


const handleCommande = async (value, props) =>{
  await BonService.getBonByNumbers(value)
  .then((response) => {
      var data = response.data
      setCommandes(data)

  })
  .catch((error) => {
      console.log(error)
  }) 
}




  const onSubmit = (values, props) => {
    var produit = {
        "idNumero": values.idNumero,
        "etat_produit": values.etat,
        "quan_retour": values.quan_retour,
        "motif": values.motif,
        "produit": values.produit,
        "fournisseur": values.fournisseur,
        "date_commande": commandes[0]?.dateComde,
        "codeIdProd": values.codeIdProd,
        
    }

    //alert(JSON.stringify(produit))


     RetourCommandeService.createRetour(produit)
        .then((response) => {
            if(response?.data){
                toast.success(response?.data?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
            else{
                toast.error("Erreur lors de l'ajout", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
             
        })
        .catch((error) => {
            console.log(error);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        });  
}

  return (
    <div>
            <Grid container>
            <Paper elevation={0} style={paperStyle}>
                <Grid align='center'>
                    <Typography variant='h6'>Enregistrer un retour de commande</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth="md">
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                            {(props) => (
                                <Form noValidate>
                                    <Grid container spacing={2}>
                                                    
                        
                        <Grid item xs={12}>
                            <Autocomplete
                                id="idNumero"
                                name="idNumero"
                                options={nums}
                                getOptionSelected={(option, value) => option.numero === value.numero}
                                getOptionLabel={option => option.numero}
                                  onChange={(e, v, r) => {
                                    props.setFieldValue("idNumero",v?.idNumero)
                                    handleCommande(v?.idNumero);
                                  }}
                                renderInput={params => (
                                    <Field as={TextField} {...params} name="idNumero" label="Numéro" variant="outlined" fullWidth
                                    error={props.errors.idNumero && props.touched.idNumero}
                                    helperText={<ErrorMessage name='idNumero' />}  required
                                    />
                                )}
                        />
                        </Grid>

                        <Grid item xs={12}>
                            <Autocomplete
                                id="produit"
                                name="produit"
                                options={commandes}
                                getOptionSelected={(option, value) => option.nomProd === value.nomProd}
                                getOptionLabel={option => option.nomProd}
                                  onChange={(e, v, r) => {
                                    props.setFieldValue("produit",v?.nomProd)
                                    props.setFieldValue("codeIdProd",v?.codeIdProd)

                                  }}
                                renderInput={params => (
                                    <Field as={TextField} {...params} name="produit" label="Produit" variant="outlined" fullWidth
                                    error={props.errors.produit && props.touched.produit}
                                    helperText={<ErrorMessage name='produit' />}  required
                                    />
                                )}
                        />
                        </Grid>

                        <Grid item xs={12}>
  <Autocomplete
    id="fournisseur"
    name="fournisseur"
    options={commandes.filter((commande, index, self) =>
      index === self.findIndex(c => c.nomFournisseur === commande.nomFournisseur)
    )}
    getOptionSelected={(option, value) => option.nomFournisseur === value.nomFournisseur}
    getOptionLabel={option => option.nomFournisseur}
    onChange={(e, v, r) => {
      props.setFieldValue("fournisseur", v?.nomFournisseur)
    }}
    renderInput={params => (
      <Field
        as={TextField}
        {...params}
        name="fournisseur"
        label="Fournisseur"
        variant="outlined"
        fullWidth
        error={props.errors.fournisseur && props.touched.fournisseur}
        helperText={<ErrorMessage name='fournisseur' />}
        required
      />
    )}
  />
</Grid>


                        {/* <Grid item xs={12}>
                            <Autocomplete
                                id="fournisseur"
                                name="fournisseur"
                                options={commandes}
                                getOptionSelected={(option, value) => option.nomFournisseur === value.nomFournisseur}
                                getOptionLabel={option => option.nomFournisseur}
                                  onChange={(e, v, r) => {
                                    props.setFieldValue("fournisseur",v?.nomFournisseur)
                                  }}
                                renderInput={params => (
                                    <Field as={TextField} {...params} name="fournisseur" label="Fournisseur" variant="outlined" fullWidth
                                    error={props.errors.fournisseur && props.touched.fournisseur}
                                    helperText={<ErrorMessage name='fournisseur' />}  required
                                    />
                                )}
                        />
                        </Grid> */}


                                        <Grid item xs={12} >
                                            <Field as={TextField} name="quan_retour" label='quantité avariée' fullWidth variant="outlined"
                                                error={props.errors.quan_retour && props.touched.quan_retour}
                                                helperText={<ErrorMessage name='quan_retour' />} required />
                                        </Grid>

                                        <Grid item xs={12}>
                            <Autocomplete
                                id="etat"
                                name="etat"
                                options={listEtats}
                                getOptionSelected={(option, value) => option.name === value.name}
                                getOptionLabel={option => option.name}                                  onChange={(e, v, r) => {
                                    props.setFieldValue("etat",v?.name)
                                  }}
                                renderInput={params => (
                                    <Field as={TextField} {...params} name="etat" label="Etat" variant="outlined" fullWidth
                                    error={props.errors.etat && props.touched.etat}
                                    helperText={<ErrorMessage name='etat' />}  required
                                    />
                                )}
                        />
                        </Grid>

                                        <Grid item xs={12} >
                                            <Field as={TextField} name="motif" label='Commentaire' fullWidth variant="outlined"
                                                error={props.errors.motif && props.touched.motif}
                                                helperText={<ErrorMessage name='motif' />} required />
                                        </Grid>
                                        

                                        <Grid item xs={12} >
                                            <Button type='submit' style={btnStyle}  variant='contained'
                                                color='primary'>Valider</Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Container>
                </Grid>
            </Paper>
        </Grid>
    </div>
  )
}

export default AddRetourCommande