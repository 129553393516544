import http from "./AxiosInstance";

class NumCommandeService {
  

getNumbers() {
  return http.get("/num/all");
}


  createNum(data){
    return http.post("/num/create",data)
  }

  updateNum(data,id) {
    return http.put(`/num/update/${id}`, data);
  }

  deleteNum(id) {
    return http.delete(`/num/delete/${id}`);
  }
  
}

export default new NumCommandeService ();