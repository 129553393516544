import axios from "axios";
import { toast } from "react-toastify";
import { setHeaders, url } from "../../api";

export const getFournisseurs = () => {
  return (dispatch) => {
    axios
      .get(`${url}/fournisseurs/`, setHeaders())
      .then((fournisseurs) => {
        dispatch({
          type: "GET_FOURNISSEURS",
          fournisseurs,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const addFournisseur = (newFournisseur) => {
  return (dispatch, getState) => {
    axios
      .post(`${url}/fournisseurs/register`, { ...newFournisseur }, setHeaders())
      .then((fournisseur) => {
        dispatch({
          type: "ADD_FOURNISSEUR",
          fournisseur,
        });
      })
      .catch((error) => {
        console.log("error ",error.response);
        toast.error(error.response?.data?.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
};

export const updateFournisseur = (updatedFournisseurs, id) => {
  return (dispatch) => {
    axios
      .put(`${url}/fournisseurs/${id}`, updatedFournisseurs, setHeaders())
      .then((fournisseur) => {
        dispatch({
          type: "UPDATE_FOURNISSEUR",
          fournisseur,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
};

export const deleteFournisseur = (id) => {
  return (dispatch) => {
    axios
      .delete(`${url}/fournisseurs/${id}`, setHeaders())
      .then(() => {
        dispatch({
          type: "DELETE_FOURNISSEUR",
          id,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
};

export const checkFournisseur = (id) => {
  return (dispatch) => {
    axios
      .patch(`${url}/fournisseurs/${id}`, {}, setHeaders())
      .then((user) => {
        dispatch({
          type: "CHECK_FOURNISSEUR",
          user,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
};