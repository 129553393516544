import { Button, Container, Dialog, DialogContent, DialogTitle, Grid, MenuItem, TextField, Typography } from '@material-ui/core'
import MaterialTable from 'material-table'
import React, { useEffect, useState } from 'react'
import AddIcon from '@material-ui/icons/Add';
import { toast } from "react-toastify";
import FournisseurService from '../../services/FournisseurService';
import BonService from '../../services/BonService';
import { Edit } from '@material-ui/icons';
import NumCommandeService from '../../services/NumCommandeService';
import CliniqueService from '../../services/CliniqueService';
import { useSelector } from 'react-redux';



const PendingCommandes = ({commandes,refresh}) => {
   
  const [state,setStates] = useState({
    open: false,
    editProduct : []
  })
  const auth = useSelector((state) => state.auth);

  const [updateProd,setUpdateProd] = useState({
    idNumero: "",
    prixPublic : "",
    date_expiration: "",
    prixUnitaire:"",
    qteComder:"",
    quan_livree:"",
    dateComde:"",
    speudo:"",
    codeIdProd:"",
    idFournisseur:""
  })

  const [nums, setNums] = useState([])

  const [fournisseurs, setFournisseurs] = useState([])
  const [prod, setProd] = useState([])
  
  const handleCommander = async ()=>{
    await  BonService.saveAllCommande(commandes)
        .then(() => {
          toast.success("Commande ajoutée avec success...", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          refresh()
         })
         .catch(e => {
           console.log(e);
         });

   } 


  useEffect(() => {
    const fetchData = async () => {
        
     // refreshCommandes()

        }

     fetchData();
  }, [])
   
  const handleChangeUpdate = (e) => {
    setUpdateProd({...updateProd, [e.target.name]: e.target.value });
  }; 
  
  const handleClose = () => {
    setStates({ open: false});
  };


  const fetchNums = async () => {
    await NumCommandeService.getNumbers().then((response) => {
        setNums(response.data)
    })
    .catch(e => {
      console.log("error numeros" + e)
    })
  }

 const fetchFournisseurs =async ()=>{
  await FournisseurService.getFournisseurs()
  .then((response) => {
    setFournisseurs(response.data)
  })
  .catch(e => {
    console.log(e);
 });
 } 

 const fetchProduits =async ()=>{
  await CliniqueService.getAllProduits()
  .then((response) => {
     setProd(response.data)
 
  })
  .catch(e => {
    console.log(e);
 });
 } 



  const handleOpen = (row) => {
    //alert(JSON.stringify(row))
    fetchNums()
    fetchProduits()
    fetchFournisseurs()
    setStates({ open: true, editProduct: row });
    // setUpdateProd(row)
    setUpdateProd({...updateProd,
      idNumero: row[0]?.idNumero,
      idFournisseur: row[0]?.idFournisseur,
      codeIdProd: row[0]?.codeIdProd,
      prixPublic: row[0]?.prixPublic,
      date_expiration: row[0]?.date_expiration,
      dateComde: row[0]?.dateComde,
      prixUnitaire:row[0]?.prixUnitaire,
      qteComder:row[0]?.qteComder,
      quan_livree:row[0]?.quan_livree,
      speudo:auth.nom
    })

  };


  const handleUpdate = async (e) => {
    //alert(JSON.stringify(updateProd))
     //alert(idProd)
    await  BonService.updateBon(updateProd,state.editProduct[0]?.idBonDeComdeMat)
           .then((response)=>{
             toast.success("Votre commande modifiee avec success...", {
               position: toast.POSITION.BOTTOM_RIGHT,
             });
             refresh()
           })
           .catch((error)=>{
 
           }) 
 }; 
     
    

  const columns = [
   // { title: "ID", field: "idBonDeComdeMat", filterPlaceholder: "filter" },±
    { title: "REFERENCE", field: "numero", filterPlaceholder: "filter" },
    { title: "NOM PRODUIT", field: "nomProd", filterPlaceholder: "filter" },
    { title: "FOURNISSEUR", field: "nomFournisseur", filterPlaceholder: "filter" },
    { title: "PRIX UNITAIRE", field: "prixUnitaire", filterPlaceholder: "filter" },
    { title: "PRIX VENTE", field: "prixPublic", filterPlaceholder: "filter" },
    { title: "EXPIRATION", field: `date_expiration`, filterPlaceholder: "filter",type: "date" },
    { title: "DATE COMMANDE", field: "dateComde", filterPlaceholder: "filter",type: "date"},
    { title: "QUANTITE COMMANDEE", field: `qteComder`, filterPlaceholder: "filter" },
    { title: "QUANTITE LIVREE", field: `quan_livree`, filterPlaceholder: "filter" },
    //{ title: "Enregistre", field: "dateEng", type: "currency", currencySetting: { currencyCode: "INR", minimumFractionDigits: 1 },
     // cellStyle: { background:"#009688" }, headerStyle: { color: "#fff" } },
   ]

    return (
      <>
        <div className="App" style={{width:"100%", display:'flow',justifyContent:'center',alignItems:'center',top:0,bottom:0,margin:'auto'}}>
            <div style={{padding:'10px',display:'flex',justifyContent:'end'}}>
            <Button type='submit'  variant='contained' onClick={handleCommander} style={{background:'#f26522',color:'white'}}>Commander</Button>
            </div>
            <MaterialTable columns={columns} data={commandes}
      onRowClick={(evt,rowData)=>{
      }}
        editable={{
          onRowAdd: (newRow) => new Promise((resolve, reject) => {
             /*  ClientService.createClient(newRow)
                  .then((response) => {
                    toast.success("Client ajoute avec success...", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    refreshCommandes()
                  })
                  .catch((error) => {
                    console.log(error);
                    toast.error(error.response?.data, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  });
            setTimeout(() => resolve(), 500) */
          }),
          onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
  
              BonService.updateBon(newRow,oldRow.idBonDeComdeMat)
                  .then((response) => {
                    console.log(JSON.stringify(response));
                    
                      toast.success(response?.data?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      });
                    
                    // eslint-disable-next-line no-unused-expressions
                    //refreshCommandes()
                    refresh()
                  })
                  .catch((error) => {
                    console.log(error);
                    toast.error(error.response?.data, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  });
            setTimeout(() => resolve(), 500)
          }),
          onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
            BonService.deleteBon(selectedRow.idBonDeComdeMat)
                  .then((response) => {
                    toast.success("Commande supprimée avec success...", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    // eslint-disable-next-line no-unused-expressions
                    //refreshCommandes()
                    refresh()

                  })
                  .catch((error) => {
                    console.log(error);
                    toast.error(error.response?.data, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  });
            setTimeout(() => resolve(), 1000)
          })
        }}
           
        actions={[
          {
            icon: () => <Edit color="error" />,
            tooltip: "modifier un produit",
            onClick: (event, rowData) => {
              //alert(rowData)
              handleOpen(rowData)
              //setOpen(true)
            },
          },
        ]}
        options={{
          selection: true
        }}
        onSelectionChange={(selectedRows) => console.log(selectedRows)}
        options={{
          sorting: true, search: true,
          searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
          filtering: true, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
          paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
          exportAllData: true, exportFileName: "Clients", addRowPosition: "first", actionsColumnIndex: -1, selection: true,
          showSelectAllCheckbox: false, showTextRowsSelected: false, selectionProps: () => ({
            //disabled: rowData.age == null,  
            color:"primary"
          }),
           columnsButton: true,
          rowStyle: (_data, index) => index % 2 === 0 ? { background: "#f5f5f5" } : null,
          headerStyle: { background: "#226D68",color:"#fff"},
          
        }}
        title="Liste des commandes en cours"
        icons={{ Add: () => <AddIcon /> }} />
          </div>
          { state.open && <Dialog
  open={state.open}
  aria-labelledby="draggable-dialog-title"
  onClose={handleClose}
>   
  <DialogTitle id="draggable-dialog-title">Modifier la commande</DialogTitle>
  {state.editProduct && <DialogContent>
     <Container>
     <Grid item xs={12}>
     <Grid container spacing={1}>
     <Grid item xs={12} >
     {/* <TextField id="outlined-basic" 
     label="Numéro commande" 
     variant="outlined" 
     name="numero"  
     onChange={handleChangeUpdate}
     defaultValue={state.editProduct[0]?.numero} 
     fullWidth 
     />  */}
      <TextField id="outlined-basic"
       select label="Numéro de commande"
       defaultValue={state.editProduct[0]?.numero} 
       name="idNumero"  
      variant="outlined"
      size='small'
      fullWidth
      onChange={handleChangeUpdate}
      sx={{minWidth:'100%'}}
       >
      {nums.map((option) => (
          <MenuItem key={option.idNumero} value={option.idNumero}>
            {option.numero}
          </MenuItem>
        ))}
      </TextField>
      </Grid>


      <Grid item xs={12} >
      <TextField id="outlined-basic"
        name="idFournisseur"  
       select label="Fournisseur"
       defaultValue={state.editProduct[0]?.nomFournisseur} 
      variant="outlined"
      size='small'
      fullWidth
      onChange={handleChangeUpdate}
      sx={{minWidth:'100%'}}
       >
      {fournisseurs.map((option) => (
          <MenuItem key={option.idFournisseur} value={option.idFournisseur}>
            {option.nomFournisseur}
          </MenuItem>
        ))}
      </TextField>
      </Grid>

      <Grid item xs={12} >
      <TextField id="outlined-basic"
        name="codeIdProd"  
       select label="Produit"
       defaultValue={state.editProduct[0]?.nomProd} 
      variant="outlined"
      size='small'
      fullWidth
      onChange={handleChangeUpdate}
      sx={{minWidth:'100%'}}
       >
      {prod.map((option) => (
          <MenuItem key={option.codeIdProd} value={option.codeIdProd}>
            {option.nomProd}
          </MenuItem>
        ))}
      </TextField>
      </Grid>

      <Grid item xs={12} >

     <Grid item xs={12}>
                <TextField
                    id="date"
                    label="date Expiration"
                    type="date"
                    name="date_expiration"
                    onChange={handleChangeUpdate}
                    defaultValue={state.editProduct[0]?.date_expiration}                     
                       InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
      </Grid>
      <Grid item xs={12}>
                <TextField
                    id="date"
                    label="date commande"
                    type="date"
                    name="dateComde"
                    onChange={handleChangeUpdate}
                    defaultValue={state.editProduct[0]?.dateComde}                     
                       InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
      </Grid>
      </Grid>
      <Grid item xs={12} >
     <TextField id="outlined-basic" 
     label="Prix publique" 
     variant="outlined" 
     name="prixPublic" 
     onChange={handleChangeUpdate}
     defaultValue={state.editProduct[0]?.prixPublic} 
     fullWidth 
     />
      </Grid>
      <Grid item xs={12} >
     <TextField id="outlined-basic" 
     label="Prix de cession" 
     variant="outlined" 
     name="prixUnitaire" 
     onChange={handleChangeUpdate}
     defaultValue={state.editProduct[0]?.prixUnitaire} 
     fullWidth 
     />
      </Grid>
      <Grid item xs={12} >
     <TextField 
     id="outlined-basic" 
     label="Quantité commandée" 
     name="qteComder"  
     variant="outlined" 
     onChange={handleChangeUpdate}
     defaultValue={state.editProduct[0]?.qteComder} 
     fullWidth />
      </Grid>
      <Grid item xs={12} >
     <TextField 
     id="outlined-basic" 
     label="Quantité livrée" 
     name="quan_livree"  
     variant="outlined" 
     onChange={handleChangeUpdate}
     defaultValue={state.editProduct[0]?.quan_livree} 
     fullWidth />
      </Grid>
      <Grid item xs={12} >
                        <Button type='submit'  variant='contained' onClick={handleUpdate}
                            color='primary'>Valider</Button>
                            </Grid>
    </Grid>
    </Grid>
      </Container>
  </DialogContent>}
  </Dialog>}
  </>
    )
}

export default PendingCommandes
