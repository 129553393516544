import {  Typography } from '@material-ui/core'
import MaterialTable from 'material-table'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from "@material-ui/core/styles";
import CliniqueService from '../../services/CliniqueService'
import { toast } from "react-toastify";
import BonClientService from '../../services/BonClientService'
import Skeleton from '../skeleton/Skeleton';


const BonClientVendus = () => {
    const useStyles = makeStyles({
        root: {
          maxWidth: 345,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent:'center',
        }
      });
  const classes = useStyles();
  const [bonEncours,setBonEncours] = useState([])
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
         await BonClientService.getBonClientVendu()
          .then((response) => {
            setBonEncours(response.data)
            setLoading(false);
            //console.log("response.data"+JSON.stringify(response.data))
          })
          .catch(e => {
            setLoading(false);
            console.log(e);
         });
        }
     fetchData();
  }, [])
   

      const refreshBons = async ()=>{
        await BonClientService.getBonClientVendu()
           .then((response) => {
            setBonEncours(response.data)
            })
            .catch(e => {
              console.log(e);
            });
      }


    

  const columns = [
    {
     title: "PRODUIT", field: "nomProd"
   },
    { title: "PRIX PRODUIT", field: "prixProdVendu",filterPlaceholder:"filter"},
    { title: "NOM CLIENT", field: "nom",filterPlaceholder:"filter"},
    { title: "QUANTITE VENDUE", field: "qtVdu",filterPlaceholder:"filter"},
   // { title: "CODE BARRE", field: "codeBarre",filterPlaceholder:"filter" },
   /*  {
     title: "ETAT", field: "etat",
     render: (rowData) => <div style={{ background: rowData.etat === "VENDU" ? "#008000aa" : "#f90000aa",borderRadius:"4px",paddingLeft:5 }}>{rowData.etat}</div>,
      searchable: false, export: false
   },  */    
   { title: "NUMERO FACTURE ", field: "numFactSrt",filterPlaceholder:"filter" },
   { title: "TOTAL", field: "total",filterPlaceholder:"filter"},
   { title: "VENDEUR", field: "vendeur",filterPlaceholder:"filter"},
     //{ title: "Enregistre", field: "dateEng", type: "currency", currencySetting: { currencyCode: "INR", minimumFractionDigits: 1 },
     // cellStyle: { background:"#009688" }, headerStyle: { color: "#fff" } },
   ]

    return (
        <div className="App" style={{width:"100%", display:'flow',justifyContent:'center',alignItems:'center',top:0,bottom:0,margin:'auto'}}>
            <Typography variant="title" className={classes.title}>
              Liste des bons client vendus
            </Typography>
   {

    loading  ? <Skeleton type="circle" /> :

    <MaterialTable columns={columns} data={bonEncours}
    onRowClick={(evt,rowData)=>{
      //setSelectedRow(rowData)
      //setShowDialog(true)
     /*  history.push(
        {
          pathname: '/report',
          state: { data: rowData }
        }
      ) */
    }}
      editable={{
        onRowAdd: (newRow) => new Promise((resolve, reject) => {
          refreshBons()
          setTimeout(() => resolve(), 500)
        }),
        onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
          console.log("row === ",oldRow.idBonDeComdeMat)
          console.log("newRow === ",newRow)
          CliniqueService.updateBon(newRow,oldRow.idBonDeComdeMat)
              .then((response) => {
                toast.success("Votre commande modifiee avec success...", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
                refreshBons()
              })
              .catch((error) => {
                console.log(error);
                toast.error(error.response?.data, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              });
          setTimeout(() => resolve(), 500)
        }),
        onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
          console.log("selectedRow = "+selectedRow)
          BonClientService.deleteBonClient(selectedRow.idBon)
              .then((response) => {
                toast.success("Votre commande supprimee avec success...", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
                refreshBons()

              })
              .catch((error) => {
                console.log(error);
                toast.error(error.response?.data, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              });
          setTimeout(() => resolve(), 1000)
        })
      }}
    
      options={{
        selection: true
      }}
      onSelectionChange={(selectedRows) => console.log(selectedRows)}
      options={{
        sorting: true, search: true,
        searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
        filtering: true, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
        paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
        exportAllData: true, exportFileName: "LISTE DES BON DE COMMANDES", addRowPosition: "first", actionsColumnIndex: -1, selection: true,
        showSelectAllCheckbox: false, showTextRowsSelected: false, selectionProps: rowData => ({
          //disabled: rowData.age == null,  
          color:"primary"
        }),
         columnsButton: true,
        rowStyle: (data, index) => index % 2 === 0 ? { background: "#f5f5f5" } : null,
        headerStyle: { background: "#226D68",color:"#fff"},
        
      }}
      title="Liste des bons de clients vendus "
      icons={{ Add: () => <AddIcon /> }} />
   }
          </div>
    )
}

export default BonClientVendus
