import { Button, Container, Grid, TextField } from '@material-ui/core'
import React, {useEffect, useRef, useState } from 'react'
import CustomizedDialogs from '../dialog'
import {DeleteOutlined} from '@material-ui/icons';
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@material-ui/data-grid'

import { toast } from "react-toastify";
import BonClientService from '../../services/BonClientService'
import './styles.css'
import { Link } from 'react-router-dom'
import ClientService from '../../services/ClientService'
import {Alert, Autocomplete } from '@material-ui/lab'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import PayementForm from '../PayementForm'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import TypeClientService from '../../services/TypeClientService';
import PayeBon from '../PayeBon'
import generatePDFBon from '../../services/reportBonClient'
import { useReactToPrint } from 'react-to-print';
import { Modal, Table } from 'antd';
import moment from 'moment'


const ListBonClient = () => {
 
  const [data, setData] = useState([])
  const [type, setType] = useState([])
const [filters,setFilters] = useState([])
const [idClient,setIdClient] = useState()
const [sort,setSort] = useState("NON_PAYER")
const componentRef = useRef();
const [printBillModalVisibility, setPrintBillModalVisibilty] = useState(false);


const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
}




const handleDelete = async (id)=>{
   await  BonClientService.annulerBon(id)
   .then((response) => {
    toast.success(response.data.message, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
   })
   .catch((error) => {
       console.log(error)
   })
   
}



const btnStyle = { marginTop: 10, width: '100%',backgroundColor:'#F96B2B',color:'white'}

const columns = [
    { field: 'idBon', headerName: 'ID', width: 100 },
    {
      field: 'nom',
      headerName: 'Nom',
      width: 200
    },
    {
      field: 'adresse',
      headerName: 'Adresse',
      width: 120,
    },
    {
      field: 'beneficiaire',
      headerName: 'Beneficiare',
      width: 110,
    },
    {
      field: 'qtVdu',
      headerName: 'Quantité',
      width: 90,
    },
    {
      field: 'nomProd',
      headerName: 'Produit',
      width: 200,
    },
    {
      field: 'prixProdVendu',
      headerName: 'Prix Unitaire',
      width: 120,
    },
    {
        field: 'total',
        headerName: 'Total',
        width: 120,
      },
      {
        field: 'total_remise',
        headerName: 'Remise',
        width: 120,
      },
      {
        field: 'dateEnreg',
        headerName: 'Creation',
        width: 120,
        renderCell: (params) => {
          const date = new Date(params.row.dateEnreg);
          const formattedDate = date.toLocaleDateString('fr-FR'); // Remplacez 'fr-FR' par le code de votre région
          return <>{formattedDate}</>;
        },
      },
      {
        field: 'statut',
        headerName: 'Statut',
        width: 120,
        renderCell: (params) => {
          return (
            <>
            { params.row.statut =='PAYER' ?
            <div style={{
              color:'#008000aa'
            }}>
              {params.row.statut}
            </div>
            :
            <div style={{
              color:'#f90000aa'
            }}>
              {params.row.statut}
            </div>
          }
            </>
          );
        },
      },
      {
        field: 'action',
        headerName: 'Action',
        width: 200,
        renderCell:(params)=>{
            return(
               <>
                
                {
                  params.row.statut === 'NON_PAYER' && <CustomizedDialogs title="Payer" boutonTitle="payer" refresh={()=>refreshBons()}>
                  <PayeBon idBon={params.row.idBon}  />
               </CustomizedDialogs>
                }
              {
                params.row.statut === 'PAYER' &&  <DeleteOutlined className="userListDelete" onClick={()=>handleDelete(params.row.idBon)} />

              }
           
  </>
            )
        }
      },
  ];



  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  
  });


const columnIPMs = [
  { field: 'idBon', headerName: 'ID', width: 100 },
  {
    field: 'nom',
    headerName: 'Nom',
    width: 200
  },
  {
    field: 'adresse',
    headerName: 'Adresse',
    width: 120,
  },
  {
    field: 'beneficiaire',
    headerName: 'Beneficiare',
    width: 110,
  },
  {
    field: 'qtVdu',
    headerName: 'Quantité',
    width: 90,
  },
  {
    field: 'nomProd',
    headerName: 'Produit',
    width: 200,
  },
  {
      field: 'prixProdVendu',
      headerName: 'Prix',
      width: 120,
    },
    {
      field: 'total_client',
      headerName: 'Client',
      width: 120,
    },
    {
      field: 'total_ipm',
      headerName: 'Ipm',
      width: 120,
    },
    {
      field: 'statut',
      headerName: 'Statut',
      width: 120,
      renderCell: (params) => {
        return (
          <>
          { params.row.statut =='PAYER' ?
          <div style={{
            color:'#008000aa'
          }}>
            {params.row.statut}
          </div>
          :
          <div style={{
            color:'#f90000aa'
          }}>
            {params.row.statut}
          </div>
        }
          </>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      renderCell:(params)=>{
          return(
             <>
              
              {
                params.row.statut === 'NON_PAYER' && <CustomizedDialogs title="Payer" boutonTitle="payer" refresh={()=>refreshBons()}>
                <PayeBon idBon={params.row.idBon}  />
             </CustomizedDialogs>
              }
            {
              params.row.statut === 'PAYER' &&  <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}> 
                <DeleteOutlined className="userListDelete" onClick={()=>handleDelete(params.row.idBon)} /> 
                </div>
            }
         
</>
          )
      }
    },
];


useEffect(() => {
  const fetchData = async ()=>{
    await TypeClientService.getTypeClient()
    .then((response) => {
        var data = response.data
        console.log("types === "+JSON.stringify(data))
        setType(data)
    })
    .catch((error) => {
        console.log(error)
    })
  }
fetchData()
}, [])


  const handleStatut = async (e)=>{
    setSort(e.target.value)
     await BonClientService.getBonClientByStatut(idClient,e.target.value)
    .then((response) => {
         setData(response.data)
          console.log("sort data == ",response.data)
      })
      .catch(e => {
        console.log(e);
     });
    
}
  
  const handleFilters = async (e)=>{
    const value = e.target.value;

    await ClientService.getTypeClient(value)
        .then((response) => {
            var data = response.data
            setFilters(data)
            //alert(JSON.stringify(data));
        })
        .catch((error) => {
            console.log(error)
        })
    
}




const initialValues = {
  idClient: '',
  
}
const validationSchema = Yup.object().shape({
  idClient: Yup.number().required("Required"),
})
  
const onSubmit = (values, props) => {
  var produit = {
      "idClient":values.idClient,
  }

  setIdClient(produit.idClient)

  console.log("idClient"+produit.idClient);

  BonClientService.getBonByClient(produit.idClient)
  .then((response) => {
       // console.log("getBonByClient"+JSON.stringify(response.data));
       setData(response.data)
    })
    .catch(e => {
      console.log(e);
   });
  //props.resetForm()

}
  
const refreshBons = () =>{
  BonClientService.getBonByClient(idClient)
  .then((response) => {
       setData(response.data)
    })
    .catch(e => {
      console.log(e);
   });
}

function MyExportButton() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


    return (
      <>
      <div className="userList">
            <div style={{margin:'20px',display:'flex'}}>
            <select style={{padding:'10px',height:'40px'}} name ="color" onChange={handleFilters}>
                    <option>
                        Type Client
                    </option>
                    {
                      type.map((type)=>(
                        <option value={type.idType}>{type.designation}</option>

                      ))
                    }
                </select>
                
                <Grid item xs={12}>
                    <Container maxWidth="md">
           <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {(props) => (
                      <Form noValidate>
                          <Grid item xs={4}>
                                <Autocomplete
                                id="idClient"
                                name="idClient"
                                options={filters}
                                getOptionSelected={(option, value) => option.nom === value.nom}
                                getOptionLabel={option => option.nom}
                                  onChange={(e, v, r) => {
                                    var nom = JSON.stringify(v?.idClient)
                                    props.setFieldValue("idClient",v?.idClient)
                                  }}
                                renderInput={params => (
                                    <Field as={TextField} {...params} name="nom" label="Nom Client" variant="outlined" fullWidth
                                    error={props.errors.nom && props.touched.nom}
                                    helperText={<ErrorMessage name='nom' />}  required
                                    />
                                )}
                        />
                        
                        </Grid>
                        <Grid item xs={4} >
                                            <Button type='submit' style={btnStyle}  variant='contained'
                                                >Valider</Button>
                         </Grid>
                        
                        </Form>
                    )}
                </Formik>
                </Container>
                </Grid>
                <select style={{padding:'10px',height:'40px',marginRight:'200px'}} name ="color"  onChange={handleStatut}>
                  <option value="">Rechercher</option>
                  <option value="NON_PAYER">NON_PAYER</option>
                  <option value="PAYER">PAYER</option>
                </select>
                <div>
                <Button  style={btnStyle} disabled={data.length === 0} onClick={(e) => generatePDFBon(data)}  variant='contained'
                                            >Exporter</Button>
                </div>

            </div>

            <div className="infoContainer">
                <div style={{color:'darkblue',fontSize:20,padding:10}}>
                  Total  {data.length} ventes 
                </div >
                { data.length > 0 &&  sort === 'NON_PAYER' && <div style={{display:'flex',margin:'10px',justifyContent:'space-between'}}>
                  <CustomizedDialogs title="Payer" boutonTitle="payer tout" refresh={()=>refreshBons()}>
                     <PayementForm bons={data}  />
                   </CustomizedDialogs> 
                 {/*   <Button style={{marginLeft:'10px'}}  onClick={(e) => generatePDFBon(data)}  variant='contained'
                                                color='primary'>Exporter</Button> */}
                </div> 
                }
                <div style={{color:'crimson',fontSize:20,padding:10}} >
                Montant {data.reduce((a,v) =>  a = a + v.total , 0 )} Fcfa
                </div>
                  <div style={{color:'green',fontSize:20,padding:10}}>
                  { 
                    filters[0]?.idType === 2 && 
                <div>
                 Total Client {data.reduce((a,v) =>  a = a + v.total_client , 0 )} Fcfa --||--
                 Total IPM {data.reduce((a,v) =>  a = a + v.total_ipm , 0 )} Fcfa
                </div> }
                  </div>
            </div>
            
           { filters[0]?.idType === 1 ? <DataGrid
        rows={data}
        columns={columns}
        pageSize={10}
        getRowId={(row) => row.idBon}
        checkboxSelection
        components={{
          Toolbar: MyExportButton,
        }}
        autoHeight={true}
        disableSelectionOnClick
      />
       :
       <DataGrid
       rows={data}
       columns={columnIPMs}
       pageSize={10}
       getRowId={(row) => row.idBon}
       checkboxSelection
       components={{
        Toolbar: MyExportButton,
      }}
       autoHeight={true}
       disableSelectionOnClick
      />
    }

        </div>
   
</>
    )
  }
export default ListBonClient
