import http from "./AxiosInstance";

class ProduitVenduService {
  
// produit vendu

vendreProduit(data) {
  return http.put(`/prodvendus/vendre/`, data);
}

updateOneProdVendu(id) {
  return http.put(`/prodvendus/one/${id}`);
}

getProdVendus() {
  return http.get("/prodvendus/all");
}

getProdVenduPending() {
  return http.get("/prodvendus/pending");
}

getProdEtatVendu() {
  return http.get("/prodvendus/vendu");
}

getProdReport() {
  return http.get("/prodvendus/report");
}

createProdVendu(data){
  console.log("data === ",data)
  return http.post("/prodvendus/create",data)
}

saveProdVendu(data){
  console.log("data === ",data)
  return http.post("/prodvendus/save",data)
}

updateProdVendu(data,id) {
  return http.put(`/prodvendus/update/${id}`, data);
}

updateStatut(data) {
  return http.put(`/produits/all/updatestatut/`, data);
}


deleteProdVendu(id) {
  return http.delete(`/prodvendus/delete/${id}`);
}

venteJournee() {
  return http.get(`/prodvendus/journaliere/`);
}

venteHier() {
  return http.get(`/prodvendus/hier/`);
}

top10Daily(){
  return http.get(`/prodvendus/top-selling/daily`)
}

top10Yearly(){
  return http.get(`/prodvendus/top-selling/yearly`)
}

top10Monthly(){
  return http.get(`/prodvendus/top-selling/monthly`)
}

productStatistique(productName){
  return http.get(`/prodvendus/sales/monthly/${encodeURIComponent(productName)}`)
}

productSales15days(productName){
  return http.get(`/prodvendus/sales/fifteen-days/${encodeURIComponent(productName)}`)
}


venteMonth() {
  return http.get(`/prodvendus/month/`);
}

venteYear() {
  return http.get(`/prodvendus/year/`);
}



venteGerantJour(id) {
  return http.get(`/prodvendus/jour/${id}`);
}

venteGerantHier(id) {
  return http.get(`/prodvendus/yesterday/${id}`);
}

venteGerantMonth(id) {
  return http.get(`/prodvendus/mois/${id}`);
}

venteGerantYear(id) {
  return http.get(`/prodvendus/annee/${id}`);
}

searchVente(data) {
    return http.get(`/prodvendus/search/${data.date1}/${data.date2}`);
  
}

generateFacture(data) {
  console.log("data == "+data)
  return http.get(`/prodvendus/search/${data}`);

}



//vente des produits
venteProduitJour(nom) {
  return http.get(`/prodvendus/prod/jour/${encodeURIComponent(nom)}`,nom);
}

venteProduitHier(nom) {
  return http.get(`/prodvendus/prod/yesterday/${encodeURIComponent(nom)}`,nom);
}

venteProduitMonth(nom) {
  return http.get(`/prodvendus/prod/mois/${encodeURIComponent(nom)}`,nom);
}

venteProduitYear(nom) {
  return http.get(`/prodvendus/prod/annee/${encodeURIComponent(nom)}`,nom);
}




}

export default new ProduitVenduService ();