

import { Button, Container, Grid,TextField } from '@material-ui/core'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from 'yup'
import { toast } from "react-toastify";
import DoseService from '../services/DoseService'


const DosageForm = () => {


  const auth= useSelector((state) => state.auth);
  const produits = useSelector((state) => state.produits);
  const dispatch = useDispatch();


  const useStyles = makeStyles({
    root: {
      maxWidth: 345,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent:'center',
    }
  });

  const btnStyle = { marginTop: 10, width:'100%' }
    const [prod,setProd] = useState([])


  useEffect(() => {
    

    const fetchData = async () => {
    

   }

   fetchData();
  }, [dispatch]);
    
  const initialValues = {
    designation: '', 
}
const validationSchema = Yup.object().shape({
  designation: Yup.string().required("Required"),
  })
const onSubmit = (values, props) => {

    var forme = {
      "designation":values.designation.trim(),
      "speudo":auth.nom,
    }
    //alert(JSON.stringify(forme), null, 2)
    DoseService.createDosage(forme)
       .then((response) => {
         toast.success("Dosage ajoute avec success...", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
       })
       .catch((error) => {
         console.log(error);
         toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
    //props.resetForm()
}




    return (
        <div>
             <Grid item xs={12}>
        <Container maxWidth="md">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {(props) => (
                        <Form noValidate>
                     <Grid container spacing={2}>
                    <Grid item xs={12} >
                            <Field as={TextField} name='designation' label='Dosage' fullWidth variant="outlined"
                                error={props.errors.designation && props.touched.designation}
                                helperText={<ErrorMessage name='designation' />} required />
                    </Grid>                           
                               <Grid item xs={12} >
                            <Button type='submit' style={btnStyle} variant='contained'
                                color='primary'>Valider</Button>
                                </Grid>
                                                </Grid>
                        </Form>
                    )}
                </Formik>
                </Container>
                </Grid>
        </div>
    )
}

export default DosageForm
