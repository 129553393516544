import { toast } from "react-toastify";

const depenseReducer = (depenses = [], action) => {
  switch (action.type) {
    case "GET_DEPENSES":
      return action.depenses.data;
    case "ADD_DEPENSE":
      toast.success("A DEPENSE was added...", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return [action.depense.data, ...depenses];
    case "UPDATE_DEPENSE":
      toast.success("A DEPENSE was updated...", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return depenses.map((depense) =>
        depense.idDepense === action.depense.data.idDepense ? action.depense.data : depense
      );
    case "CHECK_DEPENSE":
      toast.success("A DEPENSE status was changed...", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return depenses.map((depense) =>
        depense.idDepense === action.depense.data.idDepense ? action.depense.data : depense
      );
    case "DELETE_DEPENSE":
      toast.success("A DEPENSE was deleted...", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return depenses.filter((depense) => depense.idDepense !== action.id);
    case "CLEAR_DEPENSES":
      return [];
    default:
      return depenses;
  }
};

export default depenseReducer;