import React, { useContext, useState } from 'react'

import CancelIcon from '@material-ui/icons/Cancel';
import './alert.css'



export const Alert = ({setOpen,data}) => {









  const handleChange = (e,type) => {
  
  };

  const handleClick = async (e,type) => {
    
  };




const handleDelete = async () => {


  setOpen(false);
}
 




  return (
    <div className="formDelete">
  <div className="formWrapperDelete">
  <CancelIcon
          className="rclose"
          size="2x"
          onClick={() => setOpen(false)}
        />
      <div className="scroll">
      
              <div>
                 
                <ul style={{display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
                    <div className="test" style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                    <li>{data.nomProd}  </li>
                    <li>{data.qtEntrer} </li>
                    <li>{data.statut_produit}</li>
                    </div>
                </ul>
              </div>
      </div>      
          
    </div>

    </div>
  )
}
