import http from "./AxiosInstance";

class FournisseurService {
  
    getFournisseurs() {
        return http.get("/fournisseurs/all");
      }
    
      createFournisseur(data){
        console.log("data === ",data)
        return http.post("/fournisseurs/new",data)
      }
    
      updateFournisseur(data,id) {
        return http.put(`/fournisseurs/update/${id}`, data);
      }
    
      deleteFournisseur(id) {
        return http.delete(`/fournisseurs/delete/${id}`);
      }
  
 
}

export default new FournisseurService ();