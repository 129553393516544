import {Typography } from '@material-ui/core'
import MaterialTable from 'material-table'
import React, { useEffect, useState } from 'react'
import CustomizedDialogs from '../dialog'
import GetAppIcon from '@material-ui/icons/GetApp';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from "@material-ui/core/styles";
import TypeClientService from '../../services/TypeClientService'
import { toast } from "react-toastify";
import TypeClientForm from '../TypeClientForm'
import FondService from '../../services/FondService';
import FondForm from '../FondForm';
import { useSelector } from 'react-redux';



const FondCaisse = () => {
    const useStyles = makeStyles({
        root: {
          maxWidth: 345,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent:'center',
        }
      });
  const classes = useStyles();
  const [formes,setFormes] = useState([])
  const auth= useSelector((state) => state.auth);


  useEffect(() => {
    const fetchData = async () => {
       await FondService.getFonds()
          .then((response) => {
            setFormes(response.data)
          })
          .catch(e => {
            console.log(e);
         });
        }

        
     fetchData();
  }, [])
   

      const refreshFormes = async ()=>{
        await FondService.getFonds()
           .then((response) => {
             const res = response.data
             setFormes(res)

            })
            .catch(e => {
              console.log(e);
            });
        return formes
      }

    
    

  const columns = [
    { title: "ID", field: "id", filterPlaceholder: "filter" },
    { title: "MONTANT", field: "montant", filterPlaceholder: "filter" },
    { title: "LIBELLE", field: "libelle", filterPlaceholder: "filter" },
    { title: "CREATEUR", field: "pseudo", filterPlaceholder: "filter",editable: 'never'},
    { title: "DATE AJOUT", field: "dateAjout", filterPlaceholder: "filter",type: "date" },
    { title: "DATE MODIFICATION", field: "creation", filterPlaceholder: "filter",type: "date",editable: 'never' },
   ]

    return (
        <div className="App" style={{width:"100%", display:'flow',justifyContent:'center',alignItems:'center',top:0,bottom:0,margin:'auto'}}>
            <Typography variant="title" className={classes.title}>
            Liste des fonds de caisse
            </Typography>
            <div style={{display:"flex",justifyContent:'flex-start',margin:15}}>
        <div>
        <CustomizedDialogs title="Fond de caisse" boutonTitle="ajouter le fond" refresh={()=>refreshFormes()} >
                   <FondForm />
       </CustomizedDialogs>
      </div>
        </div>
            <MaterialTable columns={columns} data={formes}
      onRowClick={(evt,rowData)=>{
        //setSelectedRow(rowData)
        //setShowDialog(true)
       /*  history.push(
          {
            pathname: '/report',
            state: { data: rowData }
          }
        ) */
      }}
        editable={{
          onRowAdd: (newRow) => new Promise((resolve, reject) => {
              console.log("newRow === ",newRow)
              FondService.createFond(newRow)
                  .then((response) => {
                    toast.success("Fond ajoute avec success...", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    refreshFormes()
                  })
                  .catch((error) => {
                    console.log(error);
                    toast.error(error.response?.data, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  });
            refreshFormes()
            setTimeout(() => resolve(), 500)
          }),
          onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
            console.log(newRow)
            let fond = {
              montant:newRow.montant,
              libelle:newRow.libelle,
              speudo:auth.nom,
              dateAjout:newRow.dateAjout,              
            }
            console.log(fond)
            console.log(oldRow)

              FondService.updateFond(fond,oldRow.id)
                  .then((response) => {
                    toast.success("Fond modifié avec success...", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    refreshFormes()
                  })
                  .catch((error) => {
                    console.log(error);
                    toast.error(error.response?.data, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  });
            refreshFormes()
            setTimeout(() => resolve(), 500)
          }),
          onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
            if(auth.rol==='admin'){
              FondService.deleteFond(selectedRow.id)
              .then((response) => {
               
                toast.success("Fond supprimé avec success...", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
                refreshFormes()
              })
              .catch((error) => {
                console.log(error);
                toast.error(error.response?.data, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              });
        setTimeout(() => resolve(), 1000) 
            }
            else{
              toast.error("Impossible de supprimer", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              setTimeout(() => resolve(), 1000) 

            }
            
          })
        }}
        actions={[
          {
            icon: () => <GetAppIcon />,
            tooltip: "details",
            onClick: (event, rowData) => {
              console.log(rowData)
            },
            
          }
        ]}
        options={{
          selection: true
        }}
        onSelectionChange={(selectedRows) => console.log(selectedRows)}
        options={{
          sorting: true, search: true,
          searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
          filtering: true, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
          paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
          exportAllData: true, exportFileName: "Fournisseurs", addRowPosition: "first", actionsColumnIndex: -1, selection: true,
          showSelectAllCheckbox: false, showTextRowsSelected: false, selectionProps: rowData => ({
            //disabled: rowData.age == null,  
            color:"primary"
          }),
           columnsButton: true,
          rowStyle: (data, index) => index % 2 === 0 ? { background: "#f5f5f5" } : null,
          headerStyle: { background: "#226D68",color:"#fff"},
          
        }}
        title="Liste des Fonds de caisse "
        icons={{ Add: () => <AddIcon /> }} />
          </div>
    )
}

export default FondCaisse
