import React,{useState,useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import MaterialTable from 'material-table'
import GetAppIcon from '@material-ui/icons/GetApp';
import AddIcon from '@material-ui/icons/Add';
import { getUsers,deleteUser, updateUser } from '../../redux/actions/userActions';
import CustomizedDialogs from '../../components/dialog';
import UserForm from '../../components/UserForm';
import { Grid, Paper, TextField, Button, Typography, Container} from '@material-ui/core';
import CliniqueService from '../../services/CliniqueService';
import PropTypes from 'prop-types';
import * as Yup from 'yup'
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";



import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import Avatar from '@material-ui/core/Avatar';


import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Fournisseur from '../../components/Fournisseur/Fournisseur';
import FPharmaceutique from '../../components/FPharmaceutique';
import Famille from '../../components/Famille.js';
import SousFamille from '../../components/SousFamille.js';
import TypeClient from '../../components/TypeClient.js';
import Client from '../../components/Client.js';
import Dosage from '../../components/Dosage';


function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};




const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent:'center',
  }
});


const Users=()=> {

  const [value, setValue] = useState(0);
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

const handleChange = (event, value) => {
  setValue(value);
};
const classes = useStyles();



  //const users = useSelector((state) => state.users);
  const dispatch = useDispatch();


useEffect(() => {
  const fetchData = async () => {
   await CliniqueService.getAllUsers()
      .then((response) => {
         setIsLoading(false);
         setUsers(response.data)
      })
      .catch(e => {
        setIsLoading(false);
        setIsError(true);
        console.log(e);
     });

     await  CliniqueService.getRoles()
        .then((response) => {
           setRoles(response.data)
        })
        .catch(e => {
          console.log(e);
        });
    
    }
    fetchData()
}, [])




  const refreshUsers= async ()=>{
    await CliniqueService.getAllUsers()
        .then((response) => {
           setIsLoading(false);
           setUsers(response.data)
        })
        .catch(e => {
          setIsLoading(false);
          setIsError(true);
          console.log(e);
        });
       await  CliniqueService.getRoles()
        .then((response) => {
           setRoles(response.data)
        })
        .catch(e => {
          console.log(e);
        });
  }

  const handleDeleteRole =  (role)=>{
     CliniqueService.deleteRole(role)
        .then(response => {
          toast("Role supprime avec success...", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          refreshUsers();
        })
        .catch((error) => {
          console.log(error.response);
        toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        });
  }

  

  const paperStyle = { padding: '0 15px 40px 15px', width: 600,height:500 }
  const btnStyle = { marginTop: 10, width:'100%', backgroundColor: '#f26522',color:'white'}
  const initialValues = {
      rol: '', 
  }
  const validationSchema = Yup.object().shape({
      rol: Yup.string().required("Required"),
    })
  const onSubmit =  (values, props) => {

      var user = {
          "rol":values.rol.trim(),
      }
      //alert(JSON.stringify(user), null, 2)
       CliniqueService.createRole(user)
      .then(response => {
        toast("Role ajoute avec success...", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        refreshUsers()
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });

      //props.resetForm()
  }



  const columns = [
    { title: "Nom", field: "nom", sorting: false, filtering: true, headerStyle: { color: "#fff" } },
    { title: "Prenom", field: "prenom", filterPlaceholder: "filter" },
    { title: "PSEUDO", field: "speudo", align: "center" },
    { title: "PASSWORD", field: "passwd", align: "center"},
    { title: "PASSWORD", field: "passwd", align: "center",render: rowData => <p>{rowData.passwd?.split('').map(() => '*')}</p>,
    editComponent: props => (
        <TextField
           type="password"
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
            
        />)},
    {
      title: "Role", field: "rol",
      render: (rowData) => <div style={{ background: rowData.rol == "admin" ? "#008000aa" : "#f90000aa",borderRadius:"4px",paddingLeft:5 }}>{rowData.rol}</div>,
       searchable: false, export: false
    },
    // { title: "Gender", field: "gender", lookup: { M: "Male", F: "Female" } },
    // { title: "City", field: "city",filterPlaceholder:"filter" },
    // { title: "School Fee", field: "fee", type: "currency", currencySetting: { currencyCode: "INR", minimumFractionDigits: 1 },
    // cellStyle: { background:"#009688" }, headerStyle: { color: "#fff" } },
  ]

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App" style={{width:"90%", display:'flow',justifyContent:'center',alignItems:'center',top:0,bottom:0,margin:'auto',marginTop:100}}>
        <h1 align="center"  >Utilisateurs</h1>
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Utilisateurs" />
            <Tab label="Roles" />
            <Tab label="Fournisseurs" />
            <Tab label="Formes" />
            <Tab label="Dosages" />
            <Tab label="Familles" />
            <Tab label="Sous-Familles" />
          </Tabs>
        </AppBar>
        {value === 0 && <TabContainer>
          <div style={{display:'flex',justifyContent:'flex-start',margin:10}}>
        <div>
         <CustomizedDialogs title="Nouvel utilisateur" boutonTitle="Ajouter un utilisateur" refresh={()=>refreshUsers()}>
                   <UserForm />
       </CustomizedDialogs>
      </div>
        </div>
      <MaterialTable columns={columns} data={users}
        editable={{
          onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
            dispatch(updateUser(newRow,oldRow.id))
            refreshUsers()
            setTimeout(() => resolve(), 500)
          }),
          onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
            dispatch(deleteUser(selectedRow.id))
            refreshUsers()
            setTimeout(() => resolve(), 1000)
          })
        }}
        actions={[
          {
            icon: () => <GetAppIcon />,
            tooltip: "Click me",
            onClick: (e, data) => console.log(data),
            // isFreeAction:true
          }
        ]}
        onSelectionChange={(selectedRows) => console.log(selectedRows)}
        options={{
          sorting: true, search: true,
          searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
          filtering: true, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
          paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
          exportAllData: true, exportFileName: "TableData", addRowPosition: "first", actionsColumnIndex: -1, selection: true,
          showSelectAllCheckbox: false, showTextRowsSelected: false, selectionProps: rowData => ({
            disabled: rowData.age == null,
            // color:"primary"
          }),
           columnsButton: true,
          rowStyle: (data, index) => index % 2 === 0 ? { background: "#f5f5f5" } : null,
          headerStyle: { background: "#226D68",color:"#fff"}
        }}
        title="Liste des utilisateurs "
        icons={{ Add: () => <AddIcon /> }} />
          </TabContainer>}
       
       
        {value === 1 && <TabContainer>
          <div style={{display:'flex',}}>
          <Grid container spacing={2}>
            <Paper elevation={0} style={paperStyle}>
                <Grid align='center'>
                    <Typography variant='h6' >Ajouter un role</Typography>
                </Grid>
                <Grid item xs={12}>
        <Container maxWidth="md">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {(props) => (
                        <Form noValidate>
                     <Grid container spacing={2}>
                    <Grid item xs={12} >
                            <Field as={TextField} name='rol' label='Role' fullWidth variant="outlined"
                                error={props.errors.rol && props.touched.rol}
                                helperText={<ErrorMessage name='rol' />} required />
                    </Grid>
                            
                               <Grid item xs={12} >
                            <Button type='submit' style={btnStyle} variant='contained'>Enregistrer</Button>
                                </Grid>
                                                </Grid>
                        </Form>
                    )}
                </Formik>
                </Container>
                </Grid>
                

                
            </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
            <Typography variant="title" className={classes.title}>
              Liste des roles
            </Typography>
            <div className={classes.demo}>
              <List>
                {roles.map((item)=>(
                  <ListItem>
                    <ListItemAvatar>
                    <Avatar>
                        <PersonOutlineIcon color="primary" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.rol}
                    />
                    <ListItemSecondaryAction >
                     {/*  <IconButton aria-label="Delete">
                        <DeleteIcon color="secondary" onClick={()=>handleDeleteRole(item.rol)} />
                      </IconButton> */}
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </div>
          </Grid>
          </div>
        
          </TabContainer>}
          {value === 2 && <TabContainer>
            <Fournisseur />
          </TabContainer>}
          
          {value === 3 && <TabContainer>
            <FPharmaceutique />
          </TabContainer>}

          {value === 4 && <TabContainer>
            <Dosage />
          </TabContainer>}

          {value === 5 && <TabContainer>
            <Famille />
          </TabContainer>}

          {value === 6 && <TabContainer>
            <SousFamille />
          </TabContainer>}

          

        
    </div>
  );
}

export default Users;