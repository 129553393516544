import React,{useState,useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { AppBar, Grid, Hidden, Tab, Tabs,Typography } from '@material-ui/core';

import PropTypes from 'prop-types';
import BonClientService from '../../services/BonClientService';
import ListCommandes from './ListCommandes';
import AjoutCommandes from './AjoutCommandes ';
import ListNumeros from './ListNumeros';
import ProduitDefectueux from '../ProduitDefectueux';
import RetourCommande from '../RetourCommande';



function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const Commandes=()=> {

  const auth = useSelector((state) => state.auth);
  const [prodVenduPending,setProdVenduPending] = useState([])




  useEffect(() => {
    refreshProd()
  }, []);

  
  const refreshProd = async () =>{
    await  BonClientService.getBonClientPending(auth.nom)
          .then((response) => {
           // alert(JSON.stringify(response.data));
           setProdVenduPending(response.data)
           })
           .catch(e => {
             console.log(e);
           });
  }



 
  
  const [value, setValue] = useState(0);

const handleChange = (event, value) => {
  setValue(value);
};

//if (!auth.id) {navigate("/login")};

const columns = [
    { title: "ID", field: "idDepense",editable: 'never'},
    { title: "INTITULE", field: "intitule", filterPlaceholder: "filter" },
  //  { title: "QUANTITE", field: "quantite", align: "center" },
    { title: "CREATEUR", field: "speudo", align: "center",filterPlaceholder: "filter",editable: 'never' },
    { title: "MONTANT", field: "montant",filterPlaceholder: "filter"},
    { title: "DATE OPERATION", field: "dateOper",filterPlaceholder:"filter",type: "datetime" },
    { title: "COMMENTAIRE", field: "commentaire",filterPlaceholder: "filter"},

    //{ title: "Enregistre", field: "dateEng", type: "currency", currencySetting: { currencyCode: "INR", minimumFractionDigits: 1 },
    // cellStyle: { background:"#009688" }, headerStyle: { color: "#fff" } },
  ]
  return (
    <div className="App" style={{width:"90%", display:'flow',justifyContent:'center',alignItems:'center',top:0,bottom:0,margin:'auto',marginTop:100}}>
        <h1 align="center"  >Les commandes</h1>
        <AppBar position="static">{/* 
        <Grid container justify="start">
        <Grid item xs={24} sm={8} md={6}> */}
        <Tabs value={value} onChange={handleChange}>
            <Tab label="Commandes fournisseurs" />
            <Tab label="Ajouter des commandes" />
              <Tab label="Liste des commandes" />
              <Tab label="Produits avariés" />
              <Tab label="Retour commande" />
          </Tabs>
    {/*       </Grid>
      </Grid> */}

        </AppBar>
        {value === 0 && <TabContainer>
           <ListCommandes />
        </TabContainer>}

        {value === 1 && <TabContainer>
           <AjoutCommandes />
        </TabContainer>}

        {value === 2 && <TabContainer>
           <ListNumeros />
        </TabContainer>}
        {value === 3 && <TabContainer>
           <ProduitDefectueux />
        </TabContainer>}

        {value === 4 && <TabContainer>
           <RetourCommande />
        </TabContainer>}

    </div>

  );
}


export default Commandes;


