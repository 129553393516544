import http from "./AxiosInstance";

class ProduitService {
  
  getAllProduits() {
    return http.get("/produits/all");
  }

  getAllStocks() {
    return http.get("/produits/all/stock");
  }


  getNullStocks() {
    return http.get("/produits/stock/zero");
  }
  getNameProd(id) {
    return http.get(`/produits/${id}`,id);
  }

  getAllProduitDistincts() {
    return http.get("/produits/distinct/all");
  }
  
  
updateProduit(data,id){
  //alert(id)
  return http.put(`/produits/update/${id}`,data)
}

importExcel(id,data){
  return http.post(`/produits/import/${id}`,data);
}

importData(id,data){
  return http.post(`/produits/importSave/${id}`,data);
}


// Famille

getFamilles() {
  return http.get("/familles/all");
}

createFamille(data){
  console.log("data === ",data)
  return http.post("/familles/create",data)
}

updateFamille(data,id) {
  return http.put(`/familles/update/${id}`, data);
}

deleteFamille(id) {
  return http.delete(`/familles/delete/${id}`);
}




// produit vendu

vendreProduit(data) {
  return http.put(`/prodvendus/vendre/`, data);
}


getProdVendus() {
  return http.get("/prodvendus/all");
}

getProdVenduPending() {
  return http.get("/prodvendus/pending");
}

getProdEtatVendu() {
  return http.get("/prodvendus/vendu");
}

getProdReport() {
  return http.get("/prodvendus/report");
}

createProdVendu(data){
  console.log("data === ",data)
  return http.post("/prodvendus/create",data)
}

updateProdVendu(data,id) {
  return http.put(`/prodvendus/update/${id}`, data);
}

updateStatut(data) {
  return http.put(`/produits/all/updatestatut/`, data);
}


deleteProdVendu(id) {
  return http.delete(`/prodvendus/delete/${id}`);
}



 

  // bon
  createBon (data){
    console.log("data === ",data)
    return http.post("/bonCommandes/create",data);
  }


  updateBon(data,id) {
    return http.put(`/bonCommandes/update/${id}`, data);
  }

  commandeBon(data) {
    return http.put(`/bonCommandes/commander/`, data);
  }


  deleteBon(id) {
    return http.delete(`/bonCommandes/${id}`);
  }





  getFormePharmaceutiques() {
    return http.get("/formes/all");
  }
  
  
  

  createRole(data) {
    return http.post("/users/role/create", data);
  }

  update(id, data) {
    return http.put(`/tutorials/${id}`, data);
  }

  deleteRole(rol) {
    return http.delete(`/users/role/delete/${rol}`);
  }

  deleteAll() {
    return http.delete(`/tutorials`);
  }

  findByTitle(title) {
    return http.get(`/tutorials?title=${title}`);
  }
}

export default new ProduitService ();