

import { Button, Container, Grid, MenuItem,TextField,Select } from '@material-ui/core'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from 'yup'
import { toast } from "react-toastify";
import sousfamilleService from '../services/SousfamilleService';
import FamileService from '../services/FamileService';


const SousFamilleForm = () => {


  const auth= useSelector((state) => state.auth);
  const produits = useSelector((state) => state.produits);


 
  const btnStyle = { marginTop: 10, width:'100%' }
  
    const [sousFamilles,setSousFamille] = useState([])


  useEffect(() => {
    

    const fetchData = async () => {
      await FamileService.getFamilles()
         .then((response) => {
           setSousFamille(response.data)
         })
         .catch(e => {
           console.log(e);
        });
       }

   fetchData();
  }, []);
    
  const initialValues = {
    intituleFam: '', 
    designation: '', 
}
const validationSchema = Yup.object().shape({
  intituleFam: Yup.string().required("Required"),
  designation: Yup.string().required("Required"),
  })
const onSubmit = (values, props) => {

    var forme = {
      "intituleFam":values.intituleFam.trim(),
      "designation":values.designation.trim(),
      "speudo":auth.nom,
    }
    //alert(JSON.stringify(forme), null, 2)
    sousfamilleService.createsousFamille(forme)
       .then((response) => {
         toast.success("Sous Famille ajoutee avec success...", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
       })
       .catch((error) => {
         console.log(error);
         toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
    //props.resetForm()
}




    return (
        <div>
             <Grid item xs={12}>
        <Container maxWidth="md">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {(props) => (
                        <Form noValidate>
                     <Grid container spacing={2}>
                     <Grid item xs={12} >
                            <Field as={Select} name="intituleFam" label='Famille' placeholder='famille' fullWidth variant="outlined"
                                error={props.errors.intituleFam && props.touched.intituleFam}
                                helperText={<ErrorMessage name='intituleFam' />} required >
                                     {
                                        sousFamilles.map((f)=>(
                                            <MenuItem value={f.intituleFam}>{f.intituleFam}</MenuItem>
                                        )
                                        )
                                    }
                                        </Field>
                                </Grid> 
                    <Grid item xs={12} >
                            <Field as={TextField} name='designation' label='Designation' fullWidth variant="outlined"
                                error={props.errors.designation && props.touched.designation}
                                helperText={<ErrorMessage name='designation' />} required />
                    </Grid>                          
                               <Grid item xs={12} >
                            <Button type='submit' style={btnStyle} variant='contained'
                                color='primary'>Valider</Button>
                                </Grid>
                                                </Grid>
                        </Form>
                    )}
                </Formik>
                </Container>
                </Grid>
        </div>
    )
}

export default SousFamilleForm
