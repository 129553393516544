import http from "./AxiosInstance";

class FondService {
  
// dosage
getFonds() {
  return http.get("/fond/all");
}


  createFond(data){
    console.log("data === ",data)
    return http.post("/fond/create",data)
  }

  updateFond(data,id) {
    return http.put(`/fond/update/${id}`, data);
  }

  deleteFond(id) {
    return http.delete(`/fond/delete/${id}`);
  }

  fondJournee() {
    return http.get(`/fond/jour/`);
  }
  
  fondHier() {
    return http.get(`/fond/hier/`);
  }
  
  fondMonth() {
    return http.get(`/fond/mois/`);
  }
  
  fondYear() {
    return http.get(`/fond/annee/`);
  }


  depensesJournee() {
    return http.get(`/depenses/jour/`);
  }
  
  depensesHier() {
    return http.get(`/depenses/hier/`);
  }
  
  depensesMonth() {
    return http.get(`/depenses/mois/`);
  }
  
  depensesYear() {
    return http.get(`/depenses/annee/`);
  }
  
}

export default new FondService ();