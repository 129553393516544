import React, { useState, useEffect } from 'react';
import ProduitVenduService from '../../services/ProduitVenduService';
import { Container, Grid, TextField, FormControlLabel, Radio, RadioGroup, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import CliniqueService from '../../services/CliniqueService';
import { Autocomplete } from '@material-ui/lab';
import Skeleton from '../skeleton/Skeleton';
import { Bar, Line, Doughnut } from 'react-chartjs-2'; // Importation des types de graphiques
import './styles.css';

function SearchRProduit() {
  const [filters, setFilters] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterType, setFilterType] = useState('monthly'); // 'monthly' or 'fifteenDays'
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [chartType, setChartType] = useState('bar'); // Type de graphique: 'bar', 'line', 'doughnut'

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await CliniqueService.getAllProduitDistincts();
        setFilters(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedProduct) {
      fetchSalesData(selectedProduct);
    }
  }, [filterType]);

  const initialValues = {
    nomProd: ''
  };

  const validationSchema = Yup.object().shape({
    nomProd: Yup.string().required('Required')
  });

  const fetchSalesData = async (nomProd) => {
    setLoading(true);
    try {
      let response;
      if (filterType === 'monthly') {
        response = await ProduitVenduService.productStatistique(nomProd);
      } else {
        response = await ProduitVenduService.productSales15days(nomProd);
      }
      setSalesData(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleFilters = (nomProd) => {
    setSelectedProduct(nomProd);
    fetchSalesData(nomProd);
  };

  const getMonthName = (monthNumber) => {
    const monthNames = [
      'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
      'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ];
    return monthNames[monthNumber - 1];
  };

  const chartData = {
    labels: filterType === 'monthly'
      ? salesData.map((data) => getMonthName(data.month))
      : salesData.map((data) => `${data.startDate} - ${data.endDate}`),
    datasets: [
      {
        label: filterType === 'monthly' ? 'Ventes Mensuelles' : 'Ventes sur 15 Jours',
        data: salesData.map((data) => data.totalQuantity),
        backgroundColor: filterType === 'monthly' ? 'rgba(75, 192, 192, 0.6)' : 'rgba(255, 99, 132, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
        fill: false,
      }
    ]
  };

  const chartOptions = {
    title: {
      display: true,
      text: `Ventes ${filterType === 'monthly' ? 'Mensuelles' : 'sur 15 Jours'} pour ${selectedProduct}`,
      fontSize: 20
    },
    legend: {
      display: true,
      position: 'right'
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      callbacks: {
        label: (tooltipItem) => {
          return `Quantité: ${tooltipItem.yLabel}`;
        },
        title: (tooltipItems) => {
          return filterType === 'monthly'
            ? getMonthName(tooltipItems[0].xLabel)
            : tooltipItems[0].xLabel;
        }
      }
    }
  };

  const renderChart = () => {
    switch (chartType) {
      case 'line':
        return <Line data={chartData} options={chartOptions} />;
      case 'doughnut':
        return <Doughnut data={chartData} options={chartOptions} />;
      default:
        return <Bar data={chartData} options={chartOptions} />;
    }
  };

  return (
    <div className="content">
      <div style={{ marginTop: '50px', left: 0 }}>
        <Grid container item xs={12} alignItems="center" spacing={2} style={{ marginTop: '-50px', marginLeft: '120px' }}>
          <Container maxWidth="md">
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleFilters}>
              {(props) => (
                <Form noValidate>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6}>
                      <Autocomplete
                        id="nomProd"
                        name="nomProd"
                        options={filters}
                        getOptionSelected={(option, value) => option.nomProd === value.nomProd}
                        getOptionLabel={(option) => option.nomProd}
                        onChange={(e, v, r) => {
                          props.setFieldValue('nomProd', v?.nomProd);
                          handleFilters(v?.nomProd);
                        }}
                        renderInput={(params) => (
                          <Field
                            as={TextField}
                            {...params}
                            name="nomProd"
                            label="Nom du produit"
                            variant="outlined"
                            fullWidth
                            error={props.errors.nomProd && props.touched.nomProd}
                            helperText={<ErrorMessage name="nomProd" />}
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel>Type de graphique</InputLabel>
                        <Select
                          value={chartType}
                          onChange={(e) => setChartType(e.target.value)}
                          label="Type de graphique"
                        >
                          <MenuItem value="bar">Barres</MenuItem>
                          <MenuItem value="line">Lignes</MenuItem>
                          <MenuItem value="doughnut">Cercle</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <RadioGroup
                      row
                      name="filterType"
                      value={filterType}
                      onChange={(e) => setFilterType(e.target.value)}
                    >
                      <FormControlLabel value="monthly" control={<Radio />} label="Mois" />
                      <FormControlLabel value="fifteenDays" control={<Radio />} label="15 Jours" />
                    </RadioGroup>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Container>
        </Grid>
      </div>

      {loading ? (
        <Skeleton type="circle" />
      ) : (
        <div className="chart-container">
          {renderChart()}
        </div>
      )}
    </div>
  );
}

export default SearchRProduit;
