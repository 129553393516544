import http from "./AxiosInstance";

class BonService {
  // bon
  createBon (data){
    console.log("data === ",data)
    return http.post("/boncommandes/create",data);
  }


  updateBon(data,id) {
    return http.put(`/boncommandes/update/${id}`, data);
  }

  commandeBon(data) {
    return http.put(`/boncommandes/commander/`, data);
  }

  saveAllCommande(data) {
    return http.put(`/boncommandes/savecommande/`, data);
  }



  deleteBon(id) {
    return http.delete(`/boncommandes/${id}`);
  }
  getBons() {
    return http.get("/boncommandes/all");
  }

  getBonByFournisseurs(idFournisseur) {
    return http.get(`/boncommandes/${idFournisseur}`);
  }


  getBonByNomFournisseurs(idFournisseur) {
    return http.get(`/boncommandes/filter/${idFournisseur}`);
  }

  getBonByNumbers(idNumero) {
    return http.get(`/boncommandes/numero/${idNumero}`);
  }
  
  getBonsEncours() {
    return http.get("/boncommandes/pending/all");
  }


  getBonsCommande() {
    return http.get("/boncommandes/commande/all");
  }
  

 
}

export default new BonService ();