import http from "./AxiosInstance";

class RetourCommandeService {
  

getRetours() {
  return http.get("/retour/all");
}


  createRetour(data){
    return http.post("/retour/create",data)
  }

  updateRetour(data,id) {
    return http.put(`/retour/update/${id}`, data);
  }

  deleteRetour(id) {
    return http.delete(`/retour/delete/${id}`);
  }
}

export default new RetourCommandeService ();