

import React, { useState,useEffect } from 'react'
import ProduitVenduService from '../../services/ProduitVenduService'
import RecolteCat from '../RecolteCat'
import { Button, Container, Grid, TextField} from '@material-ui/core'

import './styles.css'
import { Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import CliniqueService from '../../services/CliniqueService'
import { Autocomplete } from '@material-ui/lab'


function SearchVentes() {
    const [journee,setJournee]=useState(0)
    const [mois,setMois]=useState(0)
    const [hier,setHier]=useState(0)
    const [annee,setAnne]=useState(0)
    const [resultat,setResultat]=useState([])
    const [filters,setFilters]=useState([])

    const btnStyle = { marginTop: 10, width:'100%' }


    const columns = [
        // { title: "ID", field: "codeIdProd", filterPlaceholder: "filter" },
         { title: "INTITULE FAMILLE", field: "intituleFam",align: "center", filterPlaceholder: "filter" },
         //{ title: "QUANTITE RESTANTE", field: "qteRestant", align: "center" },
        // { title: "PRODUIT", field: "CodeIdProd"},
         {
          title: "PRODUIT", field: "nomProd"
        },
         { title: "PRIX PRODUIT", field: "prixProdVendu",filterPlaceholder:"filter"},
         { title: "NOM CLIENT", field: "nom",filterPlaceholder:"filter"},
         { title: "TELEPHONE CLIENT", field: "telephone",filterPlaceholder:"filter"},
         { title: "QUANTITE VENDUE", field: "qtVdu",filterPlaceholder:"filter"},
        // { title: "CODE BARRE", field: "codeBarre",filterPlaceholder:"filter" },
        /*  {
          title: "ETAT", field: "etat",
          render: (rowData) => <div style={{ background: rowData.etat === "VENDU" ? "#008000aa" : "#f90000aa",borderRadius:"4px",paddingLeft:5 }}>{rowData.etat}</div>,
           searchable: false, export: false
        },  */    
        { title: "NUMERO FACTURE ", field: "numFactSrt",filterPlaceholder:"filter" },
         { title: "ENREGISTRE", field: "dateInsert", type: "date"},
         { title: "FORME", field: "FormFarmaceutik"},
         { title: "CREATEUR", field: "speudo",filterPlaceholder:"filter"},
         // cellStyle: { background:"#009688" }, headerStyle: { color: "#fff" } },
       ]

    useEffect(() => {
        const fetchData = async () => {

            await ProduitVenduService.venteJournee()
            .then((response) => {
               setJournee(response.data)
            })
            .catch(e => {
              console.log(e);
           });

           await ProduitVenduService.venteHier()
            .then((response) => {
               setHier(response.data)
            })
            .catch(e => {
              console.log(e);
           });

           await ProduitVenduService.venteMonth()
            .then((response) => {
               setMois(response.data)
            })
            .catch(e => {
              console.log(e);
           });

           await ProduitVenduService.venteYear()
            .then((response) => {
               setAnne(response.data)
            })
            .catch(e => {
              console.log(e);
           });

        
            await CliniqueService.getAllUsers()
                .then((response) => {
                    var data = response.data
                    setFilters(data)
                })
                .catch((error) => {
                    console.log(error)
                })
            
        

        }

        fetchData();

        }, [])

        const initialValues = {
            idClient: '',
            nom:''

            
        }
        const validationSchema = Yup.object().shape({
            idClient: Yup.number().required("Required"),
            nom:Yup.string()

        })

        const onSubmit = (values, props) => {
          
            //props.resetForm()
        }
        const handleFilters = async (nom)=>{
          const value = nom;
      
          await ProduitVenduService.venteGerantJour(nom)
          .then((response) => {
             setJournee(response.data)
          })
          .catch(e => {
            console.log(e);
         });

         await ProduitVenduService.venteGerantHier(nom)
          .then((response) => {
             setHier(response.data)
          })
          .catch(e => {
            console.log(e);
         });

         await ProduitVenduService.venteGerantMonth(nom)
          .then((response) => {
             setMois(response.data)
          })
          .catch(e => {
            console.log(e);
         });

         await ProduitVenduService.venteGerantYear(nom)
          .then((response) => {
             setAnne(response.data)
          })
          .catch(e => {
            console.log(e);
         });

  
          
      }

    return (
        <div className="content">
      <div style={{marginTop:'50px',left:0}}>
      <Grid item xs={12} style={{marginTop:'-50px',marginLeft:'120px'}}>
                    <Container maxWidth="md">
           <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {(props) => (
                        <Form noValidate>
                          <Grid item xs={4}>
                                <Autocomplete
                                id="idClient"
                                name="idClient"
                                options={filters}
                                getOptionSelected={(option, value) => option.nom === value.nom}
                                getOptionLabel={option => option.nom}
                                  onChange={(e, v, r) => {
                                    var id = JSON.stringify(v?.idClient)
                                    props.setFieldValue("idClient",v?.idClient)
                                    handleFilters(v?.speudo)

                                  }}
                                renderInput={params => (
                                    <Field as={TextField} {...params} name="nom" label="Nom Client" variant="outlined" fullWidth
                                    error={props.errors.nom && props.touched.nom}
                                    helperText={<ErrorMessage name='nom' />}  required
                                    />
                                )}
                        />
                        
                        </Grid>
                        
                        </Form>
                    )}
                </Formik>
                </Container>
                </Grid>
                </div>
        <div className="container">
            <RecolteCat title="Aujourd'hui" data={journee} />
            <RecolteCat title="Hier" data={hier} />
            <RecolteCat title="Mois en cours" data={mois} />
            <RecolteCat title="Année en cours" data={annee} />
        </div>
      {/*   <div className="Wrapper">
                Rechercher
                <div className="searchContainer">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {(props) => (
                        <Form noValidate>
                     <Grid container spacing={2}>
                            <Grid item xs={6}>
                                    <DateTimePicker
                                    name="dateDebut"
                                    label="date debut"
                                    />
                            </Grid>
                            <Grid item xs={6}>
                                    <DateTimePicker
                                    name="dateFin"
                                    label="date Fin"
                                    />
                            </Grid>
                            <Grid item xs={12} >
                        <Button type='submit' style={btnStyle}  variant='contained' color='primary'>Rechercher</Button>
                            </Grid>
                     </Grid>
                        </Form>
                    )}
                </Formik>
                </div>
                <div className="tableData">
                    {
                        resultat.length === 0 ? <div>
                                        pas de données
                                    </div>
                        :
                        <div>
            <div className="infoContainer">
                <div style={{color:'darkblue',fontSize:20,padding:10}}>
                  Total  {resultat.length} ventes 
                </div>
                <div style={{color:'crimson',fontSize:20,padding:10}} >
                Montant {resultat.reduce((a,v) =>  a = a + v.total , 0 )} Fcfa
                </div>
            </div>
    <MaterialTable columns={columns} data={resultat}  
        options={{
          selection: true
        }}
        onSelectionChange={(selectedRows) => console.log(selectedRows)}
        options={{
          sorting: true, search: true,
          searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
          filtering: true, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
          paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
          exportAllData: true, exportFileName: "TableData", addRowPosition: "first", actionsColumnIndex: -1, selection: true,
          showSelectAllCheckbox: false, showTextRowsSelected: false, selectionProps: rowData => ({
            //disabled: rowData.age == null,  
            color:"primary"
          }),
           columnsButton: true,
          rowStyle: (data, index) => index % 2 === 0 ? { background: "#f5f5f5" } : null,
          headerStyle: { background: "#6200EE",color:"#fff"},
          
        }}
        title="Liste des produits vendus "
        icons={{ Add: () => <AddIcon /> }} />
       
                       </div>
                    }
                </div>
            </div> */}
    </div>

    )
}

export default SearchVentes
