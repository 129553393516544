import http from "./AxiosInstance";

class DefectService {
  // bon
  createDefect (data){
    console.log("data === ",data)
    return http.post("/defects/create",data);
  }


  updateDefect(data,id) {
    return http.put(`/defects/update/${id}`, data);
  }


  deleteDefect(id) {
    return http.delete(`/defects/${id}`);
  }

  getDefects() {
    return http.get("/defects/all");
  }

 
}

export default new DefectService ();