import http from "./AxiosInstance";

class BonClientService {
  
// Famille

getBonClient() {
  return http.get("/bonclients");
}

getBonClientPending() {
  return http.get(`/bonclients/pending`);
}

/* 
getBonClientPending(nom) {
  return http.get(`/bonclients/pending/${encodeURIComponent(nom)}`);
} */


getBonClientVendu() {
  return http.get("/bonclients/vendu");
}

getBonByClient(idClient) {
  return http.get(`/bonclients/client/${idClient}`);
}

getBonClientByStatut(id,statut) {
  return http.get(`/bonclients/statut/${id}/${encodeURIComponent(statut)}`,id,statut);
}

vendreBons(data) {
  return http.put("/bonclients/vendre",data);
}

annulerBon(id) {
  return http.delete(`/bonclients/annuler/${id}`);
}


getTypeBonClient(idType) {
  return http.get(`/bonclients/${idType}`);
}

createBonClient(data){
  return http.post("/bonclients/save",data)
}

updateBonClient(data,id) {
  return http.put(`/bonclients/update/${id}`, data);
}

deleteBonClient(id) {
  return http.delete(`/bonclients/delete/${id}`);
}

deleteBon(id) {
  return http.delete(`/bonclients/deletebon/${id}`);
}


savePayement(data){
  console.log("data payement === ",data)
  return http.post("/payement/save",data)
}

getTypePayement() {
  return http.get(`/payement/typepayement`);
}

payerTout(data) {
  return http.put(`/payement/payertout`,data);
}

getStatistiques(statut){
  return http.get(`/bonclients/etat/${statut}`);
}
  
}

export default new BonClientService ();