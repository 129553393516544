import React, { useState } from 'react';
import SalesChart from '../SalesChart';
import { FormControl, InputLabel, Select, MenuItem, Modal, Backdrop, Fade } from '@material-ui/core';
import './styles.css'; // Importez votre fichier CSS

const ChartPage = () => {
  const [period, setPeriod] = useState('15-jours');
  const [chartType, setChartType] = useState('Bar');
  const [open, setOpen] = useState(false);

  const handlePeriodChange = (event) => {
    setPeriod(event.target.value);
  };

  const handleChartTypeChange = (event) => {
    setChartType(event.target.value);
  };

  const handleChartClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="chart-page-container">
      <h1>Tableau de Bord</h1>
      <div className="controls-container">
        <FormControl variant="outlined" style={{ marginRight: '20px', minWidth: '200px' }}>
          <InputLabel>Période</InputLabel>
          <Select
            value={period}
            onChange={handlePeriodChange}
            label="Période"
          >
            <MenuItem value="15-jours">15-jours</MenuItem>
            <MenuItem value="mois">Mensuelle</MenuItem>
            <MenuItem value="annee">Annuelle</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" style={{ minWidth: '200px' }}>
          <InputLabel>Type de graphique</InputLabel>
          <Select
            value={chartType}
            onChange={handleChartTypeChange}
            label="Type de graphique"
          >
            <MenuItem value="Bar">Bar</MenuItem>
            <MenuItem value="Line">Line</MenuItem>
            <MenuItem value="Pie">Pie</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="chart-container" onClick={handleChartClick}>
        <SalesChart period={period} chartType={chartType} />
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="modal-content">
            <SalesChart period={period} chartType={chartType} />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ChartPage;
