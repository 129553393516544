import { Button, Container, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core'
import MaterialTable from 'material-table'
import React, { useEffect, useState } from 'react'
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from "@material-ui/core/styles";
import DefectService from '../../services/DefectService'
import { toast } from "react-toastify";
import { Edit, ThreeDRotationTwoTone } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import Skeleton from '../../components/skeleton/Skeleton';
import AddRetourCommande from './AddRetourCommande';
import CustomizedDialogs from '../../components/dialog';
import RetourCommandeService from '../../services/RetourCommandeService';



const ListRetour = () => {
    const useStyles = makeStyles({
        root: {
          maxWidth: 345,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent:'center',
        }
      });
  const classes = useStyles();
  const [coms,setCommandes] = useState([])
  const [fournisseur, setFournisseurs] = useState([])
  const [nums, setNums] = useState([])
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth);

  
  const [state,setStates] = useState({
    open: false,
    editProduct : []
  })

  const [updateProd,setUpdateProd] = useState({
    nomProd: "",
    prixPublic : "",
    dateExpiration: "",
    qtEntrer: "",
    intituleFam:"",
    FormFarmaceutik:"",
    speudo:""
  })

    
  const handleFilters = async (e)=>{
   /*  const value = e.target.value;
    await BonService.getBonByFournisseurs(value)
        .then((response) => {
            var data = response.data
            setCommandes(data)
            //alert(JSON.stringify(data));
        })
        .catch((error) => {
            console.log(error)
        }) */
}



const handleChangeUpdate = (e) => {
  setUpdateProd({...updateProd, [e.target.name]: e.target.value });
}; 

const handleClose = () => {
  setStates({ open: false});
};

const handleUpdate = async (e) => {
   // alert(JSON.stringify(updateProd))
    //alert(idProd)
   /* await  ProduitService.updateProduit(updateProd,state.editProduct[0]?.codeIdProd)
          .then((response)=>{
            toast.success("Votre commande modifiee avec success...", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            refreshProduits()
          })
          .catch((error)=>{

          }) */
}; 



  useEffect(() => {
    const fetchData = async () => {

      refreshDefects()
        }

        
     fetchData();
  }, [])
   

      const refreshDefects = async ()=>{
        setLoading(true);
        await RetourCommandeService.getRetours()
           .then((response) => {
            setCommandes(response.data)
            console.log("object"+JSON.stringify(response.data));
            setLoading(false);
            })
            .catch(e => {
              setLoading(false);
              console.log(e);
            });
      }

      const handleOpen = (row) => {
        //alert(JSON.stringify(row))
        setStates({ open: true, editProduct: row });
        // setUpdateProd(row)
        setUpdateProd({...updateProd,
          nomProd: row[0]?.nomProd,
          prixPublic: row[0]?.prixPublic,
          dateExpiration: row[0]?.dateExpiration,
          qtEntrer: row[0]?.qtEntrer,
          FormFarmaceutik:row[0]?.FormFarmaceutik,
          intituleFam:row[0]?.intituleFam,
          speudo:row[0]?.speudo
    
        })
      };
    

  const columns = [
    { title: "Numéro", field: "numero", filterPlaceholder: "filter" },
    { title: "QUANTITE RETOURNEE", field: "quan_retour", filterPlaceholder: "filter" },
    { title: "NOM PRODUIT", field: "produit", filterPlaceholder: "filter",editable: 'never' },
    { title: "Fournisseur", field: "fournisseur", filterPlaceholder: "filter",editable: 'never' },
    { title: "ENREGISTRE", field: "created_at", filterPlaceholder: "filter",editable: 'never',type: "date" },
    {
      title: "ETAT", field: "etat_produit",
      render: (rowData) => <div style={{ background: rowData.etat_produit === "AVARIE" ? "crimson" : rowData.etat_produit === "EXPIRER" ? "#f90000aa" : "#008000aa",borderRadius:"4px",paddingLeft:5 ,color:'white'}}>{rowData.etat_produit}</div>,
       searchable: false, export: false
    },
    { title: "COMMENTAIRE", field: "motif", filterPlaceholder: "filter" },

    //{ title: "Enregistre", field: "dateEng", type: "currency", currencySetting: { currencyCode: "INR", minimumFractionDigits: 1 },
     // cellStyle: { background:"#009688" }, headerStyle: { color: "#fff" } },
   ]

    return (
      <>
        <div className="App" style={{width:"100%", display:'flow',justifyContent:'center',alignItems:'center',top:0,bottom:0,margin:'auto'}}>
            <Typography variant="title" className={classes.title}>
            Liste des retours de commandes
            </Typography>
            <div style={{display:"flex",justifyContent:'flex-start',margin:15}}>
        <div style={{display:'flex',justifyContent:'space-between',width:'100%'}}>
           <div>

           <div>
         <CustomizedDialogs title="Nouveau retour" boutonTitle="Ajouter un retour" refresh={()=>refreshDefects()}>
                   <AddRetourCommande />
       </CustomizedDialogs>
      </div>
           
           </div>
      </div>
        </div>
           {
        loading ? <Skeleton type="circle" /> : <MaterialTable columns={columns} data={coms}
      onRowClick={(evt,rowData)=>{
        //setSelectedRow(rowData)
        //setShowDialog(true)
       /*  history.push(
          {
            pathname: '/report',
            state: { data: rowData }
          }
        ) */
      }}
        editable={{
          onRowAdd: (newRow) => new Promise((resolve, reject) => {
              DefectService.createDefect(newRow)
                  .then((response) => {
                    toast.success("Produit ajoute avec success...", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    refreshDefects()
                  })
                  .catch((error) => {
                    console.log(error);
                    toast.error(error.response?.data, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  });
            refreshDefects()
            setTimeout(() => resolve(), 500)
          }),
          onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {

                if(user?.rol === 'gerant') {
                  toast.error("Vous n'avez pas ce droit!!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                  setTimeout(() => resolve(), 500)
                  return;
                }
              
                DefectService.updateDefect(newRow,oldRow.idAvarie)
                  .then((response) => {
                    console.log(JSON.stringify(response));
                    if(response?.data?.message === "Vous n'avez pas ce droit"){
                      toast.error(response?.data?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      });
                    }
                    else {
                      toast.success(response?.data?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      });
                    }
                    
                    refreshDefects()
                  })
                  .catch((error) => {
                    console.log(error);
                    toast.error(error.response?.data, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  });
            refreshDefects()
            setTimeout(() => resolve(), 500)
          }),
          onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
            if(user?.rol === 'gerant') {
              toast.error("Vous n'avez pas ce droit!!", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              setTimeout(() => resolve(), 500)
              return;
            }
            RetourCommandeService.deleteRetour(selectedRow.idRetour)
                  .then((response) => {
                    toast.success("produit supprimé avec success...", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    refreshDefects()
                  })
                  .catch((error) => {
                    console.log(error);
                    toast.error(error.response?.data, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  });
            setTimeout(() => resolve(), 500)
          })
        }}
           
        actions={[
          {
            icon: () => <Edit color="error" />,
            tooltip: "modifier un produit",
            onClick: (event, rowData) => {
              //alert(rowData)
              handleOpen(rowData)
              //setOpen(true)
            },
          },
        ]}
        options={{
          selection: true
        }}
        onSelectionChange={(selectedRows) => console.log(selectedRows)}
        options={{
          sorting: true, search: true,
          searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
          filtering: true, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
          paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
          exportAllData: true, exportFileName: "Clients", addRowPosition: "first", actionsColumnIndex: -1, selection: true,
          showSelectAllCheckbox: false, showTextRowsSelected: false, selectionProps: () => ({
            //disabled: rowData.age == null,  
            color:"primary"
          }),
           columnsButton: true,
          rowStyle: (_data, index) => index % 2 === 0 ? { background: "#f5f5f5" } : null,
          headerStyle: { background: "#226D68",color:"#fff"},
          
        }}
        title="Liste des produits avariés "
        icons={{ Add: () => <AddIcon /> }} />}


          </div>

{ state.open && <Dialog
  open={state.open}
  aria-labelledby="draggable-dialog-title"
  onClose={handleClose}
>   
  <DialogTitle id="draggable-dialog-title">Modifier le produit</DialogTitle>
  {state.editProduct && <DialogContent>
     <Container>
     <Grid item xs={12}>
     <Grid container spacing={1}>
     <Grid item xs={12} >
     <TextField id="outlined-basic" 
     label="Nom produit" 
     variant="outlined" 
     name="nomProd"  
     onChange={handleChangeUpdate}
     defaultValue={state.editProduct[0]?.nomProd} 
     fullWidth 
     />
      </Grid>
      <Grid item xs={12} >

     <Grid item xs={12}>
                <TextField
                    id="date"
                    label="Birthday"
                    type="date"
                    name="dateExpiration"
                    onChange={handleChangeUpdate}
                    defaultValue={state.editProduct[0]?.dateExpiration}                     
                       InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
      </Grid>
      </Grid>
      <Grid item xs={12} >
     <TextField id="outlined-basic" 
     label="Prix" 
     variant="outlined" 
     name="prixPublic" 
     onChange={handleChangeUpdate}
     defaultValue={state.editProduct[0]?.prixPublic} 
     fullWidth 
     />
      </Grid>
      <Grid item xs={12} >
     <TextField 
     id="outlined-basic" 
     label="Quantite" 
     name="qtEntrer"  
     variant="outlined" 
     onChange={handleChangeUpdate}
     defaultValue={state.editProduct[0]?.qtEntrer} 
     fullWidth />
      </Grid>
      <Grid item xs={12} >
                        <Button type='submit'  variant='contained' onClick={handleUpdate}
                            color='primary'>Valider</Button>
                            </Grid>
    </Grid>
    </Grid>
      </Container>
  </DialogContent>}
  </Dialog>}
  </>
    )
}

export default ListRetour
