
import React,{useState,useEffect} from 'react'
import Grid from '@material-ui/core/Grid';

import { makeStyles } from "@material-ui/core/styles";
import { Typography, TextField, Button, Card, Box } from "@material-ui/core";
import Image from '../../../src/images/pha2.jpg'; // Import using relative path
import Person from '@material-ui/icons/Person';

import { useDispatch, useSelector } from "react-redux";
import { signIn } from '../../redux/actions/authActions';
import { useNavigate } from 'react-router';
/* import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
 */

const useStyles = makeStyles({
    formStyle: {
      margin: "0px auto",
      padding: "30px",
      borderRadius: "9px",
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems: 'center',
     // boxShadow: "0px 0px 12px -3px #000000",
      marginTop:100,
      marginLeft:20
    },
    spacing: {
      marginTop: "20px",
      width:'70%'
    },

    container: {
      backgroundImage: `url(${Image})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Modifier la valeur d'opacité ici
    },
    
    
  });

  const styles = {
    paperContainer: {
      height: '80vh',
      marginTop:80,
      backgroundImage: `url(${Image})`,
      backgroundRepeat:'no-repeat',
      backgroundSize: 'contain',
      width:'90%'

  }
};

const Login=()=> {
    const classes = useStyles();
    const dispatch = useDispatch();
    const auth= useSelector((state) => state.auth);

    const navigate = useNavigate();

    useEffect(() => {


    }, [])

    const [creds, setCreds] = useState({
        speudo: "",
        passwd: "",
      });

      const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(signIn(creds.speudo, creds.passwd));
        navigate("/dashboard")
        setCreds({ speudo: "", passwd: "" });
      };
    
      if (localStorage.getItem("token")) { navigate("/dashboard");}


    return (
        <div>
{/* <Grid container spacing={2}>
  <Grid item xs={6} style={{display:'flex',justifyContent:'center',alignItems:'center'}} >
  <Card style={{marginTop:'80px',width:'80%',height:'60vh'}} >
      <form
        noValidate
        autoComplete="off"
        className={classes.formStyle}
        onSubmit={handleSubmit}
      >
      <Typography variant="h5" color="primary" style={{textAlign:"center",marginTop:'-20px'}} >CONNECTEZ-VOUS</Typography>
        <div style={{justifyContent:'center',display:'flex',alignItems:'center'}}><Person color="primary" style={{width:80,height:80}} /> </div>
        <TextField
          className={classes.spacing}
          id="enter-email"
          label="username"
          variant="outlined"
          fullWidth
          value={creds.speudo}
          onChange={(e) => setCreds({ ...creds, speudo: e.target.value })}
        />
        <TextField
          className={classes.spacing}
          id="enter-password"
          type="password"
          label="password"
          variant="outlined"
          fullWidth
          value={creds.passwd}
          onChange={(e) => setCreds({ ...creds, passwd: e.target.value })}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.spacing}
          type="submit"
        >
          Valider
        </Button>
      </form>
    </Card>
  </Grid>
  <Grid item xs={6}>
  <div style={styles.paperContainer}>
  </div>
  </Grid>
</Grid> */}
<Box className={classes.container}>
      <Typography variant="h4" className={classes.loginText}>
         
<Card style={{width:'600px',height:'60vh'}} >
<form
  noValidate
  autoComplete="off"
  className={classes.formStyle}
  onSubmit={handleSubmit}
>
{/*         <div style={{fontSize:'34px',marginTop:'-30px'}}><LocalHospitalIcon color="primary" style={{fontSize:'34px',marginTop:'-10px'}} /></div>
*/}        <Typography variant="h5" color="primary" style={{textAlign:"center",marginTop:'-50px'}} >CONNECTEZ-VOUS</Typography>
  <div style={{justifyContent:'center',display:'flex',alignItems:'center'}}><Person color="primary" style={{width:80,height:80}} /> </div>
  <TextField
    className={classes.spacing}
    id="enter-email"
    label="username"
    variant="outlined"
    fullWidth
    value={creds.speudo}
    onChange={(e) => setCreds({ ...creds, speudo: e.target.value })}
  />
  <TextField
    className={classes.spacing}
    id="enter-password"
    type="password"
    label="password"
    variant="outlined"
    fullWidth
    value={creds.passwd}
    onChange={(e) => setCreds({ ...creds, passwd: e.target.value })}
  />
  <Button
    variant="contained"
    color="primary"
    className={classes.spacing}
    type="submit"
  >
    Valider
  </Button>
</form>
</Card>
      </Typography>
    </Box>
        </div>
    )
}

export default Login

