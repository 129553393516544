

import { Button, Container, Grid,TextField } from '@material-ui/core'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CliniqueService from '../services/CliniqueService'
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from 'yup'
import { toast } from "react-toastify";


const FamilleForm = () => {


  const auth= useSelector((state) => state.auth);
  const produits = useSelector((state) => state.produits);
  const dispatch = useDispatch();

  console.log("produits ===== ",produits)

  


  const btnStyle = { marginTop: 10, width:'100%' }
    const [prod,setProd] = useState([])


  useEffect(() => {
    

    const fetchData = async () => {
    

   }
   console.log("prod ==== ",prod)

   fetchData();
  }, [dispatch]);
    
  const initialValues = {
    intituleFam: '', 
}
const validationSchema = Yup.object().shape({
  intituleFam: Yup.string().required("Required"),
  })
const onSubmit = (values, props) => {

    var forme = {
      "intituleFam":values.intituleFam.trim(),
      "speudo":auth.nom,
    }
    //alert(JSON.stringify(forme), null, 2)
    CliniqueService.createFamille(forme)
       .then((response) => {
         toast.success("Famille pharmaceutique ajoute avec success...", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
       })
       .catch((error) => {
         console.log(error);
         toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
    //props.resetForm()
}




    return (
        <div>
             <Grid item xs={12}>
        <Container maxWidth="md">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {(props) => (
                        <Form noValidate>
                     <Grid container spacing={2}>
                    <Grid item xs={12} >
                            <Field as={TextField} name='intituleFam' label='Nom famille' fullWidth variant="outlined"
                                error={props.errors.intituleFam && props.touched.intituleFam}
                                helperText={<ErrorMessage name='intituleFam' />} required />
                    </Grid>                           
                               <Grid item xs={12} >
                            <Button type='submit' style={btnStyle} variant='contained'
                                color='primary'>Valider</Button>
                                </Grid>
                                                </Grid>
                        </Form>
                    )}
                </Formik>
                </Container>
                </Grid>
        </div>
    )
}

export default FamilleForm
