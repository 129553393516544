import { Button, Container, Dialog, DialogContent, DialogTitle, Grid, MenuItem, TextField, Typography } from '@material-ui/core'
import MaterialTable from 'material-table'
import React, { useEffect, useState } from 'react'
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from "@material-ui/core/styles";
import ClientService from '../../services/ClientService'
import { toast } from "react-toastify";
import FournisseurService from '../../services/FournisseurService';
import BonService from '../../services/BonService';
import { Edit } from '@material-ui/icons';
import NumCommandeService from '../../services/NumCommandeService';
import Skeleton from '../../components/skeleton/Skeleton';
import { Autocomplete } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import MyCardFournisseurs from '../../components/MyCardFournisseurs';



const ListCommandes = () => {
    const useStyles = makeStyles({
        root: {
          maxWidth: 345,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent:'center',
        }
      });
  const classes = useStyles();
  const [coms,setCommandes] = useState([])
  const [fournisseur, setFournisseurs] = useState([])
  const [nums, setNums] = useState([])
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth);

  
  const [state,setStates] = useState({
    open: false,
    editProduct : []
  })

  const [updateProd,setUpdateProd] = useState({
    nomProd: "",
    prixPublic : "",
    dateExpiration: "",
    qtEntrer: "",
    intituleFam:"",
    FormFarmaceutik:"",
    speudo:""
  })

    
  const handleFilters = async (e)=>{
    const value = e.target.value;
    await BonService.getBonByFournisseurs(value)
        .then((response) => {
            var data = response.data
            setCommandes(data)
            //alert(JSON.stringify(data));
        })
        .catch((error) => {
            console.log(error)
        })
}

const handleNumber = async (e,v,r)=>{
  //const value = e.target.value;

  await BonService.getBonByNumbers(v?.idNumero)
      .then((response) => {
          var data = response.data
          setCommandes(data)
          //alert(JSON.stringify(data));
      })
      .catch((error) => {
          console.log(error)
      })
}

const handleChangeUpdate = (e) => {
  setUpdateProd({...updateProd, [e.target.name]: e.target.value });
}; 

const handleClose = () => {
  setStates({ open: false});
};

const handleUpdate = async (e) => {
   // alert(JSON.stringify(updateProd))
    //alert(idProd)
   /* await  ProduitService.updateProduit(updateProd,state.editProduct[0]?.codeIdProd)
          .then((response)=>{
            toast.success("Votre commande modifiee avec success...", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            refreshProduits()
          })
          .catch((error)=>{

          }) */
}; 

const fetchNums = async () => {
  await NumCommandeService.getNumbers().then((response) => {
      setNums(response.data)
  })
  .catch(e => {
    console.log("error numeros" + e)
  })
}

  useEffect(() => {
    const fetchData = async () => {

      setLoading(true);
       await BonService.getBons()
          .then((response) => {
            setCommandes(response.data)
            setLoading(false);

          })
          .catch(e => {
            console.log(e);
            setLoading(false);
         });

         await FournisseurService.getFournisseurs()
         .then((response) => {
             var data = response.data
             console.log("fournisseur"+data)
             console.log(data)

             setFournisseurs(data)
         })
         .catch((error) => {
             console.log(error)
         })

         fetchNums()


        }

        
     fetchData();
  }, [])
   

      const refreshCommandes = async ()=>{
        await BonService.getBons()
           .then((response) => {
            setCommandes(response.data)
            })
            .catch(e => {
              console.log(e);
            });
      }

      const handleOpen = (row) => {
        //alert(JSON.stringify(row))
        setStates({ open: true, editProduct: row });
        // setUpdateProd(row)
        setUpdateProd({...updateProd,
          nomProd: row[0]?.nomProd,
          prixPublic: row[0]?.prixPublic,
          dateExpiration: row[0]?.dateExpiration,
          qtEntrer: row[0]?.qtEntrer,
          FormFarmaceutik:row[0]?.FormFarmaceutik,
          intituleFam:row[0]?.intituleFam,
          speudo:row[0]?.speudo
    
        })
      };
    

  const columns = [
    { title: "ID", field: "idBonDeComdeMat", filterPlaceholder: "filter" },
    { title: "REFERENCE", field: "numero", filterPlaceholder: "filter",editable: 'never' },
    { title: "NOM PRODUIT", field: "nomProd", filterPlaceholder: "filter",editable: 'never' },
    { title: "FOURNISSEUR", field: "nomFournisseur", filterPlaceholder: "filter",editable: 'never' },
    { title: "PRIX VENTE", field: "prixPublic", filterPlaceholder: "filter" },
    { title: "PRIX UNITAIRE", field: "prixUnitaire", filterPlaceholder: "filter" },
    { title: "EXPIRATION", field: `date_expiration`, filterPlaceholder: "filter",type: "date" },
    { title: "DATE COMMANDE", field: "dateComde", filterPlaceholder: "filter",type: "date"},
    { title: "QUANTITE COMMANDEE", field: `qteComder`, filterPlaceholder: "filter" },
    { title: "QUANTITE LIVREE", field: `quan_livree`, filterPlaceholder: "filter" },
    { title: "CREATEUR", field: "speudo", filterPlaceholder: "filter",editable: 'never' },
    //{ title: "Enregistre", field: "dateEng", type: "currency", currencySetting: { currencyCode: "INR", minimumFractionDigits: 1 },
     // cellStyle: { background:"#009688" }, headerStyle: { color: "#fff" } },
   ]

    return (
      <>
        <div className="App" style={{width:"100%", display:'flow',justifyContent:'center',alignItems:'center',top:0,bottom:0,margin:'auto'}}>
            <Typography variant="title" className={classes.title}>
            Liste des commandes
            </Typography>
            <div style={{display:"flex",justifyContent:'flex-start',margin:15}}>
        <div style={{display:'flex',justifyContent:'space-between',width:'100%'}}>
           <div>

           <Autocomplete
         id="idNumero"
         name="idNumero"
         options={nums}
         style={{ width: 300 }}
         getOptionSelected={(option, value) => option.numero === value.numero}
         getOptionLabel={option => option.numero}
           onChange={handleNumber}
        renderInput={(params) => <TextField {...params} label="Numero de commande" variant="outlined" />}
      />

           
           </div>
        <div style={{marginRight:'20px'}} >
        <select style={{padding:'10px', marginLeft:'50px'}} name ="color" onChange={handleFilters}>
                 <option>
                        Fournisseurs
                    </option>
                    {
                      fournisseur.map((f)=>(
                        <option value={f.idFournisseur}>{f.nomFournisseur}</option>

                      ))
                    }
        </select>
        </div>
      </div>
        </div>
        <div style={{display:'flex',flexDirection:'row'}}>
        {
                      fournisseur.map((f)=>(
                        <MyCardFournisseurs value={f} />

                      ))
                    }
        </div>
           {
        loading ? <Skeleton type="circle" /> : <MaterialTable columns={columns} data={coms}
      onRowClick={(evt,rowData)=>{
        //setSelectedRow(rowData)
        //setShowDialog(true)
       /*  history.push(
          {
            pathname: '/report',
            state: { data: rowData }
          }
        ) */
      }}
        editable={{
          onRowAdd: (newRow) => new Promise((resolve, reject) => {
              ClientService.createClient(newRow)
                  .then((response) => {
                    toast.success("Client ajoute avec success...", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    refreshCommandes()
                  })
                  .catch((error) => {
                    console.log(error);
                    toast.error(error.response?.data, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  });
            refreshCommandes()
            setTimeout(() => resolve(), 500)
          }),
          onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {

                if(user?.rol === 'gerant') {
                  toast.error("Vous n'avez pas ce droit!!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                  setTimeout(() => resolve(), 500)
                  return;
                }
              
                BonService.updateBon(newRow,oldRow.idBonDeComdeMat)
                  .then((response) => {
                    console.log(JSON.stringify(response));
                    if(response?.data?.message === 'Ce type de client ne peut etre modifié'){
                      toast.error(response?.data?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      });
                    }
                    else {
                      toast.success(response?.data?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      });
                    }
                    
                    refreshCommandes()
                  })
                  .catch((error) => {
                    console.log(error);
                    toast.error(error.response?.data, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  });
            refreshCommandes()
            setTimeout(() => resolve(), 500)
          }),
          onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
            if(user?.rol === 'gerant') {
              toast.error("Vous n'avez pas ce droit!!", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              setTimeout(() => resolve(), 500)
              return;
            }
            BonService.deleteBon(selectedRow.idBonDeComdeMat)
                  .then((response) => {
                    toast.success("Commande supprimée avec success...", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    refreshCommandes()
                  })
                  .catch((error) => {
                    console.log(error);
                    toast.error(error.response?.data, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  });
            setTimeout(() => resolve(), 500)
          })
        }}
           
        actions={[
          {
            icon: () => <Edit color="error" />,
            tooltip: "modifier un produit",
            onClick: (event, rowData) => {
              //alert(rowData)
              handleOpen(rowData)
              //setOpen(true)
            },
          },
        ]}
        options={{
          selection: true
        }}
        onSelectionChange={(selectedRows) => console.log(selectedRows)}
        options={{
          sorting: true, search: true,
          searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
          filtering: true, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
          paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
          exportAllData: true, exportFileName: "Liste des commandes", addRowPosition: "first", actionsColumnIndex: -1, selection: true,
          showSelectAllCheckbox: false, showTextRowsSelected: false, selectionProps: () => ({
            //disabled: rowData.age == null,  
            color:"primary"
          }),
           columnsButton: true,
          rowStyle: (_data, index) => index % 2 === 0 ? { background: "#f5f5f5" } : null,
          headerStyle: { background: "#226D68",color:"#fff"},
          
        }}
        title="Liste des commandes "
        icons={{ Add: () => <AddIcon /> }} />}


          </div>

{ state.open && <Dialog
  open={state.open}
  aria-labelledby="draggable-dialog-title"
  onClose={handleClose}
>   
  <DialogTitle id="draggable-dialog-title">Modifier le produit</DialogTitle>
  {state.editProduct && <DialogContent>
     <Container>
     <Grid item xs={12}>
     <Grid container spacing={1}>
     <Grid item xs={12} >
     <TextField id="outlined-basic" 
     label="Nom produit" 
     variant="outlined" 
     name="nomProd"  
     onChange={handleChangeUpdate}
     defaultValue={state.editProduct[0]?.nomProd} 
     fullWidth 
     />
      </Grid>
      <Grid item xs={12} >

     <Grid item xs={12}>
                <TextField
                    id="date"
                    label="Birthday"
                    type="date"
                    name="dateExpiration"
                    onChange={handleChangeUpdate}
                    defaultValue={state.editProduct[0]?.dateExpiration}                     
                       InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
      </Grid>
      </Grid>
      <Grid item xs={12} >
     <TextField id="outlined-basic" 
     label="Prix" 
     variant="outlined" 
     name="prixPublic" 
     onChange={handleChangeUpdate}
     defaultValue={state.editProduct[0]?.prixPublic} 
     fullWidth 
     />
      </Grid>
      <Grid item xs={12} >
     <TextField 
     id="outlined-basic" 
     label="Quantite" 
     name="qtEntrer"  
     variant="outlined" 
     onChange={handleChangeUpdate}
     defaultValue={state.editProduct[0]?.qtEntrer} 
     fullWidth />
      </Grid>
      <Grid item xs={12} >
                        <Button type='submit'  variant='contained' onClick={handleUpdate}
                            style={{background:'#f26522',color:'white'}}>Valider</Button>
                            </Grid>
    </Grid>
    </Grid>
      </Container>
  </DialogContent>}
  </Dialog>}
  </>
    )
}

export default ListCommandes
