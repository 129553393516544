import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, Grid, Chip } from '@material-ui/core';
import FondService from '../services/FondService';
import CountUp from 'react-countup';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 400,
    margin: 'auto',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 300,
    },
  },
}));
const MyCard = ({depensesAnnee,depensesJour,depensesMois}) => {
  const classes = useStyles();




  useEffect(() => {
    
    const fetchData = async () => {
       //depenses


    }
  
    fetchData()
  }, [])
  
  


  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h6" component="h3">
              Dépenses du jour
            </Typography>
            <Typography color="textSecondary">
            <Chip
                label={<CountUp start={0} end={depensesJour} duration={2.5} separator=" " suffix='F CFA' />}
                style={{color:'crimson',fontSize:'16px'}} variant="outlined"    /> 
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card className={classes.card}>
          <CardContent>
          <Typography variant="h6" component="h3">
              Dépenses du mois
            </Typography>
            <Typography color="textSecondary">
            <Chip
                label={<CountUp start={0} end={depensesMois} duration={2.5} separator=" " suffix='F CFA' />}
                style={{color:'crimson',fontSize:'16px'}} variant="outlined"    /> 
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card className={classes.card}>
          <CardContent>
          <Typography variant="h6" component="h3">
              Dépenses de l'année
            </Typography>
            <Typography color="textSecondary">
            <Chip
                label={<CountUp start={0} end={depensesAnnee} duration={2.5} separator=" " suffix='F CFA' />}
                style={{color:'crimson',fontSize:'16px'}} variant="outlined"    /> 
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};


export default MyCard;
