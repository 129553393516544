

import React, { useState,useEffect } from 'react'
import ProduitVenduService from '../../services/ProduitVenduService'
import FondService from '../../services/FondService'
import RecolteCat from '../RecolteCat'
import { Button, Grid} from '@material-ui/core'

import './styles.css'
import DateTimePicker from '../FormsUI/DataTimePicker'
import { Formik, Form} from 'formik'
import * as Yup from 'yup'
import MaterialTable from 'material-table'
import AddIcon from '@material-ui/icons/Add';
import Skeleton from '../skeleton/Skeleton'


function Recolte() {
    const [journee,setJournee]=useState(0)
    const [mois,setMois]=useState(0)
    const [hier,setHier]=useState(0)
    const [annee,setAnne]=useState(0)

    const [fondJour,setFondJour]=useState(0)
    const [fondMois,setFondMois]=useState(0)
    const [fondhier,setFondHier]=useState(0)
    const [fondAnnee,setFondAn]=useState(0)

    const [depensesJour,setDepensesJour]=useState(0)
    const [depensesMois,setDepensesMois]=useState(0)
    const [depenseshier,setDepensesHier]=useState(0)
    const [depensesAnnee,setDepensesAnnee]=useState(0)

    const [resultat,setResultat]=useState([])

    const btnStyle = { marginTop: 10, width:'100%' }

    const [loading,setLoading]=useState(false)



    const columns = [
        // { title: "ID", field: "codeIdProd", filterPlaceholder: "filter" },
         { title: "INTITULE FAMILLE", field: "intituleFam",align: "center", filterPlaceholder: "filter" },
         //{ title: "QUANTITE RESTANTE", field: "qteRestant", align: "center" },
        // { title: "PRODUIT", field: "CodeIdProd"},
         {
          title: "PRODUIT", field: "nomProd"
        },
         { title: "PRIX PRODUIT", field: "prixProdVendu",filterPlaceholder:"filter"},
         { title: "NOM CLIENT", field: "nom",filterPlaceholder:"filter"},
         { title: "TELEPHONE CLIENT", field: "telephone",filterPlaceholder:"filter"},
         { title: "QUANTITE VENDUE", field: "qtVdu",filterPlaceholder:"filter"},
        // { title: "CODE BARRE", field: "codeBarre",filterPlaceholder:"filter" },
        /*  {
          title: "ETAT", field: "etat",
          render: (rowData) => <div style={{ background: rowData.etat === "VENDU" ? "#008000aa" : "#f90000aa",borderRadius:"4px",paddingLeft:5 }}>{rowData.etat}</div>,
           searchable: false, export: false
        },  */    
        { title: "NUMERO FACTURE ", field: "numFactSrt",filterPlaceholder:"filter" },
         { title: "ENREGISTRE", field: "dateInsert", type: "date"},
         { title: "FORME", field: "FormFarmaceutik"},
         { title: "CREATEUR", field: "speudo",filterPlaceholder:"filter"},
         // cellStyle: { background:"#009688" }, headerStyle: { color: "#fff" } },
       ]

    useEffect(() => {
        const fetchData = async () => {

            await ProduitVenduService.venteJournee()
            .then((response) => {
               setJournee(response.data)
               
            })
            .catch(e => {
              console.log(e);
           });

           await ProduitVenduService.venteHier()
            .then((response) => {
               setHier(response.data)
            })
            .catch(e => {
              console.log(e);
           });

           await ProduitVenduService.venteMonth()
            .then((response) => {
               setMois(response.data)
            })
            .catch(e => {
              console.log(e);
           });
           setLoading(true);
           await ProduitVenduService.venteYear()
            .then((response) => {
              setLoading(false);
               setAnne(response.data)
            })
            .catch(e => {
              console.log(e);
           });

           // fonds

           await FondService.fondJournee()
           .then((response) => {
            const data = response.data[0]?.val
            setFondJour(data)

           })
           .catch(e => {
             console.log(e);
          });

          await FondService.fondHier()
           .then((response) => {
             const data = response.data[0]?.val
              setFondHier(data)
             })
           .catch(e => {
             console.log(e);
          });

          await FondService.fondMonth()
           .then((response) => {
            const data = response.data[0]?.val
              setFondMois(data)
  
           })
           .catch(e => {
             console.log(e);
          });

          await FondService.fondYear()
           .then((response) => {
            const data = response.data[0]?.val
            setFondAn(data)

           })
           .catch(e => {
             console.log(e);
          });

           //depenses


           await FondService.depensesJournee()
           .then((response) => {
            const data = response.data[0]?.val
              setDepensesJour(data)
  
           })
           .catch(e => {
             console.log(e);
          });

          await FondService.depensesHier()
           .then((response) => {
            const data = response.data[0]?.val
              setDepensesHier(data)
  
           })
           .catch(e => {
             console.log(e);
          });

          await FondService.depensesMonth()
           .then((response) => {
            const data = response.data[0]?.val
            setDepensesMois(data)

           })
           .catch(e => {
             console.log(e);
          });

          await FondService.depensesYear()
           .then((response) => {
            const data = response.data[0]?.val
            setDepensesAnnee(data)
           })
           .catch(e => {
             console.log(e);
          });

        }

        fetchData();

        }, [])

        const initialValues = {
            dateDebut: '',
            dateFin: '',
            
        }
        const validationSchema = Yup.object().shape({
            dateDebut: Yup.date().required("Required"),
            dateFin: Yup.date(),
        })

        const onSubmit = (values, props) => {
            var produit = {
                "date1":values.dateDebut,
                "date2":values.dateFin,  
            }
            //alert(JSON.stringify(produit), null, 2)
            ProduitVenduService.searchVente(produit)
            .then((response) => {
                 setResultat(response.data)
              })
              .catch(e => {
                console.log(e);
             });
            //props.resetForm()
        }

        const diffJournee = fondJour+depensesJour
        const diffMois = fondMois+depensesMois
        const diffAnnee = fondAnnee+depensesAnnee
        const diffHier = fondhier+depenseshier

    


    return (
        <div className="content">
        <div className="container">
            <RecolteCat style={{backgroundColor:'#86DDE8'}} title="Aujourd'hui" data={journee} type={diffJournee}/>
            <RecolteCat style={{backgroundColor:'#F65F54'}} title="Hier" data={hier} type={diffHier}/>
            <RecolteCat style={{backgroundColor:'#E8C286'}} title="Mois en cours" data={mois} type={diffMois} />
           {loading ? <Skeleton type="circle" /> : <RecolteCat style={{backgroundColor:'#80DB80'}} title="Année en cours" data={annee} type={diffAnnee} /> }
        </div>
        <div className="Wrapper">
                Rechercher
                <div className="searchContainer">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {(props) => (
                        <Form noValidate>
                     <Grid container spacing={2}>
                            <Grid item xs={6}>
                                    <DateTimePicker
                                    name="dateDebut"
                                    label="date debut"
                                    />
                            </Grid>
                            <Grid item xs={6}>
                                    <DateTimePicker
                                    name="dateFin"
                                    label="date Fin"
                                    />
                            </Grid>
                            <Grid item xs={12} >
                        <Button type='submit' style={btnStyle}  variant='contained' color='primary'>Rechercher</Button>
                            </Grid>
                     </Grid>
                        </Form>
                    )}
                </Formik>
                </div>
                <div className="tableData">
                    {
                        resultat.length === 0 ? <div>
                                        pas de données
                                    </div>
                        :
                        <div>
            <div className="infoContainer">
                <div style={{color:'darkblue',fontSize:20,padding:10}}>
                  Total  {resultat.length} ventes 
                </div>
                <div style={{color:'crimson',fontSize:20,padding:10}} >
                Montant {resultat.reduce((a,v) =>  a = a + v.total , 0 )} Fcfa
                </div>
            </div>
    <MaterialTable columns={columns} data={resultat}  
        options={{
          selection: true
        }}
        onSelectionChange={(selectedRows) => console.log(selectedRows)}
        options={{
          sorting: true, search: true,
          searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
          filtering: true, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
          paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
          exportAllData: true, exportFileName: "TableData", addRowPosition: "first", actionsColumnIndex: -1, selection: true,
          showSelectAllCheckbox: false, showTextRowsSelected: false, selectionProps: rowData => ({
            //disabled: rowData.age == null,  
            color:"primary"
          }),
           columnsButton: true,
          rowStyle: (data, index) => index % 2 === 0 ? { background: "#f5f5f5" } : null,
          headerStyle: { background: "#226D68",color:"#fff"},
          
        }}
        title="Liste des produits vendus "
        icons={{ Add: () => <AddIcon /> }} />
       
                       </div>
                    }
                </div>
            </div>
    </div>

    )
}

export default Recolte
