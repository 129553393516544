import React,{useEffect,useState} from 'react'
import { Grid, Paper, Button, Typography, Container} from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DateTimePicker from '../components/FormsUI/DataTimePicker';
import { useDispatch, useSelector } from 'react-redux'
import { getFournisseurs } from '../redux/actions/fournisseurActions'
import CliniqueService from '../services/CliniqueService'
import DosageService from '../services/DosageService'
import FournisseurService from '../services/FournisseurService'
import { Autocomplete } from "@material-ui/lab";
import SousfamilleService from '../services/SousfamilleService'
import { toast } from "react-toastify";



const RegistrationForm = () => {

    const auth= useSelector((state) => state.auth);
    const dispatch = useDispatch();


    //const formes = [{"FormFarmaceutik":"tablette"},{"FormFarmaceutik":"sirop"}]

    const [formes, setFormes] = useState([])
    const [fournisseurs, setFournisseur] = useState([])
    const [familles, setFamille] = useState([])
    const [sousfamilles, setSousFamille] = useState([])
    const [saveProduits, setSaveProduits] = useState([])
    const [typeInjectable, setTypeInjectable] = useState([])
   

    useEffect(() => {
        dispatch(getFournisseurs())
        const fetchData = async () => {
              await FournisseurService.getFournisseurs()
              .then((response) => {
                 setFournisseur(response.data)
              })
              .catch(e => {
                console.log(e);
             });
       
             await DosageService.getFormes()
              .then((response) => {
                 setFormes(response.data)
              })
              .catch(e => {
                console.log(e);
             });

             await CliniqueService.getFamilles()
              .then((response) => {
                 setFamille(response.data)
              })
              .catch(e => {
                console.log(e);
             });
       
          }
       
          fetchData();
    }, [dispatch])


    const handleChangeValue = async (value, props) => {
       
    }

    const paperStyle = { padding: '0 15px 40px 15px', width: 600,height:700 }
    const btnStyle = { marginTop: 10, width:'100%' }
    const initialValues = {
        nomProd: '',
        prixProd: '',
        intituleFam: '',
        fournisseur: '',
        dateExpiration:'',
        quantite: '',
        codeBarre: '',
        formepharmaceutique: '',
        tva:'0',
        boites:'',
        prixBoite:'',
        pieces:''
        //dosage:''
    }
    const validationSchema = Yup.object().shape({
        nomProd: Yup.string().min(3, "It's too short").required("Required"),
        //dosage: Yup.string().required("Required"),
        //email: Yup.string().email("Enter valid email").required("Required"),
        prixProd:Yup.number().required("Required"),
        intituleFam: Yup.string().required("Required"),
        fournisseur: Yup.string().required("Required"),
        dateExpiration: Yup.date().required("Required"),
        quantite:Yup.number().required("Required"),
        codeBarre:Yup.number(),
        formepharmaceutique: Yup.string().required("Required"),
       // prix_unitaire:Yup.number(),
        tva:Yup.number(),
        boites:Yup.number(),
        prixBoite:Yup.number(),
        pieces:Yup.number(),

        //password: Yup.string().min(8, "Minimum characters should be 8")
        //.matches(passwordRegExp,"Password must have one upper, lower case, number, special symbol").required('Required'),
        //confirmPassword:Yup.string().oneOf([Yup.ref('password')],"Password not matches").required('Required')
    })
    const onSubmit = async (values, props) => {

        var today = new Date()
        var produit = {
            "nomProd":values.nomProd.trim(),
            "prixProd":values.prixProd,
            "intituleFam":values.intituleFam,
            "idFournisseur":values.fournisseur,
            "dateExpiration":values.dateExpiration,
            "dateEng":today.getFullYear()+ "-"+ parseInt(today.getMonth()+1) +"-"+today.getDate(),
            "qtEntrer":values.quantite,
            "speudo":auth.nom,
            "codeBarre":values.codeBarre,
            "FormFarmaceutik":values.formepharmaceutique,
            //"prix_unitaire":values.prix_unitaire,
            "tva":values.tva,
            "boites":values.boites,
            "prixBoite":values.prixBoite,
            "pieces":values.pieces,
            //"dosage":values.dosage.trim(),

        }
        //alert(JSON.stringify(produit), null, 2)
        //dispatch(addProduit(produit))
        await CliniqueService.saveProd(produit)
            .then((response) => {
                var data = response.data
                setSaveProduits(data)
                toast.success("Produit ajouté avec success...", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            })
            .catch((error) => {
                console.log(error)
            })
        //props.resetForm()
    }
    return (
        <Grid container>
            <Paper elevation={0} style={paperStyle}>
                <Grid align='center'>
                    <Typography variant='h6' >Ajouter un nouveau produit</Typography>
                </Grid>
                <Grid item xs={12}>
        <Container maxWidth="md">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {(props) => (
                        <Form noValidate>
                     <Grid container spacing={2}>
                            {/* <TextField label='Name' name="name" fullWidth value={props.values.name}
                    onChange={props.handleChange} /> */}
                    <Grid item xs={12} >
                            <Field as={TextField} name='nomProd' label='Nom produit' fullWidth variant="outlined"
                                error={props.errors.nomProd && props.touched.nomProd}
                                helperText={<ErrorMessage name='nomProd' />} required />
                    </Grid>
                   


<Grid item xs={6} >

 <Autocomplete
    id="intituleFam"
    name="intituleFam"
    options={familles}
    getOptionSelected={(option, value) => option.intituleFam === value?.intituleFam}
    getOptionLabel={option => option.intituleFam}
      onChange={(e, v, r) => {
        //alert(ref0.current.getAttribute("name"));
        handleChangeValue(v?.intituleFam)
        var nom = JSON.stringify(v?.intituleFam)
        props.setFieldValue("intituleFam",v?.intituleFam)
      }}
    renderInput={params => (
        <Field as={TextField} {...params} name="intituleFam" label="Famille" placeholder='famille' variant="outlined" fullWidth
        error={props.errors.intituleFam && props.touched.intituleFam}
        helperText={<ErrorMessage name='intituleFam' />}  required
        />
    )}
/>

    </Grid>



                    <Grid item xs={6}>
                            <Field as={TextField} name='prixProd' label='Prix Vente' fullWidth variant="outlined"
                                error={props.errors.prixProd && props.touched.prixProd}
                                helperText={<ErrorMessage name='prixProd' />} required />
                        </Grid>
                    
                       {/*  <Grid item xs={6}>
                            <Field as={TextField} name='prix_unitaire' label='Prix unitaire' fullWidth variant="outlined"
                                error={props.errors.prix_unitaire && props.touched.prix_unitaire}
                                helperText={<ErrorMessage name='prix_unitaire' />} required />
                        </Grid>  */}

                        <Grid item xs={6}>
                            <Field as={TextField} name='tva' label='TVA' fullWidth variant="outlined"
                                error={props.errors.tva && props.touched.tva}
                                helperText={<ErrorMessage name='tva' />} required />
                        </Grid>


                        <Grid item xs={6} >
                            {/* <Field as={Select} name="formepharmaceutique" label='forme pharmaceutique' placeholder='forme pharmaceutique' fullWidth variant="outlined"
                                error={props.errors.formepharmaceutique && props.touched.formepharmaceutique}
                                helperText={<ErrorMessage name='formepharmaceutique' />} required >
                                     {
                                        formes.map((f)=>(
                                            <MenuItem value={f.FormFarmaceutik}>{f.FormFarmaceutik}</MenuItem>
                                        )
                                        )
                                    }
                                        </Field> */}

                            <Autocomplete
                                id="formepharmaceutique"
                                name="formepharmaceutique"
                                options={formes}
                                getOptionSelected={(option, value) => option.FormFarmaceutik === value?.FormFarmaceutik}
                                getOptionLabel={option => option.FormFarmaceutik}
                                  onChange={(e, v, r) => {
                                    //alert(ref0.current.getAttribute("name"));
                                    setTypeInjectable(v?.FormFarmaceutik)
                                    var nom = JSON.stringify(v?.FormFarmaceutik)
                                    props.setFieldValue("formepharmaceutique",v?.FormFarmaceutik)
                                  }}
                                renderInput={params => (
                                    <Field as={TextField} {...params} name="formepharmaceutique" label="Forme pharmaceutique" placeholder='forme pharmaceutique' variant="outlined" fullWidth
                                    error={props.errors.formepharmaceutique && props.touched.formepharmaceutique}
                                    helperText={<ErrorMessage name='formepharmaceutique' />}  required
                                    />
                                )}
                        />
                                        
                                </Grid>


                                {
                                    typeInjectable === 'Injectables' && (
                                        <>
                                        <Grid item xs={6} >
                            <Field as={TextField} name="boites" label='nombre de boites' fullWidth variant="outlined"
                                error={props.errors.boites && props.touched.boites}
                                helperText={<ErrorMessage name='boites' />} required />
                                </Grid>
                                <Grid item xs={6} >
                                <Field as={TextField} name="prixBoite" label='prix de la Boite' fullWidth variant="outlined"
                                    error={props.errors.prixBoite && props.touched.prixBoite}
                                    helperText={<ErrorMessage name='prixBoite' />} required />
                                    </Grid>
                                    <Grid item xs={6} >
                                    <Field as={TextField} name="pieces" label='nombre de pieces' fullWidth variant="outlined"
                                        error={props.errors.pieces && props.touched.pieces}
                                        helperText={<ErrorMessage name='pieces' />} required />
                                        </Grid>
                                 </>

                                    )
                                }
    
                            

                                <Grid item xs={6}>
                                    <DateTimePicker
                                    name="dateExpiration"
                                    label="date expiration"
                                    />
                                </Grid>

                                <Grid item xs={6} >

                            <Autocomplete
                                id="fournisseur"
                                name="fournisseur"
                                options={fournisseurs}
                                getOptionSelected={(option, value) => option.nomFournisseur === value?.nomFournisseur}
                                getOptionLabel={option => option.nomFournisseur}
                                  onChange={(e, v, r) => {
                                    //alert(ref0.current.getAttribute("name"));
                                    //handleChangeValue(v?.FormFarmaceutik)
                                    var nom = JSON.stringify(v?.idFournisseur)
                                    props.setFieldValue("fournisseur",v?.idFournisseur)
                                  }}
                                renderInput={params => (
                                    <Field as={TextField} {...params} name="fournisseur" label="Fournisseur"  variant="outlined" fullWidth
                                    error={props.errors.fournisseur && props.touched.fournisseur}
                                    helperText={<ErrorMessage name='fournisseur' />}  required
                                    />
                                )}
                            />

                                </Grid>
                              
                               {
                                   typeInjectable === 'Injectables' ? 
                                   <Grid item xs={6} >
                                   <Field as={TextField} name="quantite" label='quantite' fullWidth variant="outlined"
                                       error={props.errors.quantite && props.touched.quantite}
                                       helperText={<ErrorMessage name='quantite' />} 
                                       required 
                                       value={props.values.quantite=props.values.boites*props.values.pieces}
                                       />
                                       
                                       </Grid>
                                       :
                                       <Grid item xs={6} >
                                       <Field as={TextField} name="quantite" label='quantite' fullWidth variant="outlined"
                                           error={props.errors.quantite && props.touched.quantite}
                                           helperText={<ErrorMessage name='quantite' />} required />
                                           </Grid>
                               }
                                <Grid item xs={6} >
                            <Field as={TextField} name="codeBarre" label='Code barre' fullWidth variant="outlined"
                                error={props.errors.codeBarre && props.touched.codeBarre}
                                helperText={<ErrorMessage name='codeBarre' />} required />
                                </Grid>
                              {/*   <Grid item xs={6} >
                            <Field as={TextField} name="codeBarre" label='code barre' fullWidth variant="outlined"
                                error={props.errors.codeBarre && props.touched.codeBarre}
                                helperText={<ErrorMessage name='codeBarre' />} required />
                                </Grid> */}
                            
                               <Grid item xs={12} >
                            <Button type='submit' style={btnStyle} variant='contained'
                                color='primary'>Valider</Button>
                                </Grid>
                                                </Grid>
                        </Form>
                    )}
                </Formik>
                </Container>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default RegistrationForm;