import { combineReducers } from 'redux';
import authReducer from './authReducer';
import depenseReducer from './depenseReducer';
import fournisseurReducer from './fournisseurReducer';
import oneProductReducer from './oneProductReducer';
import produitReducer from './produitReducer';
import stockReducer from './stockReducer';
import userReducer from './userReducer';

const rootReducer = combineReducers({ 
    auth: authReducer,
    users: userReducer,
    produits:produitReducer,
    produit:oneProductReducer,
    fournisseurs:fournisseurReducer,
    depenses:depenseReducer,
    stocks:stockReducer

})

export default rootReducer;