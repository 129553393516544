import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, Grid, Chip } from '@material-ui/core';
import FondService from '../services/FondService';
import CountUp from 'react-countup';
import BonService from '../services/BonService';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 400,
    margin: 'auto',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 300,
    },
    width:300
  },
}));
const MyCardFournisseurs = ({value}) => {
  const classes = useStyles();

  const [data, setData] = useState([]);



  useEffect(() => {
    
    const fetchData = async () => {
       
      await BonService.getBonByFournisseurs(value?.idFournisseur)
      .then((response) => {
          var data = response.data
          setData(data)
          //alert(JSON.stringify(data));
      })
      .catch((error) => {
          console.log(error)
      })

    }
  
    fetchData()
  }, [value])
  
  
  var sum = data && data.reduce((accumulator, value) => accumulator + value.total, 0);


  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h6" component="h3">
              {value.nomFournisseur}
            </Typography>
            <Typography color="textSecondary">
            <Chip
                label={<CountUp start={0} end={sum} duration={2.5} separator=" " suffix='F CFA' />}
                style={{color:'crimson',fontSize:'16px'}} variant="outlined"    /> 
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};


export default MyCardFournisseurs;
