import MaterialTable from 'material-table'
import React, { useEffect, useRef, useState } from 'react'
import CustomizedDialogs from '../dialog'
import GetAppIcon from '@material-ui/icons/GetApp';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from "@material-ui/core/styles";
import CliniqueService from '../../services/CliniqueService'
import { toast } from "react-toastify";
import { Grid, Paper, Button, Typography, Container, NativeSelect} from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete } from "@material-ui/lab";
import ProduitVenduService from '../../services/ProduitVenduService';
import ValideVenteForm from '../ValideVenteForm';
import generateTicket from '../../services/ticketCaisse';
import DeleteIcon from '@material-ui/icons/Delete';

import { Modal, Table } from "antd";
import { useReactToPrint } from 'react-to-print';
import './styles.css'
import moment from 'moment'
import { DataGrid } from '@material-ui/data-grid';

const useVenteStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const VendreProduits = () => {


  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [prodVenduPending,setProdVenduPending] = useState([])
  const [exportData, setExportData] = useState([]);

  const [reportPrVendu, setReportPrVendu] = useState([]);


  const [formes, setFormes] = useState([])
  const [prod, setProd] = useState('')
  const [barCode, setBarCode] = useState('')
  const [famille, setFamille] = useState([])
  const [name, setName] = useState('')
  const [prix, setPrix] = useState(0)
  const [dosage, setDosage] = useState([])
  const [codeBarre, setCodeBarre] = useState([])
  const [familleProduit, setFamilleProduit] = useState([])
  const [quantite, setQuantite] = useState(1)
  const [defaultAmount, setDefaultAmount] = useState(0)
  const [total, setTotal] = useState(0)

  const [modePayement, setModePayement] = useState('ESPECE');
  const [valueRemise, setValueRemise] = useState('0');
  const [montant, setMontant] = React.useState(0);
  const [donne, setDonne] = React.useState(0);
  const [produit, setProduit] = React.useState([]);
  const [allProduct, setAllProduct] = useState([])

  const componentRef = useRef();
  const refSubmit = useRef();
  const refPrint = useRef();


  const [printBillModalVisibility, setPrintBillModalVisibilty] = useState(false);
  const [test, setTest] = useState(false);
  const [valider, setValider] = useState(false);
  const [idCodeProd, setIdCodeProd] = useState(null)



  const typePayement = [
      {"name":"ESPECE"},
      {"name":"WAVE"},
      {"name":"OM"}
  ]



const options = ['ESPECE', 'WAVE','OM'];
const remises = ['0', '5','10','15','20'];
const [value, setValue] = React.useState(options[0]);
const [stateRemise, setRemise] = React.useState(remises[0]);
const [getRemise, setGetRemise] = React.useState(total);



  
  useEffect(() => {
      const fetchData = async () => {
         
           await CliniqueService.getAllProduitDistincts()
              .then((response) => {
                setAllProduct(response.data)
              })
              .catch(e => {
                  console.log(e);
              }); 

          await CliniqueService.getFamilles()
              .then((response) => {
                  setFamille(response.data)
              })
              .catch(e => {
                  console.log(e);
              });

          await CliniqueService.getFormePharmaceutiques()
              .then((response) => {
                  setFormes(response.data)
              })
              .catch(e => {
                  console.log(e);
              });


              await CliniqueService.getAllProductCodeBarre()
              .then((response) => {
                  setCodeBarre(response.data)
              })
              .catch(e => {
                  console.log(e);
              });

              refreshProdVendus()

      }

      fetchData();
  }, [dispatch])



 
  

  const handleCodeBarre = async (value, props) => {

      await CliniqueService.getCodeBarre(value)
          .then((response) => {
              var data = response.data
              //alert(JSON.stringify(data))
              setProd(data[0].nomProd)
              setPrix(data[0].prixProd)
              setValider(true)
          })
          .catch((error) => {
              console.log(error)
              setValider(false)

          })


  }

  const handleChangeValue = async (value, props) => {
      await CliniqueService.getNomProd(value)
          .then((response) => {
              var data = response.data
              setPrix(data[0].prixProd)
              setValider(true)

          })
          .catch((error) => {
              console.log(error)
              //setValider(false)

          })

    /*   await CliniqueService.getDosageProd(value)
          .then((response) => {
              var data = response.data
              setDosage(data)
          })
          .catch((error) => {
              console.log(error)
          })
      await CliniqueService.getFamilleProd(value)
          .then((response) => {
              var data = response.data
              setFamilleProduit(data)
          })
          .catch((error) => {
              console.log(error)
          }) */
  }

  const refreshProdExport = async()=>{
      await CliniqueService.getProdReport()
      .then((response) => {
        setExportData(response.data)
        //generatePDF(exportData)
      })
      .catch(e => {
        console.log(e);
     });
  
  
    }

  const refreshProdVendus = async ()=>{
      await  CliniqueService.getProdVenduPending()
          .then((response) => {
           // alert(JSON.stringify(response.data));
           setProdVenduPending(response.data)
           })
           .catch(e => {
             console.log(e);
           });

     }

  


  const paperStyle = { padding: '0 15px 40px 15px', width: 500, height: 1200 }
  const btnStyle = { marginTop: 2, width: '100%',height:'90%',fontSize:'25px',background:'#f26522',color:'white' }

  const initialVentes = {
    mode:'ESPECE',
    donne: '',
    nom:'',
    remise:"0"
}
const validationSchemaVente = Yup.object().shape({
    mode:Yup.string(),
    donne:Yup.number(),
    nom:Yup.string(),
    remise:Yup.string()

 })


  const initialValues = {
      codeBarre: '',
      prixProd:  prix,
      nomProd: '',
      qtVdu: 1,       
  }
  const validationSchema = Yup.object().shape({
      codeBarre: Yup.number(),
      prixProd: Yup.number(),
      nomProd: Yup.string(),
      qtVdu: Yup.number(),
      //codeBarre:Yup.number().required("Required"),
      //password: Yup.string().min(8, "Minimum characters should be 8")
      //.matches(passwordRegExp,"Password must have one upper, lower case, number, special symbol").required('Required'),
      //confirmPassword:Yup.string().oneOf([Yup.ref('password')],"Password not matches").required('Required')
  })

   

  const classes = useVenteStyles();

  const onSubmitVente =  async (values, props) => {

    
  // alert(JSON.stringify(values));

    if(total == 0){
        toast.error("Il ya pas de produits en cours ", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          return
    }

    if(values.mode==='ESPECE'){

      if(getRemise !== 0){

        if(values.donne < getRemise){
          toast.error("La valeur saisie est petite", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            return
      }

      var depense = {
        "donne":values.donne,
        "nom":values.nom,
        "mode":values.mode,
        "remise":values.remise

    }

     
      //setTotal(getRemise)
      setMontant(depense.donne - getRemise)
      setDonne(depense.donne)

      console.log(JSON.stringify(prodVenduPending));
    prodVenduPending.forEach((product) => {
      product.donne = depense.donne
      product.rendu = depense.donne - getRemise 
      product.nom = depense.nom
      product.mode = depense.mode
      product.remise = depense.remise
      produit.push(product)
  })

      }

      else {

        if(values.donne < total){
          toast.error("La valeur saisie est petite", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            return
      }

      var depense = {
        "donne":values.donne,
        "nom":values.nom,
        "mode":values.mode,
        "remise":values.remise

    }

     
     // setTotal(total)
      setMontant(depense.donne - total)
      setDonne(depense.donne)


    prodVenduPending.forEach((product) => {
      product.donne = depense.donne
      product.rendu = depense.donne - total
      product.nom = depense.nom
      product.mode = depense.mode
      product.remise = depense.remise
      produit.push(product)
  })
      }
  
  }
  else {

    if(getRemise  !== 0){
      depense = {
        "donne":getRemise,
        "nom":values.nom,
        "mode":values.mode,
        "remise":values.remise
    }
    //setTotal(getRemise)
    setMontant(depense.donne - getRemise)
    setDonne(depense.donne)

    console.log(JSON.stringify(prodVenduPending));

    prodVenduPending.forEach((product) => {
      product.donne = depense.donne
      product.rendu = depense.donne - getRemise  
      product.nom = depense.nom
      product.mode_payement = depense.mode
      product.remise = depense.remise
      produit.push(product)
  })

    }
    else {

      depense = {
        "donne":total,
        "nom":values.nom,
        "mode":values.mode,
        "remise":values.remise
    }
    setMontant(depense.donne - total)
    setDonne(depense.donne)

    console.log(JSON.stringify(prodVenduPending));

    prodVenduPending.forEach((product) => {
      product.donne = depense.donne
      product.rendu = depense.donne - total
      product.nom = depense.nom
      product.mode_payement = depense.mode
      product.remise = depense.remise
      produit.push(product)
  })


    }

      
  
      
  }


    //alert(JSON.stringify(values))

  /*   if(values.remise === '0'){
      if(values.mode==='ESPECE'){

        if(values.donne < total){
            toast.error("La valeur saisie est petite", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              return
        }

        var depense = {
            "donne":values.donne,
            "nom":values.nom,
            "mode":values.mode
        }

        setMontant(depense.donne - total)

        prodVenduPending.forEach((product) => {
            product.donne = depense.donne
            product.rendu = depense.donne - total
            product.nom = depense.nom
            product.mode = depense.mode
            produit.push(product)
        })
    }
    else {

         depense = {
            "donne":total,
            "nom":values.nom,
            "mode":values.mode
        }

        setMontant(depense.donne - total)

        prodVenduPending.forEach((product) => {
            product.donne = depense.donne
            product.rendu = depense.donne - total
            product.nom = depense.nom
            product.mode_payement = depense.mode
            produit.push(product)
            console.log(produit)
        })
        
    }
    }
    else {
      if(values.mode==='ESPECE'){

        if(values.donne < total){
            toast.error("La valeur saisie est petite", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              return
        }

        var depense = {
            "donne":values.donne,
            "nom":values.nom,
            "mode":values.mode
        }

        setMontant(depense.donne - total)
        const calcRemise = (total * values.remise)/100
        setTotal(calcRemise)
        alert(total)

        prodVenduPending.forEach((product) => {
            product.donne = depense.donne
            product.rendu = depense.donne - total
            product.nom = depense.nom
            product.mode = depense.mode
            produit.push(product)
        })
    }
    else {

         depense = {
            "donne":total,
            "nom":values.nom,
            "mode":values.mode
        }

        setMontant(depense.donne - total)
        const calcRemise = (total * values.remise)/100
        setTotal(calcRemise)
        alert(total)

        prodVenduPending.forEach((product) => {
            product.donne = depense.donne
            product.rendu = depense.donne - total
            product.nom = depense.nom
            product.mode_payement = depense.mode
            produit.push(product)
            console.log(produit)
        })
        
    }
    } */
   
    console.log("produit --------------------------------")
    console.log(produit)

    await CliniqueService.vendreProduit({"prdVendus" : produit})
       .then((response) => {
         toast.success("Vos produits sont bien vendus avec success...", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setReportPrVendu(response.data)
        console.log('reportPrVendu',response.data)
        setPrintBillModalVisibilty(true)
        refreshProdVendus()
        // renderBill(response.data)
        handlePrint()
         //refPrint.current.click(handlePrint())
       })
       .catch((error) => {
         console.log(error);
         toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }); 


    //alert(JSON.stringify(depense), null, 2)
    props.resetForm()
}

const handlePrint = useReactToPrint({
  content: () => componentRef.current,

});


 

  const renderVente=()=>{

    return (
      <div>
                <Formik initialValues={initialVentes} validationSchema={validationSchemaVente} onSubmit={onSubmitVente} >
                    {(props) => (
                        <Form className={classes.root} style={{display:'flex',flexDirection:'row'}} noValidate>
                              <Grid item xs={4}>
                                            <Autocomplete
                                id="mode"
                                name="mode"
                                value={value}
                                options={options}
                                onChange={(e, v, r) => {
                                    //handleChangeValue(v?.name)
                                    setModePayement(v)
                                    props.setFieldValue("mode",v)
                                  }}
                                 renderInput={params => (
                                    <Field as={TextField} {...params} name="mode" label="Type payement" variant="outlined" fullWidth
                                    error={props.errors.mode && props.touched.mode}
                                    helperText={<ErrorMessage name='mode' />}  required
                                    />
                                )}
                        />
                        </Grid>
                    {modePayement === 'ESPECE'  ?
                    
                    <>
                     <Grid item xs={4}>
                            <Field as={TextField} name='nom' label='Nom client' fullWidth variant="outlined"
                                error={props.errors.nom && props.touched.nom}
                                helperText={<ErrorMessage name='nom' />} required
                                value={props.values.nom}
                                />
                        </Grid>
                    <Grid item xs={4}>
                            <Field as={TextField} name='donne' label='Montant donné' fullWidth variant="outlined"
                                error={props.errors.donne && props.touched.donne}
                                helperText={<ErrorMessage name='donne' />} required 
                                value={props.values.donne}
                                />

                        </Grid>
                     
                    </>
                        :
                        <>
                         <Grid item xs={4}>
                            <Field as={TextField} name='nom' label='Nom client' fullWidth variant="outlined"
                                error={props.errors.nom && props.touched.nom}
                                helperText={<ErrorMessage name='nom' />} required 
                                value={props.values.nom}

                                />
                        </Grid>
                        <Grid item xs={4} >
                                            <Field as={TextField} name="donne" placeholder='Montant donné' fullWidth variant="outlined"
                                                error={props.errors.donne && props.touched.donne}
                                                helperText={<ErrorMessage name='donne' />} required   
                                                value={total}
                                                />
                                        </Grid>
                                    
                        </>

                    }
                                             <Grid item xs={4}>
                                            <Autocomplete
                                id="remise"
                                name="remise"
                                value={valueRemise}
                                options={remises}
                                onChange={(e, v, r) => {
                                    //handleChangeValue(v?.name)
                                    if(v ===0){
                                      setGetRemise(total)
                                      setValueRemise(v)
                                      props.setFieldValue("remise",v)
                                    }
                                    else{
                                      const calcRemise =total * (1 - (v/100))
                                      setGetRemise(calcRemise)
                                      setValueRemise(v)
                                      props.setFieldValue("remise",v)
                                    }
                                  }}
                                 renderInput={params => (
                                    <Field as={TextField} {...params} name="remise" label="Remise" variant="outlined" fullWidth
                                    error={props.errors.remise && props.touched.remise}
                                    helperText={<ErrorMessage name='remise' />}  required
                                    />
                                )}
                        />
                        </Grid>
                            
                               <Grid item xs={4} >
                                
                            <Button type='submit'  style={btnStyle} variant='contained'
                                color='primary'>Vendre</Button>
                                </Grid>
                        </Form>
                    )}
                </Formik>
      </div>
    )
  }


  useEffect(() => {
    const fetchData = async () => {
         await CliniqueService.getProdVenduPending()
          .then((response) => {
            setProdVenduPending(response.data)
          })
          .catch(e => {
            console.log(e);
         });


         await CliniqueService.getProdReport()
          .then((response) => {
            setExportData(response.data)
          })
          .catch(e => {
            console.log(e);
         });


        }
        
     fetchData();
  }, [])


  useEffect(() => {
    refreshSum()
  }, [prodVenduPending])

  const refreshSum = ()=>{
    const sum = prodVenduPending.reduce(function (result, item) {
      return result + item.total;
          }, 0);
          setTotal(sum)
  }
  


  const cartcolumns = [
    {
      title: "Produit",
      dataIndex: "nomProd",
    },
    {
      title: "Quantity",
      dataIndex: "qtVdu",
      render: (id, record) => (
        <div>
          <b>{record.qtVdu}</b>
        </div>
      ),
    },
    {
      title: "Prix",
      dataIndex: "prixProdVendu",
    },
    {
      title: "Total",
      dataIndex: "total",
    }
  ];

  const colCart = [
    { field: 'codeIdProd', headerName: 'ID', width: 100,hide: true },
    {
      field: 'nomProd',
      headerName: 'Produit',
      fontSize: 12,
      fontWeight: 'bold',
      width:250
    },
    {
      field: 'qtVdu',
      headerName: 'Quantity',
      fontSize: 12,
      fontWeight: 'bold',
      width:130
    },
    {
      field: 'prixProdVendu',
      headerName: 'Prix unitaire',
      fontSize: 12,
      fontWeight: 'bold',
      width:150
    },
    {
      field: 'total',
      headerName: 'Total',
      width:170
    }
  ]

  /* 
  const renderBill = ({reportPrVendu}) =>{
    return (
      <div>
         {printBillModalVisibility && (
         <Modal
        onCancel={() => {
          setPrintBillModalVisibilty(false);
        }}
        visible={printBillModalVisibility}
        title="Details de la vente"
        footer={false}
        width={800}
      >
        <div className="bill-model p-3" ref={componentRef}>
          <div className="d-flex justify-content-between bill-header pb-2">
            <div>
              <h1>
                <b>PHARMACIE NDIOL FOUTA</b>
              </h1>
            </div>
            <div>
              <p>DR. AL Ousseynou KEITA</p>
              <p>Sor Diagne Route Nationale N 2/ Saint-Louis:</p>
              <p>X Médina Bois Saint-Louis - Tél: +221 33 961 08 80</p>
              <p>NINEA:007968079 - RC: SN-STL-2020-A-1225</p>
            </div>
          </div>
          <div className="bill-customer-details my-2">
            <p>
              <b>Nom</b> : {JSON.stringify(reportPrVendu[0]?.vendeur)}
            </p>
            <p>
              <b>N</b> : {JSON.stringify(reportPrVendu[0]?.numFactSrt)}
            </p>
            {/* <p>
              <b>Date</b> :{" "}
              {selectedBill.createdAt.toString().substring(0, 10)}
            </p> 
          </div>
          <Table dataSource={reportPrVendu} columns={cartcolumns} pagination={false}/>

          <div className="dotted-border">
              <p><b>Payer par</b> : {reportPrVendu[0]?.mode_payement}</p>
          </div>

          <div className="dotted-border">
              <p><b>rendu</b> : {montant}</p>
          </div>

          <div>
              <h2><b>TOTAL : {total}</b></h2>
          </div>
          <div className="dotted-border"></div>

          <div className="text-center">
                <p>Merci</p>
                <p>Revenez Encore :)</p>
          </div>
        </div>

        <div className="d-flex justify-content-end">
                <Button type='primary' ref={refPrint}   onClick={handlePrint}>Imprimer</Button> 
        </div>
      </Modal>
)
 }
      </div>
    )
  }
  
 */
  const onSubmit = async (values, props) => {

    var today = new Date()
    var produit = {
        "codeBarre": values.codeBarre,
        "prixProdVendu": prix,
        "nomProd": prod === '' ? values.nomProd : prod,
        "dateInsert": today.getFullYear() + "-" + parseInt(today.getMonth() + 1) + "-" + today.getDate(),
        "qtVdu": values.qtVdu,
        "speudo": auth.nom
    }

   // alert("produit /////"+produit);

    await ProduitVenduService.saveProdVendu(produit)
        .then((response) => {
            if (response.data.message  === "Produit insuffisant dans le stock"){
                toast.error("Produit Insuffisant dans le stock...", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                refreshProdVendus()
                refreshProdExport()
               
            }
            else {
                toast.success("Produit vendu avec success...", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                refreshProdVendus()
                refreshProdExport()
                 setProd('')
                props.setFieldValue("codeBarre",'')
                setProd('')
                setValider(false)
            }
            
        })
        .catch((error) => {
            console.log(error);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        });
        props.resetForm()
        //window.location.reload(false);
        window.location.href="/dashboard"
        refreshProdVendus();
      }

    
   
      if (valider === true){
          setValider(false)
          refSubmit.current.click()
          refSubmit.current.setAttribute("disabled", "disabled");
          setValider(false)
          refSubmit.current.removeAttribute("disabled")
          return
      }


    

  const columns = [
    // { title: "ID", field: "codeIdProd", filterPlaceholder: "filter" },
     //{ title: "QUANTITE RESTANTE", field: "qteRestant", align: "center" },
    // { title: "PRODUIT", field: "CodeIdProd"},
     {
      title: "PRODUIT", field: "nomProd"
    },
     { title: "PRIX PRODUIT", field: "prixProdVendu",filterPlaceholder:"filter"},
     { title: "QUANTITE VENDUE", field: "qtVdu",filterPlaceholder:"filter"},
          ]

    return (
   <>
      <div style={{display:'flex',flexDirection:'column'}}>
        <div style={{display:'flex',flexDirection:'column'}}>
        {renderVente()}
        <div style={{display:'flex',justifyContent:'space-evenly'}}>
        <h1 style={{marginTop:'-5px',color:'#017679'}}> Total: {total} </h1>
        <h1 style={{marginTop:'-5px',color:'gray'}}> Total avec Remise: {getRemise.toLocaleString(undefined, {maximumFractionDigits:2})} </h1>
        <h1 style={{marginTop:'-5px',color:'#F04358'}}> Rendu: {montant.toLocaleString(undefined, {maximumFractionDigits:2})} </h1>

         {/* <div className="d-flex">
        <DeleteIcon
        onClick={() => {
          setPrintBillModalVisibilty(true);
        }}
        />

          </div>   */}
        
        </div>
        </div>
  <Grid container spacing={2}>
  <Grid item xs={4}>
                    <Container maxWidth="md">
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                            {(props) => (
                                <Form noValidate>
                                    <Grid container spacing={2}>

                                    <Grid item xs={12}>
                             <Autocomplete
                                id="nomProd"
                                name="nomProd"
                                options={allProduct}
                                getOptionLabel={option =>  option.nomProd}
                                getOptionSelected={(option, value) => option === value}
                                  onChange={(e, v, r) => {
                                    //alert(JSON.stringify(v))
                                    handleChangeValue(v?.nomProd)
                                    props.setFieldValue("nomProd",v?.nomProd)
                                  }}
                                  
                                renderInput={params => (
                                    <Field as={TextField} {...params} name="nomProd" label="Produit" variant="outlined" fullWidth
                                    error={props.errors.nomProd && props.touched.nomProd}
                                    helperText={<ErrorMessage name='nomProd' />}  required autoFocus="autofocus"
                                    value={prod}
                                    />
                                )}
                        /> 
                                     </Grid> 
                                        
                        <Grid item xs={12}>
                              <Autocomplete
                                id="codeBarre"
                                name="codeBarre"
                                options={codeBarre}
                                getOptionLabel={option => option.codeBarre}
                                getOptionSelected={(option, value) => option.codeBarre === value.codeBarre}
                                onChange={(e, v, r) => {
                                    props.setFieldValue("codeBarre",v?.codeBarre)
                                    handleCodeBarre(v?.codeBarre)
                                  }}
                                  autoHighlight={true}
                                  selectOnFocus
                                  clearOnBlur
                                
                                  renderInput={params => (
                                    <Field as={TextField} {...params} name="codeBarre" label="CodeBarre" variant="outlined" fullWidth
                                    error={props.errors.codeBarre && props.touched.codeBarre}
                                    helperText={<ErrorMessage name='codeBarre' />}  required  
                                    />
                                )}
                        />
                        </Grid>

                                        
                            

                    {/*     <NativeSelect
                        variant="outlined" fullWidth
          defaultValue={prod}
          onChange={(e, v, r) => {
            alert(JSON.alert())
            props.setFieldValue("nomProd",v?.nomProd)
          }}
          inputProps={{
            name: 'nomProd',
            id: 'nomProd',
          }}
        >
          {
            prod.map((p)=>(
              <option value={p.nomProd}>{p.nomProd}</option>
            ))
          }
            </NativeSelect> */}
              {/* <Grid item xs={12} >
                        <Field as={TextField} name="nomProd" placeholder='Nom produit' fullWidth variant="outlined"
                       error={props.errors.nomProd && props.touched.nomProd}
                        helperText={<ErrorMessage name='nomProd' />} required   
                        value={prod}
                           />
                         
             </Grid>  */}
                                       <Grid item xs={6} >
                                            <Field as={TextField} name="qtVdu" label='quantite vendue' fullWidth variant="outlined"
                                                error={props.errors.qtVdu && props.touched.qtVdu}
                                                helperText={<ErrorMessage name='qtVdu' />} required
                                                onChange={(e)=>{props.setFieldValue("qtVdu",e.target.value)}}
                                                value={props.values.qtVdu}
                                                />
                                        </Grid>

                                        <Grid item xs={6} >
                                            <Field as={TextField} name="prixProd" placeholder='Prix produit' fullWidth variant="outlined"
                                                error={props.errors.prixProd && props.touched.prixProd}
                                                helperText={<ErrorMessage name='prixProd' />} required   
                                                value={prix}
                                                />
                                        </Grid>

                                        
                                      <Grid item xs={12}>
                                       
                                          <Button type='submit' ref={refSubmit}  style={btnStyle}  variant='contained'
                                              >Valider</Button>
                                      </Grid>
                                       
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Container>
            </Grid>
          <Grid item xs={8} >
         {/*  <div style={{margin:5,display:'flex',justifyContent:'flex-start'}}>
         <CustomizedDialogs title="Passer la vente" boutonTitle="vendre"  refresh={()=>refreshAll()}>
                   <ValideVenteForm  data={prodVenduPending} />
       </CustomizedDialogs>
      </div> */}
          <MaterialTable columns={columns} data={prodVenduPending}
      onRowClick={(evt,rowData)=>{
       
      }}
        editable={{
          onRowAdd: (newRow) => new Promise((resolve, reject) => {
            refreshProdVendus()
            setTimeout(() => resolve(), 500)
          }),
          onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
            console.log("--------------------------------------------------------")
            console.log(newRow.prixProd)
            console.log(oldRow.prixProd)
            
            CliniqueService.updateProdVendu(newRow,oldRow.idProdVdu)
                .then((response) => {
                  toast.success("Produit vendu modifie avec success...", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                  refreshProdVendus()
                  
                  
                })
                .catch((error) => {
                  console.log(error);
                  toast.error(error.response?.data, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                });
            setTimeout(() => resolve(), 500)
          }),
          onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
            CliniqueService.deleteProdVendu(selectedRow.idProdVdu)
                .then((response) => {
                  toast.success("Produit vendu supprime avec success...", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                  refreshProdVendus()

                })
                .catch((error) => {
                  console.log(error);
                  toast.error(error.response?.data, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                });
            setTimeout(() => resolve(), 1000)
          })
        }}
        actions={[
          {
            icon: () => <GetAppIcon />,
            tooltip: "details",
            onClick: (event, rowData) => {
              console.log(rowData)
            },
            
          }
        ]}
        options={{
          selection: true
        }}
        onSelectionChange={(selectedRows) => console.log(selectedRows)}
        options={{
          sorting: true,
          filtering: true, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
          paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
          exportAllData: true, exportFileName: "BON DE COMMANDES", addRowPosition: "first", actionsColumnIndex: -1, selection: true,
          showSelectAllCheckbox: false, showTextRowsSelected: false, selectionProps: rowData => ({
            //disabled: rowData.age == null,  
            color:"primary"
          }),
           columnsButton: true,
          rowStyle: (data, index) => index % 2 === 0 ? { background: "#f5f5f5" } : null,
          headerStyle: { background: "#226D68",color:"#fff"},
          
        }}
        title="Liste des produits à vendre"
                icons={{ Add: () => <AddIcon /> }} />
          </Grid>

          
          
  </Grid>

    
      </div>


 
      {printBillModalVisibility && (
         <Modal
        onCancel={() => {
          setPrintBillModalVisibilty(false);
        }}
        visible={printBillModalVisibility}
        title="Details de la vente"
        footer={false}
        width={800}
      >
        <div ref={componentRef} style={{marginLeft:'14px',marginRight:'14px',marginTop:'10px'}}>
          <div  style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent: 'center'}}>
            <div>
              <h2>
                <b>PHARMACIE NDIOL FOUTA</b>
              </h2>
            </div>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent: 'center',marginTop:'-8px'}}>
              <p style={{marginTop: '-15px',fontSize:'16px'}}> <b>DR. AL Ousseynou KEITA</b> </p>
              <p style={{marginTop: '-16px',fontSize:'16px'}}> <b>Sor Diagne Route Nationale N 2/ Saint-Louis:</b> </p>
               <p style={{marginTop: '-10px',fontSize:'16px'}}><b>Tél: +221 33 961 08 80</b>  </p>
               <p style={{marginTop: '-7px',fontSize:'16px'}}>  <b>NINEA:007968079 - RC: SN-STL-2020-A-1225</b> </p> 
            </div>
          </div>
          <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}>
            <div className="left">
            <b>Vendeur</b> : <b>{reportPrVendu[0]?.vendeur}</b>
            </div>
            <div className="right">
              <p>le : {moment(new Date()).format('DD-MM-YYYY hh:MM:ss')} </p>
            <p>
              <b>N</b> : <b>{reportPrVendu[0]?.numFactSrt}</b>
            </p>
            </div>
            
            {/* <p>
              <b>Date</b> :{" "}
              {selectedBill.createdAt.toString().substring(0, 10)}
            </p> */}
          </div>
          <div>
            <Table columns={cartcolumns}  dataSource={reportPrVendu} pagination={false} bordered/>
         {/*   <DataGrid
        rows={reportPrVendu}
        columns={colCart}
        getRowId={(row) => row.idProdVdu}
        autoHeight={true}
        hideFooter={true}
      /> */}
          </div>

          <div style={{display:"flex",flexDirection:"column", marginTop:'10px'}}>
           <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}> <div> <b>Total :</b> </div>    <div> <b>{total.toLocaleString(undefined, {maximumFractionDigits:2})} F CFA</b> </div> </div> 
           <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}> <div> <b>Dont TVA :</b> </div>  <div> <b>0,00%</b> </div>  </div>
           <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}> <div> <b>Montant Client :</b> </div>  <div> <b>{total.toLocaleString(undefined, {maximumFractionDigits:2})} F CFA</b> </div>  </div>

          </div>
          <div className="dotted-border"></div>

          <div style={{display:"flex",flexDirection:"column"}}>
          <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}> <div> <b>Remise de:</b> </div>   <div><b>{valueRemise}%</b></div> </div>
          <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}> <div> <b>Encaissé</b> </div>   <div><b>{donne.toLocaleString(undefined, {maximumFractionDigits:2})} F CFA</b></div> </div>
          <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}> <div> <b>Net à payer:</b> </div>   <div><b>{getRemise !== 0 ? getRemise.toLocaleString(undefined, {maximumFractionDigits:2}) :total.toLocaleString(undefined, {maximumFractionDigits:2})} F CFA</b></div> </div>
          <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}> <div> <b>Payer par:</b> </div>  <div> <b>{reportPrVendu[0]?.mode_payement}</b> </div> </div>
          <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}>  <div><b>Montant rendu :</b></div>  <div><b>{montant.toLocaleString(undefined, {maximumFractionDigits:2})}</b></div> </div>
          <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}>  <div> <b>nombre d'article :</b> </div>  <div><b>{reportPrVendu && reportPrVendu?.reduce((a,v) =>  a = a + v.qtVdu , 0 )}</b></div> </div>
          </div>
       

          <div style={{display:'flex',alignItems: 'center',justifyContent: 'center',flexDirection:'column',marginTop:'10px'}}>
          <div>  <b>CETTE PHARMACIE EST LA VOTRE </b> </div>
          <div>  <b>MEILLEURE SANTE</b> </div>
          </div>
        </div>

        <div className="d-flex justify-content-end">
                <Button type='primary' ref={refPrint}   onClick={handlePrint}>Imprimer</Button>
        </div>
      </Modal>
)
 }
      
        </>
    )
}

export default VendreProduits
