

import { Button, Container, Grid,TextField } from '@material-ui/core'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CliniqueService from '../services/CliniqueService'
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from 'yup'
import { toast } from "react-toastify";


const FournisseurForm = () => {


  const auth= useSelector((state) => state.auth);
  const produits = useSelector((state) => state.produits);
  const dispatch = useDispatch();


  const useStyles = makeStyles({
    root: {
      maxWidth: 345,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent:'center',
    }
  });

  const btnStyle = { marginTop: 10, width:'100%' }
    const [prod,setProd] = useState([])


  useEffect(() => {
    

    const fetchData = async () => {
    

   }
   console.log("prod ==== ",prod)

   fetchData();
  }, [dispatch]);
    
  const initialValues = {
    nomFournisseur: '', 
    phone1: '', 
    phone2: '', 
    email: '', 
    adresse: '', 
}
const validationSchema = Yup.object().shape({
  nomFournisseur: Yup.string().required("Required"),
  phone1: Yup.number().required("Required"),
  phone2: Yup.number().required("Required"),
  email: Yup.string().email("Enter valid email").required("Required"),
  adresse: Yup.string().required("Required"),
  })
const onSubmit = async (values, props) => {

    var fournisseur = {
      "nomFournisseur":values.nomFournisseur.trim(),
      "phone1":values.phone1.trim(),
      "phone2":values.phone2.trim(),
      "email":values.email.trim(),
      "speudo":auth.nom,
      "adresse":values.adresse,
    }
    await CliniqueService.createFournisseur(fournisseur)
       .then((response) => {
         toast("Fournisseur ajoute avec success...", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
       })
       .catch((error) => {
         console.log(error);
         toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
    //props.resetForm()
}




    return (
        <div>
             <Grid item xs={12}>
        <Container maxWidth="md">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {(props) => (
                        <Form noValidate>
                     <Grid container spacing={2}>
                    <Grid item xs={12} >
                            <Field as={TextField} name='nomFournisseur' label='Nom fournisseur' fullWidth variant="outlined"
                                error={props.errors.nomFournisseur && props.touched.nomFournisseur}
                                helperText={<ErrorMessage name='nomFournisseur' />} required />
                    </Grid>
                   <Grid item xs={12} >
                      <Field as={TextField} name='phone1' label='Numero 1' fullWidth variant="outlined"
                        error={props.errors.phone1 && props.touched.phone1}
                        helperText={<ErrorMessage name='phone1' />} required />
                    </Grid> 
                    <Grid item xs={12} >
                      <Field as={TextField} name='phone2' label='Numero 2' fullWidth variant="outlined"
                        error={props.errors.phone2 && props.touched.phone2}
                        helperText={<ErrorMessage name='phone2' />} required />
                    </Grid> 
                    <Grid item xs={12} >
                            <Field as={TextField} name='email' label='Email' fullWidth variant="outlined"
                                error={props.errors.email && props.touched.email}
                                helperText={<ErrorMessage name='email' />} required />
                    </Grid>
                    <Grid item xs={12} >
                            <Field as={TextField} name='adresse' label='Adresse' fullWidth variant="outlined"
                                error={props.errors.adresse && props.touched.adresse}
                                helperText={<ErrorMessage name='adresse' />} required />
                    </Grid>                            
                               <Grid item xs={12} >
                            <Button type='submit' style={btnStyle} variant='contained'
                                color='primary'>Valider</Button>
                                </Grid>
                                                </Grid>
                        </Form>
                    )}
                </Formik>
                </Container>
                </Grid>
        </div>
    )
}

export default FournisseurForm
