import { Typography } from '@material-ui/core'
import MaterialTable from 'material-table'
import React, { useEffect, useState } from 'react'
import CustomizedDialogs from '../dialog'
import GetAppIcon from '@material-ui/icons/GetApp';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from "@material-ui/core/styles";
import ClientService from '../../services/ClientService'
import { toast } from "react-toastify";
import ClientForm from '../ClientForm'
import TypeClientService from '../../services/TypeClientService';



const Client = () => {
    const useStyles = makeStyles({
        root: {
          maxWidth: 345,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent:'center',
        }
      });
  const classes = useStyles();
  const [formes,setFormes] = useState([])
  const [type, setType] = useState([])
  const [filters,setFilters] = useState([])

    
  const handleFilters = async (e)=>{
    const value = e.target.value;

    await ClientService.getTypeClient(value)
        .then((response) => {
            var data = response.data
            setFormes(data)
            //alert(JSON.stringify(data));
        })
        .catch((error) => {
            console.log(error)
        })
    
}

  useEffect(() => {
    const fetchData = async () => {
      
       await ClientService.getClient()
          .then((response) => {
            setFormes(response.data)
          })
          .catch(e => {
            console.log(e);
         });

         await TypeClientService.getTypeClient()
         .then((response) => {
             var data = response.data
             console.log("types === "+JSON.stringify(data))
             setType(data)
         })
         .catch((error) => {
             console.log(error)
         })


        }

        
     fetchData();
  }, [])
   

      const refreshFormes = async ()=>{
        await ClientService.getClient()
           .then((response) => {
            setFormes(response.data)
            })
            .catch(e => {
              console.log(e);
            });
      }

    
    

  const columns = [
    { title: "ID", field: "idClient", filterPlaceholder: "filter" },
    { title: "REFERENCE", field: "reference", filterPlaceholder: "filter" },
    { title: "NOM", field: "nom", filterPlaceholder: "filter" },
    { title: "ADRESSE", field: "adresse", filterPlaceholder: "filter" },
    { title: "TELEPHONE", field: "telephone", filterPlaceholder: "filter" },
    { title: "QUOTA", field: `quota`, filterPlaceholder: "filter" },
    { title: "SOLDE", field: "solde", filterPlaceholder: "filter",editable: 'never'},
    { title: "REMISE", field: `remise`, filterPlaceholder: "filter" },
    { title: "TAUX IPM", field: `tauxIpm`,searchable: false, export: false},
    {
      title: "TYPE", field: "designation",
      render: (rowData) => <div style={{ background: rowData.designation === "INDIVIDUELS" ? "#008000aa" : rowData.designation === "AUTRES ENTITES" ? "#f90000aa": "#CCC",borderRadius:"4px",paddingLeft:5 }}>{rowData.designation}</div>,
       searchable: false, export: false
    },
    { title: "CREATEUR", field: "speudo", filterPlaceholder: "filter" },
    //{ title: "Enregistre", field: "dateEng", type: "currency", currencySetting: { currencyCode: "INR", minimumFractionDigits: 1 },
     // cellStyle: { background:"#009688" }, headerStyle: { color: "#fff" } },
   ]

    return (
        <div className="App" style={{width:"100%", display:'flow',justifyContent:'center',alignItems:'center',top:0,bottom:0,margin:'auto'}}>
            <Typography variant="title" className={classes.title}>
            Liste des clients
            </Typography>
            <div style={{display:"flex",justifyContent:'flex-start',margin:15}}>
        <div style={{display:'flex',justifyContent:'space-between',width:'100%'}}>
           <div>
           <CustomizedDialogs title="Nouveau client" boutonTitle="Ajouter un client" refresh={()=>refreshFormes()}>
                   <ClientForm />
       </CustomizedDialogs>
           </div>
        <div style={{marginRight:'20px'}} >
        <select style={{padding:'10px', marginLeft:'50px'}} name ="color" onChange={handleFilters}>
                 <option>
                        Type Client
                    </option>
                    {
                      type.map((type)=>(
                        <option value={type.idType}>{type.designation}</option>

                      ))
                    }
        </select>
        </div>
      </div>
        </div>
            <MaterialTable columns={columns} data={formes}
      onRowClick={(evt,rowData)=>{
        //setSelectedRow(rowData)
        //setShowDialog(true)
       /*  history.push(
          {
            pathname: '/report',
            state: { data: rowData }
          }
        ) */
      }}
        editable={{
          onRowAdd: (newRow) => new Promise((resolve, reject) => {
              ClientService.createClient(newRow)
                  .then((response) => {
                    toast.success("Client ajoute avec success...", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    refreshFormes()
                  })
                  .catch((error) => {
                    console.log(error);
                    toast.error(error.response?.data, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  });
            refreshFormes()
            setTimeout(() => resolve(), 500)
          }),
          onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {

                if(parseInt(oldRow.remise ) + parseInt(newRow.tauxIpm) > 100) {
                  toast.error("Pourcentage ne doit pas dépasser 100%", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                  setTimeout(() => resolve(), 100)
                  return;
                }
              
              ClientService.updateClient(newRow,oldRow.idClient)
                  .then((response) => {
                    console.log(JSON.stringify(response));
                    if(response?.data?.message === 'Ce type de client ne peut etre modifié'){
                      toast.error(response?.data?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      });
                    }
                    else {
                      toast.success(response?.data?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      });
                    }
                    
                    refreshFormes()
                  })
                  .catch((error) => {
                    console.log(error);
                    toast.error(error.response?.data, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  });
            refreshFormes()
            setTimeout(() => resolve(), 500)
          }),
          onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
            ClientService.deleteClient(selectedRow.idClient)
                  .then((response) => {
                    toast.success("Client supprime avec success...", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    refreshFormes()
                  })
                  .catch((error) => {
                    console.log(error);
                    toast.error(error.response?.data, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  });
            setTimeout(() => resolve(), 1000)
          })
        }}
        actions={[
          {
            icon: () => <GetAppIcon />,
            tooltip: "details",
            onClick: (event, rowData) => {
              console.log(rowData)
            },
            
          }
        ]}
        options={{
          selection: true
        }}
        onSelectionChange={(selectedRows) => console.log(selectedRows)}
        options={{
          sorting: true, search: true,
          searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
          filtering: true, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
          paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
          exportAllData: true, exportFileName: "Clients", addRowPosition: "first", actionsColumnIndex: -1, selection: true,
          showSelectAllCheckbox: false, showTextRowsSelected: false, selectionProps: () => ({
            //disabled: rowData.age == null,  
            color:"primary"
          }),
           columnsButton: true,
          rowStyle: (_data, index) => index % 2 === 0 ? { background: "#f5f5f5" } : null,
          headerStyle: { background: "#226D68",color:"#fff"},
          
        }}
        title="Liste des Clients "
        icons={{ Add: () => <AddIcon /> }} />
          </div>
    )
}

export default Client
