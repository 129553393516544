
import jsPDF from "jspdf";
import "jspdf-autotable";
// Date Fns is used to format the dates we receive
// from our API call
import { format } from "date-fns";
import  logoImg  from "../images/undraw_medicine_b1ol.png";

// define a generatePDF function that accepts a tickets argument
const generatePDFBon = reportProd => {
  // initialize jsPDF
  const doc = new jsPDF();

  //const x = doc.internal.pageSize.getWidth() - 70; 
  //const y = 10; 

    // Définir les dimensions de l'image (largeur et hauteur)
    //const width = 30;
    //const height = 30;

   // doc.addImage(logoImg, 'PNG', x, y, width, height);


//'l', 'in', [3, 5]
  // define the columns we want and their titles
  var tableColumn = []

  if (reportProd[0].idType === 1){
    tableColumn = ["produit", "quantite","prix","total","statut"];
  }
  else {
    tableColumn = ["produit", "quantite", "prix","statut","total_client","total_ipm"];
  }
  // define an empty array of rows
  const tableRows = [];

  // for each ticket pass all its data into an array
  let total = 0
  let total_client = 0
  let total_ipm = 0
  let ipm = 0
  let client = 0
  let remise = 0
  let total_remise = 0
  let numero =''
  let nom = ''
  let telephone=''
  let adresse=''
  let beneficiaire=''
  let vendeur=''
  if(reportProd[0]?.idType === 1){
    reportProd.forEach(prod => {
      console.log(prod)
      const ticketData = [
        prod.nomProd,
        prod.qtVdu,
        prod.prixProdVendu,
        prod.prixProdVendu * prod.qtVdu,
        prod.statut,
        total_remise =  prod.total_remise,
        total = total + prod.total,
        remise = prod.remise,
        numero =prod.numFactSrt,
        nom = prod.nom,
        telephone=prod.telephone,
        adresse=prod.adresse,
        vendeur=prod.vendeur,
        beneficiaire = prod.beneficiaire
  
        // called date-fns to format the date on the ticket
        //format(new Date(prod.updated_at), "yyyy-MM-dd")
      ];
      // push each tickcet's info into a row
      tableRows.push(ticketData);
    });
  }

  else {
    reportProd.forEach(prod => {
      const ticketData = [
        prod.nomProd,
        prod.qtVdu,
        prod.prixProdVendu * prod.qtVdu,
        prod.statut,
        total_client = prod.total_client,
        client = client + prod.total_client,
        ipm = ipm + prod.total_ipm,
        total_ipm = prod.total_ipm,
        total = total + prod.total,
        numero =prod.numFactSrt,
        nom = prod.nom,
        telephone=prod.telephone,
        adresse=prod.adresse,
        vendeur=prod.vendeur
      ];
      tableRows.push(ticketData);
    });
  }
 

  const date = Date().split(" ");
  // we use a date string to generate our filename.
  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
  var d = new Date()
  var datestring = ("0" + d.getDate()).slice(-2)  + "/" + ("0"+(d.getMonth()+1)).slice(-2) + "/" + d.getFullYear() + " " + ("0"+d.getHours()).slice(-2)+ ":" + ("0"+d.getMinutes()).slice(-2)
  



  var header = function(data){

    const text = "PHARMACIE NDIOL FOUTA";
    const fontSize = 10;
   


    doc.setFontSize(fontSize);
    doc.setFont('helvetica', 'bold');
    //doc.setTextColor("green")
    doc.text(text, 14, 15);


    //doc.setFontSize(14) 
    //doc.setTextColor("green")
    //doc.text("PHARMACIE NDIOL FOUTA", 14, 15);
  
    doc.setFontSize(10) 
    doc.setFont('helvetica', 'bold');
    doc.text("DR. AL Ousseynou KEITA", 14, 20);

    //doc.addImage(logoImg, "PNG", 150, 10, 40, 40);

  
  
    doc.setFontSize(10) 
    doc.setFont('helvetica', 'normal'); 
    doc.text("Sor Diagne Route Nationale N 2", 14, 25);
  
    doc.setFontSize(10) 
    doc.text("X Médina Bois Saint-Louis - Tél: +221 33 961 08 80", 14, 30);
  
    doc.text("NINEA:007968079 - RC: SN-STL-2020-A-1225", 14, 35);

    doc.setFontSize(10) 
    doc.text("N°"+numero.toString(), 150, 35, { align: "left"});
  
  
   
/*   
    doc.setFontSize(14) 
    doc.text("BON CLIENT", 70, 47); */
  
  
   /*  doc.setDrawColor(0, 0, 0);
    doc.line(14, 40, 200, 40); */


const pageWidth = doc.internal.pageSize.getWidth(); // Largeur de la page
const marginRight = 10; // Marge droite fixe
const blockWidth = 55; // Largeur du bloc

const labelWidth = 20; // Largeur de l'étiquette (par exemple, "Nom du client")
const contentWidth = blockWidth - labelWidth; // Largeur du contenu (valeur dynamique)

const labelX = pageWidth - marginRight - blockWidth; // Position X de l'étiquette
const contentX = labelX + labelWidth - 15; // Position X du contenu


doc.setFontSize(10);


if (reportProd[0].idType === 1){

  doc.text("Nom du client :", labelX, 53, { align: "right" });
  doc.text("" + nom, contentX, 53, { align: "left", maxWidth: contentWidth });
  
  doc.text("Téléphone :", labelX, 61, { align: "right" });
  doc.text("" + telephone, contentX, 61, { align: "left", maxWidth: contentWidth });
  
  doc.text("Adresse :", labelX, 65, { align: "right" });
  doc.text(adresse, contentX, 65, { align: "left", maxWidth: contentWidth });  
doc.text("Remise :", labelX, 69, { align: "right" });
doc.text( remise  + "%", contentX, 69, { align: "left" });

 if (beneficiaire !== null || beneficiaire !== '') {
  doc.text("Bénéficiaire :", labelX, 73, { align: "right" });
  doc.text( beneficiaire, contentX, 73, { align: "left" });
 }

}
else {

  doc.text("Nom du client :", labelX, 53, { align: "right" });
  doc.text("" + nom, contentX, 53, { align: "left", maxWidth: contentWidth });
  
  doc.text("Téléphone :", labelX, 61, { align: "right" });
  doc.text("" + telephone, contentX, 61, { align: "left", maxWidth: contentWidth });
  
  doc.text("Adresse :", labelX, 65, { align: "right" });
  doc.text(adresse, contentX, 65, { align: "left", maxWidth: contentWidth });  
doc.text("Total client :", labelX, 82, { align: "right" });
doc.text( client  + "%", contentX, 82, { align: "left" });
doc.text("Total IPM:", labelX, 87, { align: "right" });
doc.text(+ ipm + " Fcfa", contentX, 87, { align: "left" });
}



doc.setFontSize(10) 
doc.setTextColor("black")

/* doc.text("FACTURE N°:", 14, 80, { align: "left" });
doc.text(numero.toString(), 42, 80, { align: "right", maxWidth: contentWidth }); */
doc.setFontSize(18); // Set the font size to 14
doc.setFont('helvetica', 'bold'); // Set the font to bold
doc.text("FACTURE", 14, 60, { align: "left" });


doc.setFontSize(10) 
doc.setTextColor("black")
doc.text("Réalisée le ", labelX - 15, 80, { align: "right" });
doc.text(datestring, contentX - 15, 80, { align: "left" });

/* doc.text("FACTURE N°: "+numero, 14, 21,{
  styles: { fontSize: 10},
});
doc.text(datestring, 150, 27); */

  
/*   
  if (reportProd[0].idType === 1){
    doc.text("TOTAL SANS REMISE",14, 80,{styles:{fontWeight: 'bold'}});
    doc.text(""+total+" Fcfa",80, 80,{styles:{fontWeight: 'bold'}});
    doc.text("TOTAL AVEC REMISE",14, 88,{styles:{fontWeight: 'bold'}});
    doc.text(""+remise+" Fcfa",80, 88,{styles:{fontWeight: 'bold'}});
  }
  else {
    doc.text("TOTAL CLIENT: ",14, 80,{styles:{fontWeight: 'bold'}});
    doc.text(""+client+" Fcfa",80, 80,{styles:{fontWeight: 'bold'}});
    doc.text("TOTAL IPM: ",14, 88,{styles:{fontWeight: 'bold'}});
    doc.text(""+ipm+" Fcfa",80, 88,{styles:{fontWeight: 'bold'}});
  }
   */
  
  
  
  
/*   doc.setLineWidth(0.2);
  doc.line(14, 67, 200, 67) */
  doc.setFontSize(10);
  doc.setLineWidth(1);
  doc.setFillColor(0,0,0);
  
  
  }

  const options = {
    margin: { top: 95 },
    beforePageContent: header,
  };
  
  doc.autoTable(tableColumn, tableRows, options);
  
  const tableBottom = doc.autoTableEndPosY(); // Obtenir la position verticale de fin du tableau
  
  const montantY = tableBottom + 10; // Position verticale pour le montant
  
  doc.setFontSize(10);

  /* doc.text(160, montantY, "Net à payer:",{ align: "right" }); 

  doc.text(165, montantY,Math.round(total - (remise * total) / 100) + "F CFA", { align: "left" }); */

  
  const netAPayerX = 110;
const netAPayerY = montantY;

const montantNetX = netAPayerX + 40;
const montantNetY = montantY;

const tableauWidth = 80;
const tableauHeight = 10;

// Dessiner le cadre du tableau
doc.rect(netAPayerX, netAPayerY, tableauWidth, tableauHeight);

// Dessiner la barre verticale
const barreX = netAPayerX + tableauWidth / 2;
const barreY1 = netAPayerY + 2;
const barreY2 = netAPayerY + tableauHeight - 2;
doc.line(barreX, barreY1, barreX, barreY2);

// Ajouter le contenu dans le tableau
doc.setFontSize(12)
doc.text("Net à payer", netAPayerX + 5, netAPayerY + 5, { align: "left" });
doc.setFontSize(10)
doc.setFont('helvetica', 'bold');
doc.text(Math.round(total - (remise * total) / 100) + " F CFA", montantNetX + 5, montantNetY + 5, { align: "left" });

  

  doc.text(60,montantY + 30, "La présente facture est arrêtée à la somme de "+Math.round(total - (remise * total) / 100)+ "F CFA", { align: "left" });

  const statementY = montantY + 50;
  
  doc.text(10, statementY, "La présente facture est arrêtée à la somme de .............................................................................................");
  doc.text(10, statementY+10, "..................................................................................................................................................... Francs CFA", { align: "left" });



  doc.save(`facture_${datestring}_${nom}.pdf`);
  

  
};

export default generatePDFBon;



/* doc.text("Remise", labelX, 71, { align: "right" });
doc.text(remise + "%", contentX, 71, { align: "left", maxWidth: contentWidth }); */
  
 /*  
  doc.setFontSize(10) 
  doc.text("Nom du client", 14, 53,{
    styles: { fontSize: 10},
  });
  doc.text(""+nom, 80, 53,{
    styles: { fontSize: 10},
  }); */

 /*  doc.setLineWidth(0.2);
  doc.line(14, 55, 200, 55) */
  
      
/*   
  doc.text("Téléphone", 14, 59,{
    styles: { fontSize: 10},
  });
  doc.text(""+telephone, 80, 59,{
    styles: { fontSize: 10},
  });
 */
 /*  doc.setLineWidth(0.2);
  doc.line(14, 61, 200, 61) */
  
 /*  doc.text("Adresse", 14, 65,{
    styles: { fontSize: 10},
  });
  doc.text(adresse, 80, 65,{
    styles: { fontSize: 10},
  }); */