
import React,{useEffect,useState} from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";


import CliniqueService from "../../services/CliniqueService";
import MaterialTable from 'material-table'

import GetAppIcon from '@material-ui/icons/GetApp';
import AddIcon from '@material-ui/icons/Add';
import Skeleton from "../skeleton/Skeleton";
import StockService from "../../services/StockService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
    root: {
      maxWidth: 345,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent:'center',
    }
  });





  
const StockProduit=()=> {
    const classes = useStyles();
    //const location = useLocation();
    const [stocks, setstock] = useState([])
    const [loading, setLoading] = useState(false)
    const auth= useSelector((state) => state.auth);

    

  
    useEffect(() => {
      setLoading(true);
      const fetchData = async () => {
       await CliniqueService.getAllStocks()
        .then((response) => {
          setLoading(false);
         setstock(response.data)
         })
         .catch(e => {
          setLoading(false);
           console.log(e);
         });     
         }
        //console.log(location.pathname); // result: '/secondpage'
        //console.log(location.state.data); // result: 'some_value'
        fetchData()
     }, []);

    
     const refreshStocks =async () =>{

      await CliniqueService.getAllStocks()
      .then((response) => {
        setLoading(false);
       setstock(response.data)
       })
       .catch(e => {
        setLoading(false);
         console.log(e);
       }); 

     }
 

     const columns = [
      { title: "NOM PRODUIT", field: "nomProd", filterPlaceholder: "filter" ,editable: 'never' },
      { title: "quantite en stock", field: "qtEnStck",filterPlaceholder:"filter" },
      //{ title: "Enregistre", field: "dateEng", type: "currency", currencySetting: { currencyCode: "INR", minimumFractionDigits: 1 },
      // cellStyle: { background:"#009688" }, headerStyle: { color: "#fff" } },
    ]
 
     return (
         <div className="App" style={{width:"100%", display:'flow',justifyContent:'center',alignItems:'center',top:0,bottom:0,margin:'auto'}}>
             <Typography variant="title" className={classes.title}>
             Liste des stocks pharmaceutiques
             </Typography>
           {
            loading ? <Skeleton type="circle" />  :
            <MaterialTable columns={columns} data={stocks}
      
            editable={{

              onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                console.log(newRow)
                let stock = {
                  qtEnStck:newRow.qtEnStck,
                }


                if (auth.rol === 'admin'){
                  StockService.updateStock(stock,oldRow.idStock)
                  .then((response) => {
                    toast.success("Correction apportée au stock avec success...", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    refreshStocks()
                  })
                  .catch((error) => {
                    console.log(error);
                    toast.error(error.response?.data, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  });
                }
                else {
                  toast.error("... Vous n'avez pas ce droit...", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                }
             
                 
                refreshStocks()
                setTimeout(() => resolve(), 500)
              }),
              
            }}
            actions={[
              {
                icon: () => <GetAppIcon />,
                tooltip: "details",
                onClick: (event, rowData) => {
                  console.log(rowData)
                },
                
              }
            ]}
            options={{
              selection: true
            }}
            onSelectionChange={(selectedRows) => console.log(selectedRows)}
            options={{
              sorting: true, search: true,
              searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
              filtering: true, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
              paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
              exportAllData: true, exportFileName: "Fournisseurs", addRowPosition: "first", actionsColumnIndex: -1, selection: true,
              showSelectAllCheckbox: false, showTextRowsSelected: false, selectionProps: rowData => ({
                //disabled: rowData.age == null,  
                color:"primary"
              }),
               columnsButton: true,
              rowStyle: (data, index) => index % 2 === 0 ? { background: "#f5f5f5" } : null,
              headerStyle: { background: "#226D68",color:"#fff"},
              
            }}
            title="Liste des stocks "
            icons={{ Add: () => <AddIcon /> }} />
           }
           </div>
     )
 }

export default StockProduit
