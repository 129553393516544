import { toast } from "react-toastify";

const oneProductReducer = (produit={}, action) => {
  switch (action.type) {
      case "GET_ONEPRODUIT":
        toast.success("Voici le produit selectionne...", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return action.produit.data 
    default:
      return produit;
  }
};

export default oneProductReducer;