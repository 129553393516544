

import { Button, Container, Grid,TextField } from '@material-ui/core'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from 'yup'
import { toast } from "react-toastify";
import FondService from '../services/FondService'
import DateTimePicker from './FormsUI/DataTimePicker';


const FondForm = () => {


  const auth= useSelector((state) => state.auth);
  const produits = useSelector((state) => state.produits);
  const dispatch = useDispatch();


  const useStyles = makeStyles({
    root: {
      maxWidth: 345,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent:'center',
    }
  });

  const btnStyle = { marginTop: 10, width:'100%' }
    const [prod,setProd] = useState([])


  useEffect(() => {
    

    const fetchData = async () => {
    

   }

   fetchData();
  }, [dispatch]);
    
  const initialValues = {
    montant: '', 
    dateAjout:'',
    libelle: '', 

}
const validationSchema = Yup.object().shape({
  montant: Yup.number().required("Required"),
  libelle: Yup.string().required("Required"),
  dateAjout: Yup.date().required("Required"),

  })
const onSubmit = (values, props) => {

    var forme = {
      "montant":values.montant,
      "libelle":values.libelle,
      "speudo":auth.nom,
      "userid":auth.id,
      "dateAjout":values.dateAjout
    }
    //alert(JSON.stringify(forme), null, 2)
    FondService.createFond(forme)
       .then((response) => {
         toast.success("Fond ajoute avec success...", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
       })
       .catch((error) => {
         console.log(error);
         toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
    //props.resetForm()
}




    return (
        <div>
             <Grid item xs={12}>
        <Container maxWidth="md">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {(props) => (
                        <Form noValidate>
                     <Grid container spacing={2}>
                    <Grid item xs={12} >
                            <Field as={TextField} name='montant' label='montant' fullWidth variant="outlined"
                                error={props.errors.montant && props.touched.montant}
                                helperText={<ErrorMessage name='montant' />} required />
                    </Grid> 
                    <Grid item xs={12} >
                            <Field as={TextField} name='libelle' label='Libelle' fullWidth variant="outlined"
                                error={props.errors.libelle && props.touched.libelle}
                                helperText={<ErrorMessage name='libelle' />} required />
                    </Grid> 
                    <Grid item xs={12}>
                                    <DateTimePicker
                                    name="dateAjout"
                                    label="date ajout"
                                    />
                                </Grid>                          
                               <Grid item xs={12} >
                            <Button type='submit' style={btnStyle} variant='contained'
                                color='primary'>Valider</Button>
                                </Grid>
                          </Grid>
                        </Form>
                    )}
                </Formik>
                </Container>
                </Grid>
        </div>
    )
}

export default FondForm
