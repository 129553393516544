import React from "react";
import { Navigate } from "react-router-dom";

const Protected = ({ children }) => {

    if (!localStorage.getItem("user") || localStorage.getItem("user") === 'null') {
      return <Navigate to="/login"  replace={true}/>;
    }
   
    return children;
  };

export default Protected;