//export const url = "https://chaoo-todo-app.herokuapp.com/api";
//export const url = "http://2.56.212.143/api";
//export const url = "http://www.ndiolfouta.com/api";


// PROD
//export const url = "https://api.ndiolfouta.com/api";

// DEV
//export const url = "http://localhost:7000/api";

//production
export const url = "https://api.ndiolfouta.com/api";

//export const url = process.env.REACT_APP_API_URL || "http://localhost:7000/api";

export const setHeaders = () => {
  const headers = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
      "Access-Control-Allow-Origin": "*"
    },
  };

  return headers;
};