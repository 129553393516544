

import { Chip } from '@material-ui/core'
import React, { useEffect } from 'react'

import './styles.css'
import CountUp from 'react-countup';


function RecolteCat({title,data,type,style}) {

 //{data.reduce( (a,v) => { a = a + v.total;} , 0 )}
    
    /* const Total = () => {
        const total = data.reduce(
          (prevValue, currentValue) => prevValue + currentValue.total, 0);
         return total;
      };

      const somme = Total() */

/*       var sum = (data.reduce( (a,v) => a = a + v.total , 0 ))
 */      var sum = type ? data && data.reduce((accumulator, value) => accumulator + value.total, 0) - type : 
                data && data.reduce((accumulator, value) => accumulator + value.total, 0) ;


         
         


      const formattedAmount = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'XOF',
      }).format(sum);


      console.log("first")
      console.log(sum)
      console.log("first")

      


    return (
        <div className="wrapper" style={style}>
            <span className="title">
                {title}
            </span>
            <div className="panneau">
              <h1 style={{color:'black'}}> {data.length} <span>ventes</span> </h1>
{/*               <h5> Fond de caisse: <span>{type} Fcfa</span> </h5>
 */}
{/*  <CountUp start={0} end={sum} duration={2.5} separator="," prefix="F cfa" />
 */}     
{/*                  label={`${formattedAmount}`}
 */}
         <Chip
                label={<CountUp start={0} end={sum} duration={2.5} separator=" " suffix='F CFA' />}
                style={{color:'crimson',fontSize:'16px',backgroundColor:'white'}} variant="outlined"    /> 
       <div>
                Quantité vendue : <span className="prix"> {data && data.reduce((a,v) =>  a = a + v.qtVdu , 0 )} </span> 
            </div>
            </div>
           
            
        </div>
    )  
}

export default RecolteCat


