import {Typography } from '@material-ui/core'
import MaterialTable from 'material-table'
import React, { useEffect, useRef, useState } from 'react'
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from "@material-ui/core/styles";
import CliniqueService from '../../services/CliniqueService'
import ProduitVenduService from '../../services/ProduitVenduService'
import DeleteIcon from '@material-ui/icons/Delete';

import { toast } from "react-toastify";
import generatePDF from '../../services/reportGenerator'
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Skeleton from '../skeleton/Skeleton';
import { Button, Modal } from 'antd';
import { DataGrid } from '@material-ui/data-grid';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment'
import ClientService from '../../services/ClientService';


const ProduitVendu = () => {
    const useStyles = makeStyles({
        root: {
          maxWidth: 345,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent:'center',
        }
      });


  const classes = useStyles();
  const [prodVendu,setProdVendus] = useState([])
  const [prodVenduPending,setProdVenduPending] = useState([])
  const [exportData, setExportData] = useState([]);
  const [loading, setLoading] = useState(false);

  const componentRef = useRef();
  const refSubmit = useRef();
  const refPrint = useRef();
  const [prodFacture, setProdFacture] = useState([]);

  const colCart = [
    { field: 'codeIdProd', headerName: 'ID', width: 100,hide: true },
    {
      field: 'nomProd',
      headerName: 'Produit',
      fontSize: 12,
      fontWeight: 'bold',
      width:250
    },
    {
      field: 'qtVdu',
      headerName: 'Quantity',
      fontSize: 12,
      fontWeight: 'bold',
      width:130
    },
    {
      field: 'prixProdVendu',
      headerName: 'Prix unitaire',
      fontSize: 12,
      fontWeight: 'bold',
      width:150
    },
    {
      field: 'total',
      headerName: 'Total',
      width:170
    }
  ]

  const [printBillModalVisibility, setPrintBillModalVisibilty] = useState(false);


  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  
  });


  const handleFilters = async (e)=>{
    const value = e.target.value;
    
    await CliniqueService.getProdModePayement(value)
        .then((response) => {
            var data = response.data
            setProdVendus(data)
            //alert(JSON.stringify(data));
        })
        .catch((error) => {
            console.log(error)
        })
    
}

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
         await CliniqueService.getProdEtatVendu()
          .then((response) => {
            setProdVendus(response.data)
            setLoading(false);

          })
          .catch(e => {
            setLoading(false);
            console.log(e);
         });

         await CliniqueService.getProdVenduPending()
          .then((response) => {
            setProdVenduPending(response.data)
          })
          .catch(e => {
            console.log(e);
         });

         await CliniqueService.getProdReport()
          .then((response) => {
            setExportData(response.data)
          })
          .catch(e => {
            console.log(e);
         });

        }
        
     fetchData();
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      await CliniqueService.getProdEtatVendu()
       .then((response) => {
         setProdVendus(response.data)
       })
       .catch(e => {
         console.log(e);
      });

      await CliniqueService.getProdVenduPending()
       .then((response) => {
         setProdVenduPending(response.data)
       })
       .catch(e => {
         console.log(e);
      });

      await CliniqueService.getProdReport()
       .then((response) => {
         setExportData(response.data)
       })
       .catch(e => {
         console.log(e);
      });

     }
     
  fetchData();
  }, [])
  

  
        


      const refreshProdVendus = async ()=>{
        await CliniqueService.getProdEtatVendu()
           .then((response) => {
            setProdVendus(response.data)
            })
            .catch(e => {
              console.log(e);
            });
      }
      const reportProd = exportData;

   
      const type = [{"designation": 'ESPECE'},{"designation": 'WAVE'},{"designation": 'OM'}]
    

  const columns = [
    
     {
      title: "PRODUIT", field: "nomProd"
    },
    { title: "PRIX PRODUIT", field: "prixProdVendu",filterPlaceholder:"filter"},
     { title: "QUANTITE VENDUE", field: "qtVdu",filterPlaceholder:"filter"},
     { title: "Nom", field: "nom",filterPlaceholder:"filter" },
     {
      title: "PAYEMENT", field: "mode_payement",
      render: (rowData) => <div style={{ background: rowData.mode_payement === "WAVE" ?  "#1AB3E5" : rowData.mode_payement ==="OM" ? "#f16e00" : "#009933",borderRadius:"4px",paddingLeft:5 }}>{rowData.mode_payement}</div>,
       searchable: false, export: false
    },
     { title: "Donne", field: "donne",filterPlaceholder:"filter" },
     { title: "Rendu", field: "rendu",filterPlaceholder:"filter" },
     /*  {
      title: "ETAT", field: "etat",
      render: (rowData) => <div style={{ background: rowData.etat === "VENDU" ? "#008000aa" : "#f90000aa",borderRadius:"4px",paddingLeft:5 }}>{rowData.etat}</div>,
       searchable: false, export: false
    },  */    
    { title: "NUMERO FACTURE ", field: "numFactSrt",filterPlaceholder:"filter" },
     { title: "ENREGISTRE", field: "dateInsert", type: "date"},
     { title: "VENDEUR", field: "vendeur",filterPlaceholder:"filter"},
     // cellStyle: { background:"#009688" }, headerStyle: { color: "#fff" } },
   ]

    return (
        <div className="App" style={{width:"100%", display:'flow',justifyContent:'center',alignItems:'center',top:0,bottom:0,margin:'auto'}}>
            <Typography variant="title" className={classes.title}>
              Liste des produits vendus
            </Typography>
           <div style={{display:'flex',justifyContent:'flex-start',marginTop:'10px',padding:'10px'}}>
           <select style={{padding:'10px',height:'40px'}} name ="color" onChange={handleFilters}>
                    <option>
                        Type Payement
                    </option>
                    {
                      type.map((type)=>(
                        <option value={type.designation}>{type.designation}</option>

                      ))
                    }
                </select>
           </div>
      <div style={{display:"flex",justifyContent:'space-between'}}>
        {/* <div style={{display:"flex",justifyContent:'flex-start',margin:15}}>
        <div>
         <CustomizedDialogs title="Nouveau produit" boutonTitle="vendre un produit" refresh={()=>refreshProdVendus()}>
                   <ProdVenduForm />
       </CustomizedDialogs>
      </div>
        </div> */}
       {/*  <div style={{display:"flex",justifyContent:'flex-start',margin:10}}>
        <div>
        <Button type='submit' onClick={()=>handleVendre()} style={btnStyle} variant='contained'
                                color='primary'>Vendre</Button>
      </div>
      <div>
        <Button type='submit' onClick={() => generatePDF(reportProd)} style={btnStyle} variant='contained'
                                color='primary'>Report</Button>
      </div>
        </div> */}
        </div>
          { loading ? <Skeleton type="custom" /> :
             
             <MaterialTable columns={columns} data={prodVendu}
             onRowClick={(evt,rowData)=>{
               //setSelectedRow(rowData)
               //setShowDialog(true)
              /*  history.push(
                 {
                   pathname: '/report',
                   state: { data: rowData }
                 }
               ) */
             }}
               editable={{
                 onRowAdd: (newRow) => new Promise((resolve, reject) => {
                   refreshProdVendus()
                   setTimeout(() => resolve(), 500)
                 }),
                 onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                   
                   CliniqueService.updateProdVendu(newRow,oldRow.idProdVdu)
                       .then((response) => {
                         toast.success("Produit vendu modifie avec success...", {
                           position: toast.POSITION.BOTTOM_RIGHT,
                         });
                         refreshProdVendus()
                       })
                       .catch((error) => {
                         console.log(error);
                         toast.error(error.response?.data, {
                           position: toast.POSITION.BOTTOM_RIGHT,
                         });
                       });
                   setTimeout(() => resolve(), 500)
                 }),
                 onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
                   CliniqueService.deleteProdVendu(selectedRow.idProdVdu)
                       .then((response) => {
                         toast.success("Produit vendu supprime avec success...", {
                           position: toast.POSITION.BOTTOM_RIGHT,
                         });
                         refreshProdVendus()
       
                       })
                       .catch((error) => {
                         console.log(error);
                         toast.error(error.response?.data, {
                           position: toast.POSITION.BOTTOM_RIGHT,
                         });
                       });
                   setTimeout(() => resolve(), 1000)
                 })
               }}
               actions={[
                 {
                   icon: () => <DeleteIcon color="warn"  />,
                   tooltip: "Annuler la vente",
                   onClick: (event, rowData) => {
                       ProduitVenduService.deleteProdVendu(rowData[0].idProdVdu)
                     .then((response) => {
                       toast.success("produit vendu annule ...", {
                         position: toast.POSITION.TOP_RIGHT,
                       });
                       refreshProdVendus()
                       })
                       .catch(e => {
                         console.log(e);
                       });
       
                   },
                   
                 },
                 {
                   icon: () => <ImportExportIcon color="info"  />,
                   tooltip: "Generer la Facture",
                   onClick: (event, rowData) => {
                       ProduitVenduService.generateFacture(rowData[0].numFactSrt)
                     .then((response) => {
                       refreshProdVendus()
                       generatePDF(response.data)
                       })
                       .catch(e => {
                         console.log(e);
                       }); 
                      // alert(JSON.stringify(rowData))
                       /* setProdFacture(rowData)
                       setPrintBillModalVisibilty(true)
                       alert(printBillModalVisibility) */
       
                   },
                   
                 }
               ]}
               options={{
                 selection: true
               }}
               onSelectionChange={(selectedRows) => console.log(selectedRows)}
               options={{
                 sorting: true, search: true,
                 searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
                 filtering: true, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
                 paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
                 exportAllData: true, exportFileName: "Produits vendus", addRowPosition: "first", actionsColumnIndex: -1, selection: true,
                 showSelectAllCheckbox: false, showTextRowsSelected: false, selectionProps: rowData => ({
                   //disabled: rowData.age == null,  
                   color:"primary"
                 }),
                  columnsButton: true,
                 rowStyle: (data, index) => index % 2 === 0 ? { background: "#f5f5f5" } : null,
                 headerStyle: { background: "#226D68",color:"#fff"},
                 
               }}
               title="Liste des produits vendus "
               icons={{ Add: () => <AddIcon /> }} />
          }


{printBillModalVisibility && (
         <Modal
        onCancel={() => {
          setPrintBillModalVisibilty(false);
        }}
        visible={printBillModalVisibility}
        title="Details de la vente"
        footer={false}
        width={800}
      >
        <div ref={componentRef} style={{marginLeft:'14px',marginRight:'14px',marginTop:'10px'}}>
          <div  style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent: 'center'}}>
            <div>
              <h2>
                <b>PHARMACIE NDIOL FOUTA</b>
              </h2>
            </div>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent: 'center',marginTop:'-8px'}}>
              <p style={{marginTop: '-15px',fontSize:'16px'}}>DR. AL Ousseynou KEITA</p>
              <p style={{marginTop: '-16px',fontSize:'16px'}}>Sor Diagne Route Nationale N 2/ Saint-Louis:</p>
               <p style={{marginTop: '-10px',fontSize:'16px'}}>Tél: +221 33 961 08 80  </p>
               <p style={{marginTop: '-7px',fontSize:'16px'}}>NINEA:007968079 - RC: SN-STL-2020-A-1225 </p> 
            </div>
          </div>
          <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}>
            <div className="left">
            <b>Vendeur</b> : {prodFacture[0]?.vendeur}
            </div>
            <div className="right">
              <p>le : {moment(new Date()).format('DD-MM-YYYY hh:MM:ss')} </p>
            <p>
              <b>N</b> : {prodFacture[0]?.numFactSrt}
            </p>
            </div>
            
            {/* <p>
              <b>Date</b> :{" "}
              {selectedBill.createdAt.toString().substring(0, 10)}
            </p> */}
          </div>
          <div>
{/*             <Table columns={cartcolumns}  dataSource={reportPrVendu} pagination={false} bordered/>
 */}            <DataGrid
        rows={prodFacture}
        columns={colCart}
        getRowId={(row) => row.codeIdProd}
        rowClassName={{fontSize:'13px',fontWeight:'bold'}}
        autoHeight={true}
        hideFooter={true}
      />
          </div>

          <div style={{display:"flex",flexDirection:"column", marginTop:'10px'}}>
         {/*   <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}> <div>Total : </div>    <div> {total.toLocaleString(undefined, {maximumFractionDigits:2})} F CFA</div> </div> 
           <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}> <div>Dont TVA : </div>  <div>0,00%</div>  </div>
           <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}> <div>Montant Client :</div>  <div>{total.toLocaleString(undefined, {maximumFractionDigits:2})} F CFA</div>  </div>
 */}
          </div>
          <div className="dotted-border"></div>

          <div style={{display:"flex",flexDirection:"column"}}>
          <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}> <div> <b>Remise de:</b> </div>   <div><b>{prodFacture[0]?.remise}%</b></div> </div>
         {/*  <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}> <div> <b>Encaissé</b> </div>   <div><b>{donne.toLocaleString(undefined, {maximumFractionDigits:2})} F CFA</b></div> </div>
          <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}> <div> <b>Net à payer:</b> </div>   <div><b>{getRemise !== 0 ? getRemise.toLocaleString(undefined, {maximumFractionDigits:2}) :total.toLocaleString(undefined, {maximumFractionDigits:2})} F CFA</b></div> </div>
          <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}> <div>Payer par:</div>  <div>{prodFacture[0]?.mode_payement}</div> </div>
          <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}>  <div><b>Montant rendu :</b></div>  <div><b>{montant.toLocaleString(undefined, {maximumFractionDigits:2})}</b></div> </div>
           */}<div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}>  <div>nombre d'article :</div>  <div><b>{prodFacture && prodFacture?.reduce((a,v) =>  a = a + v.qtVdu , 0 )}</b></div> </div>
          </div>
       

          <div style={{display:'flex',alignItems: 'center',justifyContent: 'center',flexDirection:'column',marginTop:'10px'}}>
          <div>  CETTE PHARMACIE EST LA VOTRE </div>
          <div>  MEILLEURE SANTE</div>
          </div>
        </div>

        <div className="d-flex justify-content-end">
                <Button type='primary' ref={refPrint}   onClick={handlePrint}>Imprimer</Button>
        </div>
      </Modal>
)
 }

          </div>
    )
}

export default ProduitVendu
