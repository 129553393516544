import React, { useEffect, useState, useRef } from 'react'
import { Grid, Paper, Button, Typography, Container} from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from "react-toastify";
import { Autocomplete } from "@material-ui/lab";
import BonClientService from '../services/BonClientService'




const PayementForm = ({bons}) => {

    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    //const formes = [{"FormFarmaceutik":"tablette"},{"FormFarmaceutik":"sirop"}]

    const [type, setType] = useState([])
    const [prod, setProd] = useState([])
    const [idPayement, setIdPayement] = useState()
    const [paye, setPaye] = useState()


    useEffect(() => {
        const fetchData = async () => {
            await BonClientService.getTypePayement()
                .then((response) => {
                    setType(response.data)
                })
                .catch(e => {
                    console.log(e);
                });

        }

        fetchData();
    }, [dispatch])







    const paperStyle = { padding: '0 15px 40px 15px', width: 600, height: 200 }
    const btnStyle = { marginTop: 10, width: '100%' }
    const initialValues = {
        idType: '',
        numero: '',
    }
    const validationSchema = Yup.object().shape({
        numero: Yup.number(),
        idType: Yup.number().required("Required"),
    })


    

    const onSubmit = (values, props) => {
      //  alert(JSON.stringify(values))
        var produit = {
            "idType": values.idType,
            "numero":values.numero,
            "speudo": auth.nom,
            //"dosage":values.dosage.trim(),
        }



        bons.forEach((product) => {
            product.numero = produit.numero
            product.idPaye = produit.idType
            prod.push(product)
        })



        BonClientService.payerTout({"prdVendus" : prod,"paye":produit.idType})
            .then((response) => {
                
                    toast.success("les bons ont ete payes avec success...", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
            })
            .catch((error) => {
                console.log(error);
                toast.error(error.response?.data, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            });
        // alert(props)
        props.resetForm()
    }
    return (
        <Grid container>
            <Paper elevation={0} style={paperStyle}>
                <Grid align='center'>
                    <Typography variant='h6' >Payer tous mes bons</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth="md">
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                            {(props) => (
                                <Form noValidate>
                                    <Grid container spacing={2}>
                                        {/* <TextField label='Name' name="name" fullWidth value={props.values.name}
                    onChange={props.handleChange} /> */}
                                        

                                        
                                <Grid item xs={12}>
                                            <Autocomplete
                                id="idType"
                                name="idType"
                                options={type}
                                getOptionLabel={option => option.designation}
                                getOptionSelected={(option, value) => option.designation === value.designation}
                                  onChange={(e, v, r) => {
                                    //handleChangeValue(v?.designation)
                                    setIdPayement(v?.id_type)
                                    setPaye(v?.id_type)
                                    props.setFieldValue("idType",v?.id_type)
                                  }}

                                renderInput={params => (
                                    <Field as={TextField} {...params} name="idType" label="Type payement" variant="outlined" fullWidth
                                    error={props.errors.idType && props.touched.idType}
                                    helperText={<ErrorMessage name='idType' />}  required
                                    />
                                )}
                        />
                        </Grid>

                              
                            { (idPayement === 2 || idPayement === 3) && <Grid item xs={12} >
                                <Field as={TextField} name="numero" label='numero' fullWidth variant="outlined"
                                error={props.errors.numero && props.touched.numero}
                                helperText={<ErrorMessage name='numero' />} required />
                            </Grid> }

                                       


                                        <Grid item xs={12} >
                                            <Button type='submit' style={btnStyle} variant='contained'
                                                color='primary'>Valider</Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Container>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default PayementForm;