import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { Grid, Paper, Button, Container} from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print';
import './styles.css'
import moment from 'moment'
import AddCommande from '../AddCommande';
import PendingCommandes from '../PendingCommandes';
import NumCommandeService from '../../../services/NumCommandeService';
import BonService from '../../../services/BonService';

const useVenteStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const AjoutCommandes = () => {

  const auth = useSelector((state) => state.auth);
  const [commandes,setCommandes] = useState([])
  const [exportData, setExportData] = useState([]);



  const [total, setTotal] = useState(0)


  const componentRef = useRef();
  const [nums, setNums] = useState([])



  const [printBillModalVisibility, setPrintBillModalVisibilty] = useState(false);




    const refreshCommandes = async ()=>{
      await  BonService.getBonsEncours()
          .then((response) => {
            console.log("render Commandes !!!!!!!!!!!!")
           setCommandes(response.data)
           })
           .catch(e => {
             console.log(e);
           });

     } 

  
  useEffect(() => {
      refreshCommandes()
      fetchNums()
  }, [])

  

  const btnStyle = { marginTop: 2, width: '100%',height:'90%',fontSize:'25px',background:'#f26522',color:'white' }

  const initialNumbers = {
    numero: '',
}
const validationSchemaCommande = Yup.object().shape({
  numero: Yup.string().required("Required")
 })


   

  const classes = useVenteStyles();

  const onSubmitVente =  async (values, props) => {
    
    await  NumCommandeService.createNum(values).then((res) => {
      fetchNums()
      toast.success("Numéro ajouté avec success...", {
        position: toast.POSITION.BOTTOM_RIGHT,
    });
     fetchNums()
    })
    .catch(err => {
      console.log(err)
    })
    props.resetForm()
}

const handlePrint = useReactToPrint({
  content: () => componentRef.current,
});


 

  const renderVente=()=>{

    return (
      <div>
                <Formik initialValues={initialNumbers} validationSchema={validationSchemaCommande} onSubmit={onSubmitVente} >
                    {(props) => (
                        <Form className={classes.root} style={{display:'flex',flexDirection:'row'}} noValidate>
                              <Grid item xs={4}>
                              <Field as={TextField} name="numero" placeholder='Numéro commande' fullWidth variant="outlined"
                                                error={props.errors.numero && props.touched.numero}
                                                helperText={<ErrorMessage name='numero' />} required   
                                                />
                        </Grid>
                            
                               <Grid item xs={4} >
                                
                            <Button type='submit'  style={btnStyle} variant='contained'>Ajouter</Button>
                                </Grid>
                        </Form>
                    )}
                </Formik>
      </div>
    )
  }



  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refreshSum = ()=>{
    const sum = commandes.reduce(function (result, item) {
      return result + item.total;
          }, 0);
          setTotal(sum)
  }

  useEffect(() => {
    refreshSum()
  }, [commandes, refreshSum])





  const fetchNums = async () => {
    await NumCommandeService.getNumbers().then((response) => {
        setNums(response.data)
    })
    .catch(e => {
      console.log("error numeros" + e)
    })
  }




      const columns = [
        // { title: "ID", field: "idBonDeComdeMat", filterPlaceholder: "filter" },
         { title: "REFERENCE", field: "numero", filterPlaceholder: "filter" },
         { title: "NOM PRODUIT", field: "nomProd", filterPlaceholder: "filter" },
         { title: "FOURNISSEUR", field: "nomFournisseur", filterPlaceholder: "filter" },
         { title: "PRIX VENTE", field: "prixProd", filterPlaceholder: "filter" },
         { title: "PRIX UNITAIRE", field: "prixUnitaire", filterPlaceholder: "filter" },
         { title: "EXPIRATION", field: `date_expiration`, filterPlaceholder: "filter",type: "date" },
         { title: "DATE COMMANDE", field: "dateComde", filterPlaceholder: "filter",type: "date"},
         { title: "QUANTITE COMMANDEE", field: `qteComder`, filterPlaceholder: "filter" },
         { title: "QUANTITE LIVREE", field: `quan_livree`, filterPlaceholder: "filter" }
        ]

    return (
   <>
{/*       <h2 style={{textAlign: "center",textTransform: "uppercase",fontWeight: "bold"}}>Bons clients</h2>
 */}      <div style={{display:'flex',flexDirection:'column'}}>
        <div style={{display:'flex',flexDirection:'column'}}>
        {renderVente()}
        <div style={{display:'flex',justifyContent:'space-evenly'}}>
        <h1 style={{marginTop:'-5px'}}> Total: {total} </h1>
{/*         <h1 style={{marginTop:'-5px',color:'gray'}}> Quota: {quota} </h1>
 */}        <h1 style={{marginTop:'-5px',color:'green'}}> Produits: {commandes?.length} </h1>
{/*         <h1 style={{marginTop:'-5px',color:'crimson'}}> Solde: {solde} </h1>
 */}        
        </div>
        </div>
  <Grid container spacing={2}>
  <Grid item xs={4} >
                    <Container maxWidth="md">
                        <Paper elevation={3} sx={{padding:'5px'}} >
                        <AddCommande nums={nums} refresh={()=>refreshCommandes()} />
                        </Paper>
                    </Container>
            </Grid>
          <Grid item xs={8} >
            <PendingCommandes  commandes={commandes} refresh={()=>refreshCommandes()}   /> 
   
          </Grid>
  </Grid>

    
      </div>

      
        </>
    )
}

export default AjoutCommandes
