import axios from "axios";
import { toast } from "react-toastify";
import { setHeaders, url } from "../../api";

export const getDepenses = () => {
  return (dispatch) => {
    axios
      .get(`${url}/depenses/all`, setHeaders())
      .then((depenses) => {
        dispatch({
          type: "GET_DEPENSES",
          depenses,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};



export const addDepense = (newDepense) => {
  return (dispatch, getState) => {
    axios
      .post(`${url}/depenses/save`, { ...newDepense }, setHeaders())
      .then((depense) => {
        dispatch({
          type: "ADD_DEPENSE",
          depense,
        });
      })
      .catch((error) => {
        console.log("error ",error.response);
        toast.error(error.response?.data?.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
};

export const updateDepense = (updatedDepense, id) => {
  return (dispatch) => {
    axios
      .put(`${url}/depenses/${id}`, updatedDepense, setHeaders())
      .then((depense) => {
        dispatch({
          type: "UPDATE_DEPENSE",
          depense,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
};

export const deleteDepense= (id) => {
  return (dispatch) => {
    axios
      .delete(`${url}/depenses/${id}`, setHeaders())
      .then(() => {
        dispatch({
          type: "DELETE_DEPENSE",
          id,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
};

export const checkDepense = (id) => {
  return (dispatch) => {
    axios
      .patch(`${url}/depenses/${id}`, {}, setHeaders())
      .then((depense) => {
        dispatch({
          type: "CHECK_DEPENSE",
          depense,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
};