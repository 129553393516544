/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup'
import { Grid, Paper, Button, Typography, Container,TextField } from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Autocomplete } from "@material-ui/lab";
import { toast } from "react-toastify";
import CliniqueService from '../../services/CliniqueService';
import DateTimePicker from '../../components/FormsUI/DataTimePicker';
import FournisseurService from '../../services/FournisseurService';
import BonService from '../../services/BonService';



const AddCommande = ({refresh,nums}) => {


  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();


  const [fournisseurs, setFournisseur] = useState([])
  const [prod, setProd] = useState([])
  const [prixUnite, setPrixUnite] = useState(0)
  const [prixPub, setPrixPub] = useState(0)
  const [nomProd, setNomProd] = useState([])
  const [nomFournisseur, setNomFournisseur] = useState([])
  //const [nums, setNums] = useState([])


  const paperStyle = { padding: '0 15px 40px 15px', width: 600, height: 620 }
    const btnStyle = { marginTop: 10, width: '100%',background:'#f26522',color:'white'}
    const initialValues = {
        speudo: '',
        codeIdProd: '',
        qteComder: '',
        quan_livree: '',
        prixPublic: '',
        prixUnitaire:'',
        idFournisseur: '',
        idNumero: '',
        dateComde:'',
        date_expiration:''
    }
    const validationSchema = Yup.object().shape({
        speudo: Yup.string(),
        idFournisseur: Yup.number().required("Obligatoire"),
        codeIdProd: Yup.string().required("Obligatoire"),
        idNumero: Yup.string(),
        qteComder: Yup.number().required("Obligatoire"),
        prixPublic: Yup.number(),
        prixUnitaire: Yup.number(),
        dateComde: Yup.date().required("Obligatoire"),
        date_expiration: Yup.date().required("Obligatoire"),
        quan_livree:Yup.number().required("Obligatoire")
        //password: Yup.string().min(8, "Minimum characters should be 8")
        //.matches(passwordRegExp,"Password must have one upper, lower case, number, special symbol").required('Required'),
        //confirmPassword:Yup.string().oneOf([Yup.ref('password')],"Password not matches").required('Required')
    })


  const handleChangeValue = async (value, props) => {
    await CliniqueService.getNomProd(value)
        .then((response) => {
            var data = response.data
            setPrixPub(data[0].prixProd)
            setPrixUnite(data[0].prix_unitaire)

        })
        .catch((error) => {
            console.log(error)
            //setValider(false)

        })

    }

    useEffect(() => {
      const fetchData = async () => {
            await FournisseurService.getFournisseurs()
            .then((response) => {
               setFournisseur(response.data)
            })
            .catch(e => {
              console.log(e);
           });

           await CliniqueService.getAllProduits()
           .then((response) => {
              setProd(response.data)
   
           })
           .catch(e => {
             console.log(e);
          });
         // fetchNums()
        }
     
        fetchData();
  }, [])


  

    const onSubmit = (values, props) => {
      var produit = {
          "codeIdProd": values.codeIdProd,
          "qteComder": values.qteComder,
          "speudo": auth.nom,
          //"codeBarre":values.codeBarre,
          "idFournisseur": values.idFournisseur,
          "idNumero": values.idNumero,
          "prixPublic": prixPub,
          "prixUnitaire": prixUnite,
          "dateComde": values.dateComde,
          "quan_livree": values.quan_livree,
          "date_expiration": values.date_expiration
      }

      BonService.createBon(produit)
          .then((response) => {
              if(response?.data){
                  toast.success(response?.data?.message, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                  });

                  refresh()              }
              else{
                  toast.error(response?.data.error, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                  });
              }
               
          })
          .catch((error) => {
              console.log(error);
              toast.error(error.response?.data, {
                  position: toast.POSITION.BOTTOM_RIGHT,
              });
          });
      // alert(props)
      //props.resetForm()
  }





  return (
    <div>
      <Grid container>
            <Paper elevation={0} style={paperStyle}>
                <Grid align='center'>
                    <Typography variant='h6' >Enregistrer une commande</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth="md">
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                            {(props) => (
                                <Form noValidate>
                                    <Grid container spacing={2}>
                <Grid item xs={12} >
                      <Autocomplete
                                id="idNumero"
                                name="idNumero"
                                options={nums}
                                getOptionSelected={(option, value) => option.numero === value.numero}
                                getOptionLabel={option => option.numero}
                                  onChange={(e, v, r) => {
                                    props.setFieldValue("idNumero",v?.idNumero)
                                  }}
                                renderInput={params => (
                                    <Field as={TextField} {...params} name="idNumero" label="Numéro commande" variant="outlined" fullWidth
                                    error={props.errors.idNumero && props.touched.idNumero}  value={nums[0]?.numero}  
                                    helperText={<ErrorMessage name='idNumero' />}  required
                                    />
                                )}
                        /> 
                 </Grid>

                <Grid item xs={12}>
                                    <Autocomplete
                                id="idFournisseur"
                                name="idFournisseur"
                                options={fournisseurs}
                                getOptionSelected={(option, value) => option.nomFournisseur === value.nomFournisseur}
                                getOptionLabel={option => option.nomFournisseur}
                                  onChange={(e, v, r) => {
                                    setNomFournisseur(v?.nomFournisseur)
                                    props.setFieldValue("idFournisseur",v?.idFournisseur)
                                  }}
                                renderInput={params => (
                                    <Field as={TextField} {...params} name="idFournisseur" label="Fournisseur" variant="outlined" fullWidth
                                    error={props.errors.idFournisseur && props.touched.idFournisseur}
                                    helperText={<ErrorMessage name='idFournisseur' />}  required
                                    />
                                )}
                        />
                        </Grid> 
                                       
                   
                        
                        <Grid item xs={12}>
                            <Autocomplete
                                id="codeIdProd"
                                name="codeIdProd"
                                options={prod}
                                getOptionSelected={(option, value) => option.nomProd === value.nomProd}
                                getOptionLabel={option => option.nomProd}
                                  onChange={(e, v, r) => {
                                    setNomProd(v?.nomProd)
                                    handleChangeValue(v?.nomProd)
                                    props.setFieldValue("codeIdProd",v?.codeIdProd)
                                  }}
                                renderInput={params => (
                                    <Field as={TextField} {...params} name="codeIdProd" label="Produit" variant="outlined" fullWidth
                                    error={props.errors.codeIdProd && props.touched.codeIdProd}
                                    helperText={<ErrorMessage name='codeIdProd' />}  required
                                    />
                                )}
                        />
                        </Grid>

                                        <Grid item xs={6} >
                                            <Field as={TextField} name="prixUnitaire" placeholder='Prix cession' fullWidth variant="outlined"
                                                error={props.errors.prixUnitaire && props.touched.prixUnitaire}
                                                helperText={<ErrorMessage name='prixUnitaire' />} required   
                                                value={prixUnite}
                                                onChange={(event) => setPrixUnite(event.target.value)}
                                                />
                                        </Grid>

                        
                                        <Grid item xs={6} >
                                            <Field as={TextField} name="prixPublic" placeholder='Prix publique' fullWidth variant="outlined"
                                                error={props.errors.prixPublic && props.touched.prixPublic}
                                                helperText={<ErrorMessage name='prixPublic' />} required   
                                                value={prixPub}
                                                onChange={(event) => setPrixPub(event.target.value)}
                                                />
                                        </Grid>

                        <Grid item xs={6} >
                                            <Field as={TextField} name="qteComder" label='quantité commandée' fullWidth variant="outlined"
                                                error={props.errors.qteComder && props.touched.qteComder}
                                                helperText={<ErrorMessage name='qteComder' />} required />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <Field as={TextField} name="quan_livree" label='quantité livrée' fullWidth variant="outlined"
                                                error={props.errors.quan_livree && props.touched.quan_livree}
                                                helperText={<ErrorMessage name='quan_livree' />} required />
                                        </Grid>

                        <Grid item xs={12}>
                                    <DateTimePicker
                                    name="date_expiration"
                                    label="date expiration"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DateTimePicker
                                    name="dateComde"
                                    label="date commande"
                                    />
                                </Grid>
                                        

                                        <Grid item xs={12} >
                                            <Button type='submit' style={btnStyle}  variant='contained'>Valider</Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Container>
                </Grid>
            </Paper>
        </Grid>
    </div>
  )
}

export default AddCommande