import { toast } from "react-toastify";

const fournisseurReducer = (fournisseurs = [], action) => {
  switch (action.type) {
    case "GET_FOURNISSEURS":
      return action.fournisseurs.data;
    case "ADD_FOURNISSEUR":
      toast.success("A USER was added...", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return [action.fournisseur.data, ...fournisseurs];
    case "UPDATE_FOURNISSEUR":
      toast.success("A fournisseur was updated...", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return fournisseurs.map((fournisseur) =>
        fournisseur.id === action.fournisseur.data._id ? action.fournisseur.data : fournisseur
      );
    case "CHECK_FOURNISSEUR":
      toast.success("A fournisseur status was changed...", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return fournisseurs.map((fournisseur) =>
        fournisseur.id === action.fournisseur.data._id ? action.fournisseur.data : fournisseur
      );
    case "DELETE_FOURNISSEUR":
      toast.success("A fournisseur was deleted...", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return fournisseurs.filter((fournisseur) => fournisseur.id !== action.id);
    case "CLEAR_FOURNISSEURS":
      return [];
    default:
      return fournisseurs;
  }
};

export default fournisseurReducer;