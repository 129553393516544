import http from "./AxiosInstance";

class CliniqueService {
  getAllUsers() {
    return http.get("/users/all");
  }
  getAllProduits() {
    return http.get("/produits/all");
  }

 

  getAllProductCodeBarre() {
    return http.get("/produits/codebarre");
  }

  getAllStocks() {
    return http.get("/produits/all/stock");
  }

  getNameProd(id) {
    return http.get(`/produits/${id}`,id);
  }

  saveProd(data){
    console.log("data === ",data)
    return http.post("/produits/save",data)
  }

  getNomProd(nom) {
    return http.get(`/produits/one/${encodeURIComponent(nom)}`,nom);
  }

  getCodeBarre(nom) {
    return http.get(`/produits/code/${encodeURIComponent(nom)}`,nom);
  }

  getProdById(id) {
    return http.get(`/produits/id/${id}`);
  }

  getDosageProd(nom) {
    return http.get(`/produits/dosage/${encodeURIComponent(nom)}`,nom);
  }

  getFamilleProd(nom) {
    return http.get(`/produits/famille/${encodeURIComponent(nom)}`,nom);
  }

  getAllProduitDistincts() {
    return http.get("/produits/distinct/all");
  }
  
  getRoles() {
    return http.get("/users/roles");
  }

  getBons() {
    return http.get("/bonCommandes/all");
  }

  getBonsEncours() {
    return http.get("/bonCommandes/pending");
  }


  getBonsCommande() {
    return http.get("/bonCommandes/commande");
  }

  getFournisseurs() {
    return http.get("/fournisseurs/");
  }

  createFournisseur(data){
    console.log("data === ",data)
    return http.post("/fournisseurs/new",data)
  }

  updateFournisseur(data,id) {
    return http.put(`/fournisseurs/update/${id}`, data);
  }

  deleteFournisseur(id) {
    return http.delete(`/fournisseurs/delete/${id}`);
  }
// dosage
getFormes() {
  return http.get("/formes/all");
}


  createForme(data){
    console.log("data === ",data)
    return http.post("/formes/create",data)
  }

  updateForme(data,id) {
    return http.put(`/formes/update/${id}`, data);
  }

  deleteForme(id) {
    return http.delete(`/formes/delete/${id}`);
  }
// Famille

getFamilles() {
  return http.get("/familles/all");
}

createFamille(data){
  console.log("data === ",data)
  return http.post("/familles/create",data)
}

updateFamille(data,id) {
  return http.put(`/familles/update/${id}`, data);
}

deleteFamille(id) {
  return http.delete(`/familles/delete/${id}`);
}


// produit vendu

vendreProduit(data) {
  return http.put(`/prodvendus/vendre/`, data);
}


getProdVendus() {
  return http.get("/prodvendus/all");
}

getProdVenduPending() {
  return http.get(`/prodvendus/pending`);
}
/* 
getProdVenduPending(nom) {
  return http.get(`/prodvendus/pending/${encodeURIComponent(nom)}`);
}
 */
getBonVenduPending() {
  return http.get("/bonclients/pending");
}

getProdEtatVendu() {
  return http.get("/prodvendus/vendu");
}

getProdModePayement(mode) {
  return http.get(`/prodvendus/mode/${encodeURIComponent(mode)}`,mode);
}

getProdReport() {
  return http.get("/prodvendus/report");
}

createProdVendu(data){
  console.log("data === ",data)
  return http.post("/prodvendus/create",data)
}

updateProdVendu(data,id) {
  return http.put(`/prodvendus/update/${id}`, data);
}




deleteProdVendu(id) {
  return http.delete(`/prodvendus/delete/${id}`);
}



 

  // bon
  createBon (data){
    console.log("data === ",data)
    return http.post("/bonCommandes/create",data);
  }


  updateBon(data,id) {
    return http.put(`/bonCommandes/update/${id}`, data);
  }

  commandeBon(data) {
    return http.put(`/bonCommandes/commander/`, data);
  }


  deleteBon(id) {
    return http.delete(`/bon/${id}`);
  }





  getFormePharmaceutiques() {
    return http.get("/formes/all");
  }
  
  
  

  createRole(data) {
    return http.post("/users/role/create", data);
  }

  update(id, data) {
    return http.put(`/tutorials/${id}`, data);
  }

  deleteRole(rol) {
    return http.delete(`/users/role/delete/${rol}`);
  }

  deleteAll() {
    return http.delete(`/tutorials`);
  }

  findByTitle(title) {
    return http.get(`/tutorials?title=${title}`);
  }
}

export default new CliniqueService ();