import React, { useCallback, useEffect, useState } from "react";
import { AppBar, Typography, Toolbar, Button, Badge } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from '../../redux/actions/authActions';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Users from "../Users";
import Produits from "../Produits";
import Depenses from "../Depenses";
import { Navigate, useNavigate } from "react-router";
import MailIcon from '@material-ui/icons/Mail';
import CliniqueService from "../../services/CliniqueService";
import ProduitService from "../../services/ProduitService";
import { Alert } from "../../components/Alert";
import { Notifications } from "@material-ui/icons";
import Commandes from "../commandes";
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import Statistiques from "../Statistiques";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  authButton: {
    right: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  linkStyle: {
    textDecoration: "none",
    color: "#fafafa",
  },
  activeLinkStyle: {
    backgroundColor: "crimson", // Couleur du lien actif
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const user = useSelector((state) => state.auth);
  const auth = localStorage.getItem("user")
  const speudo = JSON.parse(auth)?.speudo

  const handleSignOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/login");
  };

  const [stocks, setStocks] = useState([])
  const [openAlert, setOpenAlert] = useState(false)
  
  const handleAlert = () => {
    setOpenAlert(true)
  }

  const getStocks = useCallback(() => {
    ProduitService.getNullStocks()
      .then((res) =>{
        setStocks(res.data)
      })
      .catch(err => {
        console.log(err)
      });
  }, []);

  useEffect(() => {
    getStocks();
  },[])

  return (
    <>
      <div className={classes.root}>
        <AppBar position="fixed" color="primary" style={{display:"flex"}}>
          <Toolbar style={{display:"flex"}}>
            <Typography variant="h4" className={classes.title}>
              <Link className={classes.linkStyle} to="/dashboard">
                <div style={{display:'flex',justifyContent:'start',alignItems:'center'}}>
                  <LocalHospitalIcon style={{fontSize:'40px'}} /> SN-STOCK
                </div>
              </Link>
            </Typography>
            {user.id  && user.rol === "admin" ? (
              <>
                <Button
                  edge="end"
                  color="inherit"
                  className={classes.authButton}
                >
                  <NavLink
                    to="users"
                    activeClassName={classes.activeLinkStyle}
                    className={classes.linkStyle}
                  >
                    utilisateurs
                  </NavLink>
                </Button>
                <Button
                  edge="end"
                  color="inherit"
                  className={classes.authButton}
                >
                  <NavLink
                    to="produits"
                    activeClassName={classes.activeLinkStyle}
                    className={classes.linkStyle}
                  >
                    produits
                  </NavLink>
                </Button>
                <Button
                  edge="end"
                  color="inherit"
                  className={classes.authButton}
                >
                  <NavLink
                    to="clients"
                    activeClassName={classes.activeLinkStyle}
                    className={classes.linkStyle}
                  >
                    clients
                  </NavLink>
                </Button>
                <Button
                  edge="end"
                  color="inherit"
                  className={classes.authButton}
                >
                  <NavLink
                    to="commandes"
                    activeClassName={classes.activeLinkStyle}
                    className={classes.linkStyle}
                  >
                    commandes
                  </NavLink>
                </Button>
                <NavLink
                    to="statistiques"
                    activeClassName={classes.activeLinkStyle}
                    className={classes.linkStyle}
                  >
                <Button
                  edge="end"
                  color="inherit"
                  className={classes.authButton}
                >
                  
                    statistiques
                </Button>
                </NavLink>
                <Typography variant="subtitle2" style={{display: 'flex',flexDirection:'row',justifyContent:'space-evenly'}} className={classes.title}>
                  Connecté en tant que {speudo}
                </Typography>
                <Button
                  edge="end"
                  color="inherit"
                  className={classes.authButton}
                  onClick={() => handleSignOut()}
                >
                  <Link className={classes.linkStyle} to="/">
                    DECONNEXION
                  </Link>
                </Button>
              </>
            ) :  (
              <>
                <Button
                  edge="end"
                  color="inherit"
                  className={classes.authButton}
                >
                  <NavLink
                    to="produits"
                    activeClassName={classes.activeLinkStyle}
                    className={classes.linkStyle}
                  >
                    produits
                  </NavLink>
                </Button>
                <Button
                  edge="end"
                  color="inherit"
                  className={classes.authButton}
                >
                  <NavLink
                    to="clients"
                    activeClassName={classes.activeLinkStyle}
                    className={classes.linkStyle}
                  >
                    clients
                  </NavLink>
                </Button>
                <Button
                  edge="end"
                  color="inherit"
                  className={classes.authButton}
                >
                  <NavLink
                    to="commandes"
                    activeClassName={classes.activeLinkStyle}
                    className={classes.linkStyle}
                  >
                    commandes
                  </NavLink>
                </Button>
                <Typography variant="subtitle2" style={{display: 'flex',flexDirection:'row',justifyContent:'space-evenly'}} className={classes.title}>
                  Connecté en tant que {speudo}
                </Typography>
                <Button
                  edge="end"
                  color="inherit"
                  className={classes.authButton}
                  onClick={() => handleSignOut()}
                >
                  <Link className={classes.linkStyle} to="/"  >
                    DECONNEXION
                  </Link>
                </Button>
              </>
            )}
          </Toolbar>
        </AppBar>
      </div>
      <Routes>
        <Route index element={<Produits />} />
        <Route path="users" element={<Users />} />
        <Route path="produits" element={<Produits />} />
        <Route path="clients" element={<Depenses />} />
        <Route path="commandes" element={<Commandes />} />
        <Route path="statistiques" element={<Statistiques />} />
        <Route path="*" element={<h1 style={{textAlign:'center',marginTop:'100px'}}>404 Not found!</h1>} />
      </Routes>

      {openAlert && <Alert setOpen={setOpenAlert} data={stocks} />}
    </>
  );
};

export default Dashboard;
