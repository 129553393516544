import {Typography } from '@material-ui/core'
import MaterialTable from 'material-table'
import React, { useEffect, useState } from 'react'
import CustomizedDialogs from '../dialog'
import GetAppIcon from '@material-ui/icons/GetApp';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from "@material-ui/core/styles";
import CliniqueService from '../../services/CliniqueService'
import { toast } from "react-toastify";
import FPharmaForm from '../FPharmaForm'



const FPharmaceutique = () => {
    const useStyles = makeStyles({
        root: {
          maxWidth: 345,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent:'center',
        }
      });
  const classes = useStyles();
  const [formes,setFormes] = useState([])


  useEffect(() => {
    const fetchData = async () => {
       await CliniqueService.getFormes()
          .then((response) => {
            setFormes(response.data)
          })
          .catch(e => {
            console.log(e);
         });
        }

        
     fetchData();
  }, [])
   

      const refreshFormes = async ()=>{
        await CliniqueService.getFormes()
           .then((response) => {
            setFormes(response.data)
            })
            .catch(e => {
              console.log(e);
            });
      }

    
    

  const columns = [
     { title: "DOSAGE", field: "FormFarmaceutik", filterPlaceholder: "filter",align:"center" },
     { title: "CREATEUR", field: "speudo",filterPlaceholder:"filter",editable: 'never' },
     //{ title: "Enregistre", field: "dateEng", type: "currency", currencySetting: { currencyCode: "INR", minimumFractionDigits: 1 },
     // cellStyle: { background:"#009688" }, headerStyle: { color: "#fff" } },
   ]

    return (
        <div className="App" style={{width:"100%", display:'flow',justifyContent:'center',alignItems:'center',top:0,bottom:0,margin:'auto'}}>
            <Typography variant="title" className={classes.title}>
            Liste des formes
            </Typography>
            <div style={{display:"flex",justifyContent:'flex-start',margin:15}}>
        <div>
         <CustomizedDialogs title="nouvelle forme" boutonTitle="Ajouter une forme" refresh={()=>refreshFormes()}>
                   <FPharmaForm />
       </CustomizedDialogs>
      </div>
        </div>
            <MaterialTable columns={columns} data={formes}
      onRowClick={(evt,rowData)=>{
        
      }}
        editable={{
          onRowAdd: (newRow) => new Promise((resolve, reject) => {
              CliniqueService.createForme(newRow)
                  .then((response) => {
                    toast.success("Forme pharmaceutique ajoutee avec success...", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    refreshFormes()
                  })
                  .catch((error) => {
                    console.log(error);
                    toast.error(error.response?.data, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  });
            refreshFormes()
            setTimeout(() => resolve(), 500)
          }),
          onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
            
              CliniqueService.updateForme(newRow,oldRow.id)
                  .then((response) => {
                    toast.success("Forme pharmaceutique modifie avec success...", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    refreshFormes()
                  })
                  .catch((error) => {
                    console.log(error);
                    toast.error(error.response?.data, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  });
            refreshFormes()
            setTimeout(() => resolve(), 500)
          }),
          onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
            CliniqueService.deleteForme(selectedRow.id)
                  .then((response) => {
                    toast.success("Forme pharmaceutique supprimee avec success...", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    refreshFormes()
                  })
                  .catch((error) => {
                    console.log(error);
                    toast.error(error.response?.data, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  });
            setTimeout(() => resolve(), 1000)
          })
        }}
        actions={[
          {
            icon: () => <GetAppIcon />,
            tooltip: "details",
            onClick: (event, rowData) => {
              console.log(rowData)
            },
            
          }
        ]}
        options={{
          selection: true
        }}
        onSelectionChange={(selectedRows) => console.log(selectedRows)}
        options={{
          sorting: true, search: true,
          searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
          filtering: true, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
          paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
          exportAllData: true, exportFileName: "Fournisseurs", addRowPosition: "first", actionsColumnIndex: -1, selection: true,
          showSelectAllCheckbox: false, showTextRowsSelected: false, selectionProps: rowData => ({
            //disabled: rowData.age == null,  
            color:"primary"
          }),
           columnsButton: true,
          rowStyle: (data, index) => index % 2 === 0 ? { background: "#f5f5f5" } : null,
          headerStyle: { background: "#226D68",color:"#fff"},
          
        }}
        title="Liste des formes "
        icons={{ Add: () => <AddIcon /> }} />
          </div>
    )
}

export default FPharmaceutique
