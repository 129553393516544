import React,{useState,useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import MaterialTable from 'material-table'
import GetAppIcon from '@material-ui/icons/GetApp';
import AddIcon from '@material-ui/icons/Add';
import { addProduit, deleteProduit, getProduits} from '../../redux/actions/produitActions';
import CustomizedDialogs from '../../components/dialog';
import RegistrationForm from '../../components';
import { AppBar,Box,Button,Chip,Container,Dialog, DialogContent, DialogTitle,Grid,Tab, Tabs,TextField,Typography } from '@material-ui/core';
import './style.css';
import StockProduit from '../../components/StockProduit.js';
import PropTypes from 'prop-types';
import { toast } from "react-toastify";
import CliniqueService from '../../services/CliniqueService';
import ProduitVendu from '../../components/ProduitVendu';
import FournisseurService from '../../services/FournisseurService';
import DosageService from '../../services/DosageService';
import ProduitService from '../../services/ProduitService';
import XLSX from 'xlsx'
import MesVentes from '../../components/MesVentes';
import FondForm from '../../components/FondForm';
import VendreProduits from '../../components/VendreProduits';
import Skeleton from '../../components/skeleton/Skeleton';
import { Edit } from '@material-ui/icons';
import DepenseForm from '../../components/DepenseForm';
import MyCard from '../../components/MyCard';
import FondService from '../../services/FondService';
import { addDepense, getDepenses, updateDepense } from '../../redux/actions/depenseActions';
import FondCaisse from '../../components/FondCaisse';




function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const EXTENSIONS = ['xlsx', 'xls', 'csv']




const Produits=()=> {
  const [value, setValue] = useState(0);
  const handleChange = (event, value) => {
    setValue(value);
  };


  const [state,setStates] = useState({
    open: false,
    editProduct : []
  })

  const [updateProd,setUpdateProd] = useState({
    nomProd: "",
    prixProd : "",
    dateExpiration: "",
    qtEntrer: "",
    intituleFam:"",
    FormFarmaceutik:"",
    speudo:""
  })

  const handleChangeUpdate = (e) => {
    setUpdateProd({...updateProd, [e.target.name]: e.target.value });
  }; 

  const handleUpdate = async (e) => {
     // alert(JSON.stringify(updateProd))
      //alert(idProd)
     await  ProduitService.updateProduit(updateProd,state.editProduct[0]?.codeIdProd)
            .then((response)=>{
              toast.success("Votre commande modifiee avec success...", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              refreshProduits()
            })
            .catch((error)=>{

            })
  }; 


  const handleOpen = (row) => {
    //alert(JSON.stringify(row))
    setStates({ open: true, editProduct: row });
    // setUpdateProd(row)
    setUpdateProd({...updateProd,
      nomProd: row[0]?.nomProd,
      prixProd: row[0]?.prixProd,
      dateExpiration: row[0]?.dateExpiration,
      qtEntrer: row[0]?.qtEntrer,
      FormFarmaceutik:row[0]?.FormFarmaceutik,
      intituleFam:row[0]?.intituleFam,
      speudo:row[0]?.speudo

    })
  };

  const handleClose = () => {
    setStates({ open: false});
  };
  

  const auth= useSelector((state) => state.auth);
  const id= useSelector((state) => state.auth.nom);
  const depenses = useSelector((state) => state.depenses);
  const dispatch = useDispatch();


 

  const [colDefs, setColDefs] = useState()
  const [data, setData] = useState()



  const getExention = (file) => {
    const parts = file.name.split('.')
    const extension = parts[parts.length - 1]
    return EXTENSIONS.includes(extension) // return boolean
  }

  const convertToJson = async (headers, data) => {
    const rows = []
    data.forEach(row => {
      let rowData = {}
      row.forEach((element, index) => {
        //console.log("headers[index] 5",+headers[index])
        rowData[headers[index]] = element

      })
      rows.push(rowData)
      console.log("data == "+JSON.stringify(rowData))

    });
    await ProduitService.importData(id,{"rows" : rows}).
    then((res)=>{
      toast.success("Importation réussie avec succés...", {
        position: toast.POSITION.TOP_RIGHT,
      });
      refreshProduits()
    })
    .catch((error)=>{
      console.log("error"+error)
    })
    return rows
  }

  const importExcel = (e) => {
    const file = e.target.files[0]

    const reader = new FileReader()
    reader.onload = (event) => {
      //parse data

      const bstr = event.target.result
      const workBook = XLSX.read(bstr, { type: "binary",cellDates: true,cellNF: false,encoding: 'utf8'})

      //get first sheet
      const workSheetName = workBook.SheetNames[0]
      const workSheet = workBook.Sheets[workSheetName]
      //convert to array
      const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1,raw:false,'date_format':'dd/mm/yyyy'})
      // console.log(fileData)
      const headers = fileData[0]
      const heads = headers.map(head => ({ title: head, field: head }))
      setColDefs(heads)

      //removing header
      fileData.splice(0, 1)


      setData(convertToJson(headers, fileData))
    }

    if (file) {
      if (getExention(file)) {
        reader.readAsBinaryString(file)
      }
      else {
        alert("Invalid file input, Select Excel, CSV file")
      }
    } else {
      setData([])
      setColDefs([])
    }
  }

  



  
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const [prod,setProd] = useState([])
  const [fournisseurs,setFournisseur] = useState([])
  const [formes,setFormes] = useState([])

  const [depensesJour,setDepensesJour]=useState(0)
  const [depensesMois,setDepensesMois]=useState(0)
  const [depensesAnnee,setDepensesAnnee]=useState(0)



  
  const refreshDepenses=()=>{
    dispatch(getDepenses());
    fetchDepenseData()
  }

  const fetchDepenseData = async () => {
    await FondService.depensesJournee()
    .then((response) => {
     const data = response.data[0]?.val
       setDepensesJour(data)

    })
    .catch(e => {
      console.log(e);
   });
/* 
   await FondService.depensesHier()
    .then((response) => {
     const data = response.data[0]?.val
       setDepensesHier(data)

    })
    .catch(e => {
      console.log(e);
   }); */

   await FondService.depensesMonth()
    .then((response) => {
     const data = response.data[0]?.val
     setDepensesMois(data)

    })
    .catch(e => {
      console.log(e);
   });

   await FondService.depensesYear()
    .then((response) => {
     const data = response.data[0]?.val
     setDepensesAnnee(data)
    })
    .catch(e => {
      console.log(e);
   });
  }

  useEffect(() => {
    const fetchData = async () => {
      //dispatch(getProduits())
      setLoading(true);
     await CliniqueService.getAllProduits()
        .then((response) => {
           setLoading(false);
           setProd(response.data)

        })
        .catch(e => {
          setLoading(false);
          console.log(e);
       });
       await FournisseurService.getFournisseurs()
       .then((response) => {
          setFournisseur(response.data)
       })
       .catch(e => {
         console.log(e);
      });

      await DosageService.getFormes()
       .then((response) => {
          setFormes(response.data)
       })
       .catch(e => {
         console.log(e);
      });

   }

   refreshDepenses()
   fetchData();
  }, []);

  


  
  


  const refreshProduits = async ()=>{
    await CliniqueService.getAllProduits()
       .then((response) => {
           setProd(response.data)
        })
        .catch(e => {
          console.log(e);
        });
  }



  //if (!auth.id) navigate("/login");

 


  const columns = [
   // { title: "ID", field: "codeIdProd", filterPlaceholder: "filter" },
   { title: "NOM", field: "nomProd", filterPlaceholder: "filter"},
  // { title: "NOM", field: "nomProd", filterPlaceholder: "filter",render: row => <div onClick={() => alert(row.nomProd)}>{row.nomProd}</div>},
   //{ title: "DOSAGE", field: "dosage", filterPlaceholder: "filter" },
   { title: "PRIX", field: "prixProd", align: "center" },
/*    { title: "CREATEUR", field: "speudo", align: "center",editable:'never' },
 */   {
    title: "STATUT", field: "statut_produit",editable:'never',
    render: (rowData) => <div style={{ background: rowData.statut_produit === "NORMAL" ? "#008000aa" : rowData.statut_produit === "EXPIRE_BIENTOT" ? "#e2d810" : "#cf2018",borderRadius:"4px",paddingLeft:5 }}>{rowData.statut_produit}</div>,
     searchable: false, export: false
  },
/*    { title: "Famille", field: "intituleFam",filterPlaceholder: "filter",editable:'never'},
 */    { title: "Expiration", field: "dateExpiration",filterPlaceholder:"filter",type: "date",editable:'always'
  },
    { title: "Enregistre", field: "dateEng",filterPlaceholder:"filter",type: "date",editable: 'never' },
    { title: "Quantite", field: "qtEntrer",filterPlaceholder:"filter" },
    //{ title: "Code Barre", field: "codeBarre",filterPlaceholder:"filter" },
/*     { title: "Forme ", field: "FormFarmaceutik",filterPlaceholder:"filter" },
 */    //{ title: "Enregistre", field: "dateEng", type: "currency", currencySetting: { currencyCode: "INR", minimumFractionDigits: 1 },
    // cellStyle: { background:"#009688" }, headerStyle: { color: "#fff" } },
  ]

  const columnDepenses = [
    { title: "ID", field: "idDepense",editable: 'never'},
    { title: "INTITULE", field: "intitule", filterPlaceholder: "filter" },
  //  { title: "QUANTITE", field: "quantite", align: "center" },
    { title: "CREATEUR", field: "speudo", align: "center",filterPlaceholder: "filter",editable: 'never' },
    { title: "MONTANT", field: "montant",filterPlaceholder: "filter"},
    { title: "DATE OPERATION", field: "dateOper",filterPlaceholder:"filter",type: "datetime" },
    { title: "COMMENTAIRE", field: "commentaire",filterPlaceholder: "filter"},

    //{ title: "Enregistre", field: "dateEng", type: "currency", currencySetting: { currencyCode: "INR", minimumFractionDigits: 1 },
    // cellStyle: { background:"#009688" }, headerStyle: { color: "#fff" } },
  ]




  

  return (
    <>
    <div className="App" style={{width:"90%", display:'flow',justifyContent:'center',alignItems:'center',top:0,bottom:0,margin:'auto',marginTop:100}}>
        <h1 align="center" style={{color: 'gray'}} >Produits</h1>
        <div style={{display:'flex',justifyContent:'end',margin:'20px'}}>
        <CustomizedDialogs title="Fond de caisse" boutonTitle="ajouter le fond">
                   <FondForm />
       </CustomizedDialogs>
        </div>
       <AppBar position="static">
          <Tabs value={value} onChange={handleChange}>
          <Tab label="Caisse"  />
            {/* <Tab label="Bon de commande en cours" />
            <Tab label="Bon deja commande" /> */}
            <Tab label="Produits" onClick={()=>{refreshProduits()}} />
            <Tab label="Produits vendus" />
            <Tab label="Stock" />
            <Tab label="Mes ventes" />
            <Tab label="Dépenses" />
            <Tab label="Fond caisse" />


           {/*  <Tab label="Mes récoltes" />
            <Tab label="Voir les ventes des gérants" />
            <Tab label="Voir les produits vendus" /> */}
          </Tabs>
        </AppBar>
       {/*  { auth.rol ==="gerant" && (<AppBar position="static">
          <Tabs value={value} onChange={handleChange}>
          <Tab label="Caisse"  />
            <Tab label="Produits" onClick={()=>{refreshProduits()}}/>
            <Tab label="Produits vendus" />
            <Tab label="Stock" />
            <Tab label="Mes ventes" />
          </Tabs>
        </AppBar>)} */}
        {value === 0 && <TabContainer>
                   <VendreProduits />
        </TabContainer>}
        
          {value === 1 && <TabContainer>
            <div style={{display:'flex',justifyContent:'space-between',margin:10}}>
        <div style={{justifyContent:'flex-start',margin:10}}>
        <div>
         <CustomizedDialogs title="Nouveau produit" boutonTitle="Ajouter un produit" refresh={()=>refreshProduits()}>
                   <RegistrationForm />
       </CustomizedDialogs>
      </div>
        </div>
        <div style={{justifyContent:'flex-end',margin:10}}>
         <div>
         <input  type="file" size="60" onChange={importExcel} />
      </div> 
        </div>
        </div>
      {
        loading ? <Skeleton type="circle" /> :
        <MaterialTable columns={columns} data={prod}
        editable={{
          onRowAdd: (newRow) => new Promise((resolve, reject) => {
            dispatch(addProduit(newRow))
            refreshProduits()
            setTimeout(() => resolve(), 500)
          }),
          onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
            if(auth?.rol === 'gerant') {
              toast.error("Vous n'avez pas ce droit!!", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              setTimeout(() => resolve(), 500)
              return;
            }
            ProduitService.updateProduit(newRow,oldRow.codeIdProd)
            .then((response)=>{
              toast.success("Votre commande modifiee avec success...", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              refreshProduits()
            })
            .catch((error)=>{

            })
            setTimeout(() => resolve(), 500)
          }),
          
        onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
            if(auth.rol==="admin"){
              dispatch(deleteProduit(selectedRow.codeIdProd))
              refreshProduits()
              setTimeout(() => resolve(), 1000)
            }
            else {
              toast.error("Vous n'avez pas ce droit...", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              setTimeout(() => resolve(), 1000)
            }
            
          })
        }}
          
        actions={[
          {
            icon: () => <Edit color="error" />,
            tooltip: "modifier un produit",
            onClick: (event, rowData) => {
              //alert(rowData)
              if(auth?.rol === 'gerant') {
                toast.error("Vous n'avez pas ce droit!!", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
                return;
              }
              handleOpen(rowData)
              //setOpen(true)
            },
          },
        ]}
        onSelectionChange={(selectedRows) => console.log(selectedRows)}
        options={{
          sorting: true, search: true,
          searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
          filtering: true, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
          paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
          exportAllData: true, exportFileName: "Liste des produits", addRowPosition: "first", actionsColumnIndex: -1, selection: true,
          showSelectAllCheckbox: false, showTextRowsSelected: false, selectionProps: rowData => ({
            //disabled: rowData.age == null,  
            color:"primary"
          }),
           columnsButton: true,
          rowStyle: (data, index) => index % 2 === 0 ? { background: "#f5f5f5" } : null,
          headerStyle: { background: "#226D68",color:"#fff"},
          
        }}
        title="Liste des produits "
        icons={{ Add: () => <AddIcon /> }} />
      }
          </TabContainer>}

          {value === 2 && <TabContainer>
            <ProduitVendu />
          </TabContainer>}

          {value === 3 && <TabContainer>
           <StockProduit />
          </TabContainer>}
          {value === 4 && <TabContainer>
             <MesVentes />
          </TabContainer>}

          {value === 5 && <TabContainer>
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',margin:10}}>
        <div>
         <CustomizedDialogs title="Nouvelle depense" boutonTitle="Ajouter une depense" refresh={()=>refreshDepenses()}>
                   <DepenseForm />
       </CustomizedDialogs>
      </div>
      <div>
      <Chip
      label={`Dépenses Totales : ${depenses.reduce((a,v) =>  a = a + v?.montant , 0 )} Fcfa`}
      style={{color:'crimson',fontSize:'16px'}} variant="outlined"    /> 
      </div>
        </div>
        <div>
          <MyCard  depensesAnnee={depensesAnnee} depensesMois={depensesMois} depensesJour={depensesJour} />
        </div>
      <MaterialTable columns={columnDepenses} data={depenses}
      onRowClick={(evt,rowData)=>{
        //setSelectedRow(rowData)
        //setShowDialog(true)
      }}
        editable={{
          onRowAdd: (newRow) => new Promise((resolve, reject) => {
            dispatch(addDepense(newRow))
            refreshDepenses()
            setTimeout(() => resolve(), 500)
          }),
          onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
            console.log("row === ",oldRow)
            console.log("newRow === ",newRow)
            dispatch(updateDepense(newRow,oldRow.idDepense))
            refreshDepenses()
            setTimeout(() => resolve(), 500)
          }),
          /* onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
            dispatch(deleteDepense(selectedRow.idDepense))
            refreshDepenses()
            setTimeout(() => resolve(), 1000)
          }) */
        }}
        actions={[
          {
            icon: () => <GetAppIcon />,
            tooltip: "details",
            onClick: (event, rowData) => {
              console.log(rowData)
            },
            
          }
        ]}
        options={{
          selection: true
        }}
        onSelectionChange={(selectedRows) => console.log(selectedRows)}
        options={{
          sorting: true, search: true,
          searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
          filtering: true, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
          paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
          exportAllData: true, exportFileName: "TableData", addRowPosition: "first", actionsColumnIndex: -1, selection: true,
          showSelectAllCheckbox: false, showTextRowsSelected: false, selectionProps: rowData => ({
            //disabled: rowData.age == null,  
            color:"primary"
          }),
           columnsButton: true,
          rowStyle: (data, index) => index % 2 === 0 ? { background: "#f5f5f5" } : null,
          headerStyle: { background: "#226D68",color:"#fff"},
          
        }}
        title="Liste des depenses "
        icons={{ Add: () => <AddIcon /> }} />
          </TabContainer>}

          {value === 6 && <TabContainer>
            <FondCaisse />
          </TabContainer>}

          {/* {value === 5 && <TabContainer>
            <Recolte /> 
          </TabContainer>}
          {value === 6 && <TabContainer>
            <SearchVentes /> 
          </TabContainer>}
          {value === 7 && <TabContainer>
            <SearchRProduit /> 
          </TabContainer>} */}

          { state.open && <Dialog
      open={state.open}
      aria-labelledby="draggable-dialog-title"
      onClose={handleClose}
    >   
      <DialogTitle id="draggable-dialog-title">Modifier le produit</DialogTitle>
      {state.editProduct && <DialogContent>
         <Container>
         <Grid item xs={12}>
         <Grid container spacing={1}>
         <Grid item xs={12} >
         <TextField id="outlined-basic" 
         label="Nom produit" 
         variant="outlined" 
         name="nomProd"  
         onChange={handleChangeUpdate}
         defaultValue={state.editProduct[0]?.nomProd} 
         fullWidth 
         />
          </Grid>
          <Grid item xs={12} >

         <Grid item xs={12}>
                    <TextField
                        id="date"
                        label="Birthday"
                        type="date"
                        name="dateExpiration"
                        onChange={handleChangeUpdate}
                        defaultValue={state.editProduct[0]?.dateExpiration}                     
                           InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        variant="outlined"
                      />
          </Grid>
          </Grid>
          <Grid item xs={12} >
         <TextField id="outlined-basic" 
         label="Prix" 
         variant="outlined" 
         name="prixProd" 
         onChange={handleChangeUpdate}
         defaultValue={state.editProduct[0]?.prixProd} 
         fullWidth 
         />
          </Grid>
          <Grid item xs={12} >
         <TextField 
         id="outlined-basic" 
         label="Quantite" 
         name="qtEntrer"  
         variant="outlined" 
         onChange={handleChangeUpdate}
         defaultValue={state.editProduct[0]?.qtEntrer} 
         fullWidth />
          </Grid>
          <Grid item xs={12} >
                            <Button type='submit'  variant='contained' onClick={handleUpdate}
                                style={{backgroundColor:'#88C7BC'}} >Valider</Button>
                                </Grid>
        </Grid>
        </Grid>
          </Container>
      </DialogContent>}
      </Dialog>}
    </div>
   </>
  );
}


export default Produits;


