import { toast } from "react-toastify";

const produitReducer = (produits = [], action) => {
  switch (action.type) {
    case "GET_PRODUITS":
      return action.produits.data;
    case "ADD_PRODUIT":
      toast.success("A PRODUCT was added...", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return [action.produit.data, ...produits];
    case "UPDATE_PRODUIT":
      toast.success("A PRODUIT was updated...", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return produits.map((produit) =>
        produit.codeIdProd === action.produit.data.codeIdProd ? action.produit.data : produit
      );
    case "CHECK_PRODUIT":
      toast.success("A Product status was changed...", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return produits.map((produit) =>
        produit.codeIdProd === action.produit.data.codeIdProd ? action.produit.data : produit
      );
    case "DELETE_PRODUCT":
      toast.success("A PRODUCT was deleted...", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return produits.filter((produit) => produit.id !== action.id);
    case "CLEAR_PRODUITS":
      return [];
    default:
      return produits;
  }
};

export default produitReducer;