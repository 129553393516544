import http from "./AxiosInstance";

class TypeClientService {
  
// TypeClient

getTypeClient() {
  return http.get("/type/all");
}

createTypeClient(data){
  return http.post("/type/create",data)
}

updateTypeClient(data,id) {
  return http.put(`/type/update/${id}`, data);
}

deleteTypeClient(id) {
  return http.delete(`/type/delete/${id}`);
}

  
}

export default new TypeClientService ();