import { AppBar, Tab, Tabs, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Recolte from '../../components/Recolte'
import PropTypes from 'prop-types';
import SearchVentes from '../../components/SearchVentes'
import SearchRProduit from '../../components/SearchRProduit'
import ChartPage from '../../components/ChartPage';



function TabContainer(props) {
    return (
      <Typography component="div" style={{ padding: 8 * 3 }}>
        {props.children}
      </Typography>
    );
  }
  
  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
  };


const Statistiques = () => {

    
  const auth= useSelector((state) => state.auth);
  const id= useSelector((state) => state.auth.nom);
  const dispatch = useDispatch();

  const [value, setValue] = useState(0);
  const handleChange = (event, value) => {
    setValue(value);
  };

  return (
    <div className="App" style={{width:"90%", display:'flow',justifyContent:'center',alignItems:'center',top:0,bottom:0,margin:'auto',marginTop:100}}>
    <h1 align="center" style={{color: 'gray'}} >Statistiques</h1>
    <div style={{display:'flex',justifyContent:'end',margin:'20px'}}>
    
    </div>
    { auth.rol ==="admin" && (<AppBar position="static">
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Mes récoltes" />
        <Tab label="Voir les ventes des gérants" />
        <Tab label="Voir les produits vendus" />
        <Tab label="Top 10 des produits" />
      </Tabs>
    </AppBar>)}
    
      {value === 0 && <TabContainer>
        <Recolte /> 
      </TabContainer>}
      {value === 1 && <TabContainer>
        <SearchVentes /> 
      </TabContainer>}
      {value === 2 && <TabContainer>
        <SearchRProduit /> 
      </TabContainer>}

      {value === 3 && <TabContainer>
        <ChartPage /> 
      </TabContainer>}

  
</div>
  )
}

export default Statistiques