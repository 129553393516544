import http from "./AxiosInstance";

class FamilleService {
  
// Famille

getFamilles() {
  return http.get("/familles/all");
}

createFamille(data){
  console.log("data === ",data)
  return http.post("/familles/create",data)
}

updateFamille(data,id) {
  return http.put(`/familles/update/${id}`, data);
}

deleteFamille(id) {
  return http.delete(`/familles/delete/${id}`);
}

  
}

export default new FamilleService ();