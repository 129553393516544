import React, { useState } from 'react'
import * as Yup from 'yup'

import { Grid, Paper, Button, Typography, Container,TextField } from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Autocomplete } from "@material-ui/lab";
import { toast } from "react-toastify";
import DefectService from '../../services/DefectService';
import { useEffect } from 'react';
import CliniqueService from '../../services/CliniqueService';

const AddDefectProduct = () => {

    const [prod, setProd] = useState([])

    const listEtats = [
        {name:"EXPIRER"},
        {name:"AVARIE"},
        {name:"RETRAIT"}
    ]

    const initialValues = {
        codeIdProd: '',
        quan_avarie: '',
        etat: '',
        motif:'',
    }
    const validationSchema = Yup.object().shape({
        codeIdProd: Yup.string().required("Obligatoire"),
        quan_avarie: Yup.number().required("Obligatoire"),
        etat: Yup.string().required("Obligatoire"),
        motif: Yup.string(),
    })


  const paperStyle = { padding: '0 15px 40px 15px', width: 600, height: 500 }
  const btnStyle = { marginTop: 10, width: '100%' }

  useEffect(() => {
    const fetchData = async () => {
         await CliniqueService.getAllProduits()
         .then((response) => {
            setProd(response.data)
 
         })
         .catch(e => {
           console.log(e);
        });
      }
   
      fetchData();
}, [])

  const onSubmit = (values, props) => {
    var produit = {
        "codeIdProd": values.codeIdProd,
        "etat_produit": values.etat,
        "quan_avarie": values.quan_avarie,
        "motif": values.motif
    }

    //alert(JSON.stringify(produit))

     DefectService.createDefect(produit)
        .then((response) => {
            if(response?.data){
                toast.success(response?.data?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
            else{
                toast.error(response?.data.error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
             
        })
        .catch((error) => {
            console.log(error);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }); 
}

  return (
    <div>
            <Grid container>
            <Paper elevation={0} style={paperStyle}>
                <Grid align='center'>
                    <Typography variant='h6' >Enregistrer un produit avarié</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth="md">
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                            {(props) => (
                                <Form noValidate>
                                    <Grid container spacing={2}>
                                                    
                        
                        <Grid item xs={12}>
                            <Autocomplete
                                id="codeIdProd"
                                name="codeIdProd"
                                options={prod}
                                getOptionSelected={(option, value) => option.nomProd === value.nomProd}
                                getOptionLabel={option => option.nomProd}
                                  onChange={(e, v, r) => {
                                    props.setFieldValue("codeIdProd",v?.codeIdProd)
                                  }}
                                renderInput={params => (
                                    <Field as={TextField} {...params} name="codeIdProd" label="Produit" variant="outlined" fullWidth
                                    error={props.errors.codeIdProd && props.touched.codeIdProd}
                                    helperText={<ErrorMessage name='codeIdProd' />}  required
                                    />
                                )}
                        />
                        </Grid>


                                        <Grid item xs={12} >
                                            <Field as={TextField} name="quan_avarie" label='quantité avariée' fullWidth variant="outlined"
                                                error={props.errors.quan_avarie && props.touched.quan_avarie}
                                                helperText={<ErrorMessage name='quan_avarie' />} required />
                                        </Grid>

                                        <Grid item xs={12}>
                            <Autocomplete
                                id="etat"
                                name="etat"
                                options={listEtats}
                                getOptionSelected={(option, value) => option.name === value.name}
                                getOptionLabel={option => option.name}                                  onChange={(e, v, r) => {
                                    props.setFieldValue("etat",v?.name)
                                  }}
                                renderInput={params => (
                                    <Field as={TextField} {...params} name="etat" label="Etat" variant="outlined" fullWidth
                                    error={props.errors.etat && props.touched.etat}
                                    helperText={<ErrorMessage name='etat' />}  required
                                    />
                                )}
                        />
                        </Grid>

                                        <Grid item xs={12} >
                                            <Field as={TextField} name="motif" label='Commentaire' fullWidth variant="outlined"
                                                error={props.errors.motif && props.touched.motif}
                                                helperText={<ErrorMessage name='motif' />} required />
                                        </Grid>
                                        

                                        <Grid item xs={12} >
                                            <Button type='submit' style={btnStyle}  variant='contained'
                                                color='primary'>Valider</Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Container>
                </Grid>
            </Paper>
        </Grid>
    </div>
  )
}

export default AddDefectProduct