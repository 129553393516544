import React,{useEffect} from 'react'
import { Grid, Paper, Button, Typography, Container, Select, MenuItem } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { getFournisseurs } from '../redux/actions/fournisseurActions'
import { addDepense } from '../redux/actions/depenseActions'


const DepenseForm = () => {

    const auth= useSelector((state) => state.auth);
    const depenses = useSelector((state) => state.depenses);
    const dispatch = useDispatch();

    console.log("depenses == ",depenses)

   

    useEffect(() => {
        dispatch(getFournisseurs())
    }, [dispatch])

    const paperStyle = { padding: '0 15px 40px 15px', width: 600,height:400 }
    const btnStyle = { marginTop: 10, width:'100%' }
    const initialValues = {
        intitule: '',
        montant: '',
        commentaire: '',
        
    }
    const validationSchema = Yup.object().shape({
        intitule: Yup.string().required("Required"),
        montant:Yup.number().required("Required"),
        commentaire: Yup.string().required("Required"),

     })
    const onSubmit = (values, props) => {

        var today = new Date()
        var depense = {
            "intitule":values.intitule,
            "montant":values.montant,
            "commentaire":values.commentaire,
            "dateOper":today.getFullYear()+ "-"+ parseInt(today.getMonth()+1) +"-"+today.getDate(),
            "speudo":auth.nom,
        }
        dispatch(addDepense(depense))
        props.resetForm()
    }
    return (
        <Grid container>
            <Paper elevation={0} style={paperStyle}>
                <Grid align='center'>
                    <Typography variant='h6' >Ajouter une depense</Typography>
                </Grid>
                <Grid item xs={12}>
        <Container maxWidth="md">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {(props) => (
                        <Form noValidate>
                     <Grid container spacing={2}>
                            {/* <TextField label='Name' name="name" fullWidth value={props.values.name}
                    onChange={props.handleChange} /> */}
                    <Grid item xs={12} >
                            <Field as={TextField} name='intitule' label='Intutile' fullWidth variant="outlined"
                                error={props.errors.intitule && props.touched.intitule}
                                helperText={<ErrorMessage name='intitule' />} required />
                            {/* <TextField label='Email' name='email' type='Email' fullWidth 
                            {...props.getFieldProps('email')}/> */}
                    </Grid>
                    <Grid item xs={12}>
                            <Field as={TextField} name='montant' label='Montant' fullWidth variant="outlined"
                                error={props.errors.montant && props.touched.montant}
                                helperText={<ErrorMessage name='montant' />} required />
                        </Grid>
                        {/* <Grid item xs={12} >
                            <Field as={TextField} name="quantite" label='quantite' fullWidth variant="outlined"
                                error={props.errors.quantite && props.touched.quantite}
                                helperText={<ErrorMessage name='quantite' />} required />
                                </Grid> */}
                            <Grid item xs={12} >
                            <Field as={TextField} name="commentaire" label='Motif' fullWidth variant="outlined"
                                error={props.errors.commentaire && props.touched.commentaire}
                                helperText={<ErrorMessage name='commentaire' />} required />
                            </Grid>
                            
                               <Grid item xs={12} >
                            <Button type='submit' style={btnStyle} variant='contained'
                                color='primary'>Valider</Button>
                                </Grid>
                                                </Grid>
                        </Form>
                    )}
                </Formik>
                </Container>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default DepenseForm;