import http from "./AxiosInstance";

class SousfamilleService {
  
// Famille

getsousFamilles() {
  return http.get("/sousfamilles/all");
}

getsousFamilleByFamille(intitule) {
  return http.get(`/sousfamilles/${intitule}`);
}

createsousFamille(data){
  return http.post("/sousfamilles/create",data)
}

updatesousFamille(data,id) {
  return http.put(`/sousfamilles/update/${id}`, data);
}

deletesousFamille(id) {
  return http.delete(`/sousfamilles/delete/${id}`);
}

  
}

export default new SousfamilleService ();