import React,{useState,useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { AppBar, Tab, Tabs, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Client from '../../components/Client.js';
import BonClientVendus from '../../components/BonClientVendus';
import ListBonClient from '../../components/ListBonClient';
import TypeClient from '../../components/TypeClient.js';
import VendreBons from '../../components/VendreBons ';
import BonClientService from '../../services/BonClientService';
import RecolteCat from '../../components/RecolteCat/index.js';


function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const Depenses=()=> {

  const auth = useSelector((state) => state.auth);
  const [prodVenduPending,setProdVenduPending] = useState([])
  const [payer,setPayer] = useState([])
  const [nonpayer,setNonPayer] = useState([])

  //const [pending,startTransition]= useTransition()


  useEffect(() => {
    refreshProd()    
  }, []);

  const refreshProd = async () =>{
    await  BonClientService.getBonClientPending(auth.nom)
          .then((response) => {
           // alert(JSON.stringify(response.data));
           setProdVenduPending(response.data)
           })
           .catch(e => {
             console.log(e);
           });

           await  BonClientService.getStatistiques("PAYER")
           .then((response) => {
            setPayer(response.data)
            })
            .catch(e => {
              console.log(e);
            });
 
            await  BonClientService.getStatistiques("NON_PAYER")
            .then((response) => {
             setNonPayer(response.data)
             })
             .catch(e => {
               console.log(e);
             });
            

  }


  
  const [value, setValue] = useState(0);

const handleChange = (event, value) => {
  setValue(value);
};

//if (!auth.id) {navigate("/login")};

const columns = [
    { title: "ID", field: "idDepense",editable: 'never'},
    { title: "INTITULE", field: "intitule", filterPlaceholder: "filter" },
  //  { title: "QUANTITE", field: "quantite", align: "center" },
    { title: "CREATEUR", field: "speudo", align: "center",filterPlaceholder: "filter",editable: 'never' },
    { title: "MONTANT", field: "montant",filterPlaceholder: "filter"},
    { title: "DATE OPERATION", field: "dateOper",filterPlaceholder:"filter",type: "datetime" },
    { title: "COMMENTAIRE", field: "commentaire",filterPlaceholder: "filter"},

    //{ title: "Enregistre", field: "dateEng", type: "currency", currencySetting: { currencyCode: "INR", minimumFractionDigits: 1 },
    // cellStyle: { background:"#009688" }, headerStyle: { color: "#fff" } },
  ]
  return (
    <div className="App" style={{width:"90%", display:'flow',justifyContent:'center',alignItems:'center',top:0,bottom:0,margin:'auto',marginTop:100}}>
        <h1 align="center"  >Bons de clients</h1>
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange}>
          <Tab label="Bons" />
          <Tab label="Liste clients" />
{/*           <Tab label="Bon client en cours" />
 */}          <Tab label="Bons client vendus" />
          <Tab label="Consulter un client" />
          <Tab label="Type client" />
          <Tab label="Statistiques" />
{/*           <Tab label="Dépenses" />
 */}
          </Tabs>
        </AppBar>
        {value === 0 && <TabContainer>
           <VendreBons />
        </TabContainer>}

        {value === 1 && <TabContainer>
            <Client />
          </TabContainer>}
{/* 
          {value === 2 && <TabContainer>
            <BonClientEnCours />
          </TabContainer>} */}

          {value === 2 && <TabContainer>
            <BonClientVendus />
          </TabContainer>}

          {value === 3 && <TabContainer>
            <ListBonClient/>
          </TabContainer>}

          {value === 4 && <TabContainer>
            <TypeClient />
          </TabContainer>}

          {value === 5 && <TabContainer>
            <div className="container">
            <RecolteCat style={{backgroundColor:'#86DDE8'}} title="Bons payés" data={payer} type={0} />
            <RecolteCat style={{backgroundColor:'#F65F54'}} title="Bons non payés" data={nonpayer} type={0}/>
           </div>
          </TabContainer>}
    </div>

  );
}


export default Depenses;


