import axios from "axios";
import { toast } from "react-toastify";
import { setHeaders, url } from "../../api";

export const getProduits = () => {
  return (dispatch) => {
    axios
      .get(`${url}/produits/all`, setHeaders())
      .then((produits) => {
        dispatch({
          type: "GET_PRODUITS",
          produits,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};



export const getOne = (nomProd) => {
    return (dispatch) => {
      axios
        .get(`${url}/produits/one/${nomProd}`, setHeaders())
        .then((produit) => {
          dispatch({
            type: "GET_ONEPRODUIT",
            produit,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };
  };

export const addProduit = (newProduit) => {
  return (dispatch, getState) => {
    axios
      .post(`${url}/produits/create`, { ...newProduit }, setHeaders())
      .then((produit) => {
        dispatch({
          type: "ADD_PRODUIT",
          produit,
        });
      })
      .catch((error) => {
        console.log("error ",error.response);
        toast.error(error.response?.data?.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
};

export const updateProduit = (updatedProduit, id) => {
  return (dispatch) => {
    axios
      .put(`${url}/produits/${id}`, updatedProduit, setHeaders())
      .then((produit) => {
        dispatch({
          type: "UPDATE_PRODUIT",
          produit,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
};

export const deleteProduit= (id) => {
  return (dispatch) => {
    axios
      .delete(`${url}/produits/${id}`, setHeaders())
      .then(() => {
        dispatch({
          type: "DELETE_PRODUCT",
          id,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
};

export const checkProduit = (id) => {
  return (dispatch) => {
    axios
      .patch(`${url}/produits/${id}`, {}, setHeaders())
      .then((produit) => {
        dispatch({
          type: "CHECK_PRODUIT",
          produit,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
};