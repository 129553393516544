import http from "./AxiosInstance";

class StockService {
  
  updateStock(data,id) {
    return http.put(`/stock/update/${id}`, data);
  }

}

export default new StockService ();