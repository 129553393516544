import http from "./AxiosInstance";

class DoseService {
  
// dosage
getDosages() {
  return http.get("/dosage/all");
}


  createDosage(data){
    console.log("data === ",data)
    return http.post("/dosage/create",data)
  }

  updateDosage(data,id) {
    return http.put(`/dosage/update/${id}`, data);
  }

  deleteDosage(id) {
    return http.delete(`/dosage/delete/${id}`);
  }
  
}

export default new DoseService ();