import { Typography } from '@material-ui/core'
import MaterialTable from 'material-table'
import React, { useEffect, useState } from 'react'
import GetAppIcon from '@material-ui/icons/GetApp';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import NumCommandeService from '../../services/NumCommandeService'



const ListNumeros = () => {
    const useStyles = makeStyles({
        root: {
          maxWidth: 345,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent:'center',
        }
      });
  const classes = useStyles();
  const [formes,setFormes] = useState([])


  useEffect(() => {
    refreshFormes()
  }, [])
   

      const refreshFormes = async ()=>{
        await NumCommandeService.getNumbers()
          .then((response) => {
            setFormes(response.data)
          })
          .catch(e => {
            console.log(e);
         });
      }

    
    

  const columns = [
     { title: "ID", field: "idNumero", filterPlaceholder: "filter",align:"center" },
     { title: "Numéro", field: "numero",filterPlaceholder:"filter" }
   ]

    return (
        <div className="App" style={{width:"100%", display:'flow',justifyContent:'center',alignItems:'center',top:0,bottom:0,margin:'auto'}}>
            <Typography variant="title" className={classes.title}>
            Liste des numéros de commande
            </Typography>
            <div style={{display:"flex",justifyContent:'flex-start',margin:15}}>
        </div>
            <MaterialTable columns={columns} data={formes}
      onRowClick={(evt,rowData)=>{
        //setSelectedRow(rowData)
        //setShowDialog(true)
       /*  history.push(
          {
            pathname: '/report',
            state: { data: rowData }
          }
        ) */
      }}
        editable={{
          onRowAdd: (newRow) => new Promise((resolve, reject) => {
               NumCommandeService.createNum(newRow)
                  .then((response) => {
                    toast.success("F ajoutee avec success...", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    refreshFormes()
                  })
                  .catch((error) => {
                    console.log(error);
                    toast.error(error.response?.data, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  });
            refreshFormes()
            setTimeout(() => resolve(), 500) 
          }),
          onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
            
              NumCommandeService.updateNum(newRow,oldRow.idNumero)
                  .then((response) => {
                    toast.success("Numéro modifie avec success...", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    refreshFormes()
                  })
                  .catch((error) => {
                    console.log(error);
                    toast.error(error.response?.data, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  });
            refreshFormes()
            setTimeout(() => resolve(), 500)
          }),
          onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
            NumCommandeService.deleteNum(selectedRow.idNumero)
                  .then((response) => {
                    toast.success("Numéro supprimé avec success...", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    refreshFormes()
                  })
                  .catch((error) => {
                    console.log(error);
                    toast.error(error.response?.data, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  });
            setTimeout(() => resolve(), 1000)
          })
        }}
        actions={[
          {
            icon: () => <GetAppIcon />,
            tooltip: "details",
            onClick: (event, rowData) => {
              console.log(rowData)
            },
            
          }
        ]}
        options={{
          selection: true
        }}
        onSelectionChange={(selectedRows) => console.log(selectedRows)}
        options={{
          sorting: true, search: true,
          searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
          filtering: true, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
          paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
          exportAllData: true, exportFileName: "Fournisseurs", addRowPosition: "first", actionsColumnIndex: -1, selection: true,
          showSelectAllCheckbox: false, showTextRowsSelected: false, selectionProps: rowData => ({
            //disabled: rowData.age == null,  
            color:"primary"
          }),
           columnsButton: true,
          rowStyle: (data, index) => index % 2 === 0 ? { background: "#f5f5f5" } : null,
          headerStyle: { background: "#226D68",color:"#fff"},
          
        }}
        title="Liste des numéros de commandes "
        icons={{ Add: () => <AddIcon /> }} />
          </div>
    )
}

export default ListNumeros
