import http from "./AxiosInstance";

class DosageService {
  
// dosage
getFormes() {
  return http.get("/formes/all");
}


  createForme(data){
    console.log("data === ",data)
    return http.post("/formes/create",data)
  }

  updateForme(data,id) {
    return http.put(`/formes/update/${id}`, data);
  }

  deleteForme(id) {
    return http.delete(`/formes/delete/${id}`);
  }
  
}

export default new DosageService ();