

import React, { useState,useEffect } from 'react'
import ProduitVenduService from '../../services/ProduitVenduService'
import RecolteCat from '../RecolteCat'
import { Button, Grid} from '@material-ui/core'

import './styles.css'
import DateTimePicker from '../FormsUI/DataTimePicker'
import { Formik, Form} from 'formik'
import * as Yup from 'yup'
import MaterialTable from 'material-table'
import AddIcon from '@material-ui/icons/Add';
import { useSelector } from 'react-redux'
import FondService from '../../services/FondService'

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Skeleton from '../skeleton/Skeleton'

function MesVentes() {
    const [journee,setJournee]=useState(0)
    const [mois,setMois]=useState(0)
    const [hier,setHier]=useState(0)
    const [annee,setAnne]=useState(0)
    const [resultat,setResultat]=useState([])
    const auth= useSelector((state) => state.auth);

    const [fondJour,setFondJour]=useState(0)
    const [depensesJour,setDepensesJour]=useState(0)


    const [produit, setProduit] = React.useState([]);
    const [loading, setLoading] = React.useState(false);


    const btnStyle = { marginTop: 10, width:'100%' }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    let today = new Date()
    let auj =today.getDate() + "-"+ parseInt(today.getMonth()+1) +"-"+today.getFullYear();

const exportToCSV = (csvData, fileName) => {

  var prod = {
    "produit":'',
    "prix":'',
    "quantite":'',
    "date":''
}

  
 csvData.forEach((product) => {
   prod.produit =  product.nomProd 
   prod.prix = product.prixProd 
   prod.quantite =  product.qtVdu 
   prod.date = product.dateInsert.substr(0, 10)
    produit.push(prod)
 })
    
    
    const ws = XLSX.utils.json_to_sheet(produit);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
}


    const columns = [
        // { title: "ID", field: "codeIdProd", filterPlaceholder: "filter" },
         { title: "INTITULE FAMILLE", field: "intituleFam",align: "center", filterPlaceholder: "filter" },

         {
          title: "PRODUIT", field: "nomProd"
        },
         { title: "PRIX PRODUIT", field: "prixProdVendu",filterPlaceholder:"filter"},
         { title: "NOM CLIENT", field: "nom",filterPlaceholder:"filter"},
         { title: "VENDEUR", field: "vendeur",filterPlaceholder:"filter"},
         { title: "QUANTITE VENDUE", field: "qtVdu",filterPlaceholder:"filter"},
        // { title: "CODE BARRE", field: "codeBarre",filterPlaceholder:"filter" },
        /*  {
          title: "ETAT", field: "etat",
          render: (rowData) => <div style={{ background: rowData.etat === "VENDU" ? "#008000aa" : "#f90000aa",borderRadius:"4px",paddingLeft:5 }}>{rowData.etat}</div>,
           searchable: false, export: false
        },  */    
        { title: "NUMERO FACTURE ", field: "numFactSrt",filterPlaceholder:"filter" },
         { title: "ENREGISTRE", field: "dateInsert", type: "date"},
         { title: "FORME", field: "FormFarmaceutik"},
         { title: "CREATEUR", field: "speudo",filterPlaceholder:"filter"},
         // cellStyle: { background:"#009688" }, headerStyle: { color: "#fff" } },
       ]

    useEffect(() => {
        const fetchData = async () => {

            await ProduitVenduService.venteGerantJour(auth.nom)
            .then((response) => {
               setJournee(response.data)
            })
            .catch(e => {
              console.log(e);
           });

           await ProduitVenduService.venteGerantHier(auth.nom)
            .then((response) => {
               setHier(response.data)
            })
            .catch(e => {
              console.log(e);
           });

           await ProduitVenduService.venteGerantMonth(auth.nom)
            .then((response) => {
               setMois(response.data)
            })
            .catch(e => {
              console.log(e);
           });

           await ProduitVenduService.venteGerantYear(auth.nom)
            .then((response) => {
               setAnne(response.data)
            })
            .catch(e => {
              console.log(e);
           });

           // fonds

           await FondService.fondJournee()
           .then((response) => {
            const data = response.data[0]?.val
            setFondJour(data)

           })
           .catch(e => {
             console.log(e);
          });

          await FondService.depensesJournee()
           .then((response) => {
            const data = response.data[0]?.val
              setDepensesJour(data)
  
           })
           .catch(e => {
             console.log(e);
          });

        }

        fetchData();

        }, [])

        const initialValues = {
            dateDebut: '',
            dateFin: '',
            
        }
        const validationSchema = Yup.object().shape({
            dateDebut: Yup.date().required("Required"),
            dateFin: Yup.date(),
        })

        const onSubmit = (values, props) => {
            var produit = {
                "date1":values.dateDebut,
                "date2":values.dateFin,  
            }
            //alert(JSON.stringify(produit), null, 2)
            setLoading(true);
            ProduitVenduService.searchVente(produit)
            .then((response) => {
                          setLoading(false);
                 setResultat(response.data)
              })
              .catch(e => {
                console.log(e);
             });
            //props.resetForm()


        }


       if ( resultat.length === 0) {
        <div>
        pas de données
           </div>
       }
        

    return (
        <div className="content">
        <div className="container">
            <RecolteCat title="MES VENTES D'AUJOURDHUI" data={journee} />
            <div style={{width:'500px',height:'200px'}}>
                <h3>Dépenses d'aujourd'hui: {depensesJour} </h3>
                <h3>Fond de caisse d'aujourd'hui: {fondJour} </h3>
                {
                resultat.length !== 0  && 
                <Button  style={{width:'100px',height:'40px',marginTop:'50px'}}  onClick={(e) => exportToCSV(resultat,"report"+auj)}  variant='contained'
                color='primary'>Exporter</Button>
                
            }
            </div>
            
            
        </div>
        
        <div className="Wrapper">
                Rechercher
                <div className="searchContainer">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {(props) => (
                        <Form noValidate>
                     <Grid container spacing={2}>
                            <Grid item xs={6}>
                                    <DateTimePicker
                                    name="dateDebut"
                                    label="date debut"
                                    />
                            </Grid>
                            <Grid item xs={6}>
                                    <DateTimePicker
                                    name="dateFin"
                                    label="date Fin"
                                    />
                            </Grid>
                            <Grid item xs={12} >
                        <Button type='submit' style={btnStyle}  variant='contained' color='primary'>Rechercher</Button>
                            </Grid>
                     </Grid>
                        </Form>
                    )}
                </Formik>
                </div>
                <div className="tableData">
                    {
                        loading ? <Skeleton type="circle" />  :
                        <div>
            <div className="infoContainer">
                <div style={{color:'darkblue',fontSize:20,padding:10}}>
                  Total  {resultat?.length} ventes 
                </div>
                <div style={{color:'crimson',fontSize:20,padding:10}} >
                Montant {resultat.reduce((a,v) =>  a = a + v.total , 0 )} Fcfa
                </div>
            </div>
    <MaterialTable columns={columns} data={resultat}  
        options={{
          selection: true
        }}
        onSelectionChange={(selectedRows) => console.log(selectedRows)}
        options={{
          sorting: true, search: true,
          searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
          filtering: true, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
          paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
          exportAllData: true, exportFileName: "Ndiol Fouta", addRowPosition: "first", actionsColumnIndex: -1, selection: true,
          showSelectAllCheckbox: false, showTextRowsSelected: false, selectionProps: rowData => ({
            //disabled: rowData.age == null,  
            color:"primary"
          }),
           columnsButton: true,
          rowStyle: (data, index) => index % 2 === 0 ? { background: "#f5f5f5" } : null,
          headerStyle: { background: "#226D68",color:"#fff"},
          
        }}
        title="Liste des produits vendus "
        icons={{ Add: () => <AddIcon /> }} />
       
                       </div>
                    }
                </div>
            </div>
    </div>

    )
}

export default MesVentes
