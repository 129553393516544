import React, { useEffect } from 'react';
import './App.css';
import Login from './pages/Login';
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from './redux/actions/authActions';
import { ToastContainer } from "react-toastify";
import { Route ,Routes} from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import Dashboard from './pages/Dashboard';
import Produits from './pages/Produits';
import Protected from './Protected';




function App() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);



  return (
   <>
   <ToastContainer />
     <Routes>
       <Route  path="/"   element={<Login />} />
       <Route  path="/login"   element={<Login />} />
       <Route  path="/dashboard/*"  element={<Protected><Dashboard /></Protected>} />
       <Route   path={"*"} element={<h1 style={{textAlign:'center',marginTop:'100px'}}>404 Not found!</h1>}/>
         
       {/* <div className="App">
      <Login />
    </div> */}
     </Routes>
   </>
    
  );
}

export default App;
