import React,{useEffect,useState} from 'react'
import { Grid, Paper, Button, Typography, Container, Select, MenuItem } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { addUser } from '../../redux/actions/userActions'
import CliniqueService from '../../services/CliniqueService'


const UserForm = () => {

    const dispatch = useDispatch();
    const [roles, setRoles] = useState([])


   

    useEffect(() => {
        refreshRoles()
    }, [dispatch])

    const refreshRoles = async ()=>{
        await CliniqueService.getRoles()
        .then((response) => {
           setRoles(response.data)
        })
        .catch(e => {
          console.log(e);
        });
    }

    const paperStyle = { padding: '0 15px 40px 15px', width: 600,height:500 }
    const btnStyle = { marginTop: 10, width:'100%' }
    const passwordRegExp=/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
    const initialValues = {
        speudo: '',
        nom: '',
        prenom: '',
        rol: '',
        passwd:'',
        confirmPasswd:''
    }
    const validationSchema = Yup.object().shape({
        speudo: Yup.string().min(3, "It's too short").required("Required"),
        //email: Yup.string().email("Enter valid email").required("Required"),
        nom:Yup.string().required("Required"),
        prenom: Yup.string().required("Required"),
        rol: Yup.string().required("Required"),
        passwd: Yup.string().min(4, "Minimum characters should be 8")
        .matches(passwordRegExp,"Password must have one upper, lower case, number, special symbol").required('Required'),
        confirmPasswd:Yup.string().oneOf([Yup.ref('passwd')],"Password ne correspond pas").required('Required')
    })
    const onSubmit = (values, props) => {

        var user = {
            "speudo":values.speudo.trim(),
            "nom":values.nom.trim(),
            "prenom":values.prenom.trim(),
            "rol":values.rol,
            "passwd":values.passwd
            
        }
        //alert(JSON.stringify(user), null, 2)
        dispatch(addUser(user))
        //props.resetForm()
    }
    return (
        <Grid container>
            <Paper elevation={0} style={paperStyle}>
                <Grid align='center'>
                    <Typography variant='h6' >Ajouter un nouveau produit</Typography>
                </Grid>
                <Grid item xs={12}>
        <Container maxWidth="md">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {(props) => (
                        <Form noValidate>
                     <Grid container spacing={2}>
                            {/* <TextField label='Name' name="name" fullWidth value={props.values.name}
                    onChange={props.handleChange} /> */}
                    <Grid item xs={12} >
                            <Field as={TextField} name='speudo' label='Username' fullWidth variant="outlined"
                                error={props.errors.speudo && props.touched.speudo}
                                helperText={<ErrorMessage name='speudo' />} required />
                            {/* <TextField label='Email' name='email' type='Email' fullWidth 
                            {...props.getFieldProps('email')}/> */}
                    </Grid>
                    <Grid item xs={12}>
                            <Field as={TextField} name='nom' label='Nom' fullWidth variant="outlined"
                                error={props.errors.nom && props.touched.nom}
                                helperText={<ErrorMessage name='nom' />} required />
                        </Grid>
                            <Grid item xs={12} >
                            <Field as={TextField} name="prenom" label='Prenom' fullWidth variant="outlined"
                                error={props.errors.prenom && props.touched.prenom}
                                helperText={<ErrorMessage name='prenom' />} required />
                            </Grid>
                            <Grid item xs={12} >
                            <Field as={Select} name="rol" label='Role' placeholder='ROLE' fullWidth variant="outlined"
                                error={props.errors.rol && props.touched.rol}
                                helperText={<ErrorMessage name='rol' />} required >
                                     {
                                        roles.map((f)=>(
                                            <MenuItem value={f.rol}>{f.rol}</MenuItem>
                                        )
                                        )
                                    }
                                        </Field>
                                </Grid>
                                <Grid item xs={12} >
                            <Field as={TextField}  type="password" name="passwd" label='Password' fullWidth variant="outlined"
                                error={props.errors.passwd && props.touched.passwd}
                                helperText={<ErrorMessage name='passwd' />} required />
                            </Grid>
                            <Grid item xs={12} >
                            <Field as={TextField} type="password" name="confirmPasswd" label='Confirmer votre password' fullWidth variant="outlined"
                                error={props.errors.confirmPasswd && props.touched.confirmPasswd}
                                helperText={<ErrorMessage name='confirmPasswd' />} required />
                            </Grid>
                           
                            
                               <Grid item xs={12} >
                            <Button type='submit' style={btnStyle} variant='contained'
                                color='primary'>Valider</Button>
                                </Grid>
                                                </Grid>
                        </Form>
                    )}
                </Formik>
                </Container>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default UserForm;