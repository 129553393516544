import React from 'react'
import ListDefectueux from './ListDefectueux'

const index = () => {
  return (
    <div>
        <ListDefectueux />
    </div>
  )
}

export default index